import styled from '@emotion/styled'
import MenuIcon from '@mui/icons-material/Menu'
import { Alert, Box, IconButton, Toolbar } from '@mui/material'
import { GlobalMessageContext } from 'App'
import useAuth from 'features/hooks/useAuth'
import { ReactNode, useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const MainContainer = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  flexDirection: 'column',
  height: '100vh',
  overflowY: 'auto',
}))

const MainContainerWithTitle = ({
  onMenuClickHandler,
  children,
}: {
  onMenuClickHandler?: () => void
  children: ReactNode
}) => {
  const { globalMessage, setGlobalMessage } = useContext(GlobalMessageContext)
  const { isLoggedIn } = useAuth()

  const onAlertCloseHandler = () => {
    setGlobalMessage(undefined)
  }

  return (
    <MainContainer className='main-container-with-title'>
      {isMobile && isLoggedIn && (
        <Toolbar
          sx={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr auto 1fr' : 'auto',
            position: 'fixed',
            backgroundColor: 'white',
            zIndex: 1,
            width: isMobile ? '100%' : 'calc(100% - 280px)',
          }}
        >
          <IconButton
            size='medium'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2, justifySelf: 'flex-start' }}
            onClick={onMenuClickHandler}
          >
            <MenuIcon />
          </IconButton>
          <Link to='/' style={{ justifySelf: 'center', padding: '10px' }}>
            <Box component='img' src='/assets/imgs/likefolio_vault.jpg' width='300px' />
          </Link>
        </Toolbar>
      )}
      <Box marginTop={isMobile ? '64px' : '0px'} display='flex' flex='1' flexDirection='column'>
        {globalMessage && (
          <Alert
            color={globalMessage.type}
            variant='standard'
            onClose={onAlertCloseHandler}
            sx={{ margin: '0px 6px' }}
          >
            {globalMessage.message}
          </Alert>
        )}
        {children}
      </Box>
    </MainContainer>
  )
}

export default MainContainerWithTitle
