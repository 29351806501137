import { yupResolver } from '@hookform/resolvers/yup'
import { Link as MUILink } from '@mui/material'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import RoundInput from 'components/atoms/RoundInput/RoundInput.component'
import { Text } from 'components/atoms/Text/Text'
import { AuthForm, AuthFormInputsContainer } from 'features/components/LoginForm/LoginForm.styled'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { theme } from 'utils/theme/index'
import * as yup from 'yup'

export type TRecoverForm = {
  password: string
  passwordConfirmation: string
}

export type TRecoverPasswordFormProps = {
  onSubmitHandler: (form: TRecoverForm) => void
  isSubmiting: boolean
}

const schema = yup
  .object({
    password: yup.string().label('Password').required().min(6),
    passwordConfirmation: yup
      .string()
      .label('Confirmation')
      .required()
      .oneOf([yup.ref('password'), ''], 'Password and Confirmation must match'),
  })
  .required()

const RecoverPasswordFormPure = ({ onSubmitHandler, isSubmiting }: TRecoverPasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TRecoverForm>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(schema),
  })
  return (
    <AuthForm onSubmit={handleSubmit(onSubmitHandler)}>
      <AuthFormInputsContainer gap='8px !important'>
        <Controller
          name='password'
          control={control}
          render={({ field }) => (
            <div>
              <RoundInput
                {...field}
                placeholder='Password'
                sx={{ width: '100%' }}
                type='password'
              />
              <Text color={theme.palette.error.contrastText} variant='body2' marginTop='6px'>
                {errors.password?.message}
              </Text>
            </div>
          )}
        />
        <Controller
          name='passwordConfirmation'
          control={control}
          render={({ field }) => (
            <div>
              <RoundInput
                {...field}
                placeholder='Password Confirmation'
                sx={{ width: '100%' }}
                type='password'
              />
              <Text color={theme.palette.error.contrastText} variant='body2' marginTop='6px'>
                {errors.passwordConfirmation?.message}
              </Text>
            </div>
          )}
        />
        <RoundButton type='submit' variant='contained' color='primary' disabled={isSubmiting}>
          Reset Password
        </RoundButton>
        <MUILink variant='body1' underline='none' component={Link} to='/subscribe'>
          Back to login
        </MUILink>
      </AuthFormInputsContainer>
    </AuthForm>
  )
}

export default RecoverPasswordFormPure
