import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import StyledTableCell from 'components/atoms/Table/StylizedTableCell.style'
import StyledTableRow from 'components/atoms/Table/StylizedTableRow.style'
import TableRowsLoader from 'components/atoms/Table/TableLoader.component'
import { Text } from 'components/atoms/Text/Text'
import { PageContainer } from 'components/organisms/PageContainer/PageContainer'
import DrawerModal from 'features/components/DrawerModal/DrawerModal.component'
import { useState } from 'react'
import { TDrawer, useDeleteDrawerMutation, useGetDrawersQuery } from 'store/apis/drawersApi'

const Drawers = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [edittingDrawer, setEdittingDrawer] = useState<TDrawer | undefined>(undefined)

  const [deleteDrawer] = useDeleteDrawerMutation()

  const { data: drawers = [], isFetching } = useGetDrawersQuery()

  const onDeleteClickHandler = (drawer: TDrawer) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      deleteDrawer(drawer.id)
    }
  }

  const onEditIconClick = async (drawer: TDrawer) => {
    setEdittingDrawer(drawer)
    setIsDrawerOpen(true)
  }

  const onAddDrawerClick = () => {
    setEdittingDrawer(undefined)
    setIsDrawerOpen(true)
  }

  const onClose = () => {
    setIsDrawerOpen(false)
  }

  return (
    <PageContainer>
      <DrawerModal open={isDrawerOpen} drawer={edittingDrawer} onClose={onClose} />
      <Box display='flex' justifyContent='flex-end' marginBottom='30px'>
        <Button variant='contained' sx={{ width: '200px' }} onClick={onAddDrawerClick}>
          Add Drawer
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align='left'>Drawer</StyledTableCell>
              <StyledTableCell align='center'>Collapsed</StyledTableCell>
              <StyledTableCell align='right'>Actions</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRowsLoader rowsNum={10} />
            ) : drawers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Text textAlign='center'>No records found</Text>
                </TableCell>
              </TableRow>
            ) : (
              drawers.map((drawer) => (
                <StyledTableRow
                  key={drawer.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component='th' scope='row'>
                    {drawer.name}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {drawer.collapsed ? 'True' : 'False'}
                  </StyledTableCell>
                  <StyledTableCell align='right' width='150px'>
                    <EditIcon
                      onClick={() => onEditIconClick(drawer)}
                      sx={{
                        cursor: 'pointer',
                        width: '20px',
                        top: '1px',
                        position: 'relative',
                        marginRight: '4px',
                      }}
                    />
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onDeleteClickHandler(drawer)}
                      sx={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  )
}

export default Drawers
