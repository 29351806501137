import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Link as MUILink } from '@mui/material'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import { Text } from 'components/atoms/Text/Text'
import TextFieldWithError from 'components/molecules/TextFieldWithError/TextFieldWithError.component'
import { AuthForm, AuthFormInputsContainer } from 'features/components/LoginForm/LoginForm.styled'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { TSignInForm } from 'types/authForm'
import { theme } from 'utils/theme/index'
import * as yup from 'yup'

export type TSignInFormProps = {
  signInError?: string | undefined
  isSubmiting: boolean
  onEmailSubmitHandler: (form: TSignInForm) => void
  onPasswordSubmitHandler: (form: TSignInForm) => void
}

const SignInFormPure = ({
  signInError,
  isSubmiting,
  onPasswordSubmitHandler,
  onEmailSubmitHandler,
}: TSignInFormProps) => {
  const [signInWithPassword, setSignInWithPassword] = useState<boolean>(false)

  const schema = useMemo(() => {
    return yup
      .object({
        email: yup.string().label('Email').required().email(),
        password: yup
          .string()
          .when('$condition', (condition, schema) =>
            signInWithPassword ? schema.required() : schema,
          ),
      })
      .required()
  }, [signInWithPassword])

  const { control, handleSubmit } = useForm<TSignInForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  return (
    <AuthForm
      onSubmit={handleSubmit(signInWithPassword ? onPasswordSubmitHandler : onEmailSubmitHandler)}
    >
      <AuthFormInputsContainer gap='30px !important'>
        {signInError && (
          <Text
            color={theme.palette.error.contrastText}
            variant='body2'
            marginTop='6px'
            textAlign='center'
          >
            {signInError}
          </Text>
        )}
        <Box display='flex' flexDirection='column' gap='8px'>
          <TextFieldWithError
            name='email'
            control={control}
            sx={{ width: '100%', fontSize: '30px' }}
            placeholder='Email'
            autoComplete='on'
          />

          {signInWithPassword ? (
            <>
              <TextFieldWithError
                name='password'
                control={control}
                placeholder='Password'
                type='Password'
                sx={{ width: '100%' }}
              />
              <Box display='flex' justifyContent='flex-end' gap='8px'>
                <MUILink
                  variant='body1'
                  underline='none'
                  component={Link}
                  textAlign='right'
                  to='/reset_password'
                >
                  Set a new password
                </MUILink>
                <Text color='primary' variant='h2' lineHeight='12px'>
                  .
                </Text>
                <MUILink
                  variant='body1'
                  underline='none'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setSignInWithPassword(false)}
                >
                  Sign in with email
                </MUILink>
              </Box>
            </>
          ) : (
            <MUILink
              variant='body1'
              underline='none'
              textAlign='right'
              sx={{ cursor: 'pointer' }}
              onClick={() => setSignInWithPassword(true)}
            >
              Sign in with password
            </MUILink>
          )}
        </Box>

        <RoundButton type='submit' variant='contained' color='primary' disabled={isSubmiting}>
          {signInWithPassword ? 'Sign In' : 'Send Login Link'}
        </RoundButton>
      </AuthFormInputsContainer>
    </AuthForm>
  )
}

export default SignInFormPure
