import CloseIcon from '@mui/icons-material/Close'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import { Avatar, Box, CircularProgress, ClickAwayListener, Drawer, ListItem } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import SidebarPosts from 'features/components/SideBarPosts/Posts'
import useSubscription from 'features/hooks/useSubscription'
import { capitalize } from 'lodash'
import { MouseEvent, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { useFetchAccountQuery } from 'store/apis/userApi'
import { theme } from 'utils/theme/index'
import SideBarMenu from './SideBarMenu.component'

const width = 340

const StyledDrawer = styled(Drawer)(() => ({
  zIndex: 10,
  '.MuiPaper-root': {
    position: 'initial',
    border: 0,
    width: `${width}px`,
    boxShadow: '1px -1px 11px -2px rgba(0,0,0,0.71);',
    backgroundColor: 'black',
    color: 'white',
    a: {
      color: 'white',
    },
  },
}))

const listItemStyles = {
  width: `${width}px`,
  backgroundColor: '#202123',
  color: 'white',
  alignSelf: 'end',
  borderRadius: '30px 0 0 30px',
  display: 'flex',
  padding: '8pxl 10px',
  marginTop: '10px',
  textDecoration: 'none',
  cursor: 'pointer',
}

export default function SideBar({
  open,
  onSideMenuCloseClickHandler,
}: {
  open: boolean
  onSideMenuCloseClickHandler: () => void
}) {
  const { data: account, isFetching } = useFetchAccountQuery()

  const { isAdminUser } = useSubscription()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onMenuClickHandler = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <StyledDrawer open={open} variant={isMobile ? 'temporary' : 'permanent'}>
      <ClickAwayListener onClickAway={onSideMenuCloseClickHandler}>
        <Box
          width='100%'
          height='100vh'
          display='flex'
          flexDirection='column'
          position='relative'
          padding='15px 0 10px 0'
        >
          {!isMobile && (
            <Box
              component='img'
              src='/assets/imgs/LIkeFolioFoundersFeedWhite.png'
              width='80%'
              alignSelf='center'
              marginBottom='30px'
            />
          )}

          {isMobile && (
            <ListItemIcon
              style={{
                justifyContent: 'flex-end',
                right: '0px',
                top: '10px',
                zIndex: 10,
                marginBottom: '10px',
              }}
              onClick={onSideMenuCloseClickHandler}
            >
              <CloseIcon style={{ color: 'white', marginRight: '8px' }} />
            </ListItemIcon>
          )}

          <SidebarPosts onSideMenuCloseClickHandler={onSideMenuCloseClickHandler} />

          {isAdminUser && (
            <ListItem
              sx={listItemStyles}
              component={Link}
              to='/'
              onClick={onSideMenuCloseClickHandler}
            >
              <Avatar sx={{ bgcolor: theme.palette.primary.main, marginRight: '10px' }}>
                <DisplaySettingsIcon sx={{ color: 'white' }} />
              </Avatar>
              <ListItemText primary='Data Engine' />
            </ListItem>
          )}

          <ListItem onClick={onMenuClickHandler} sx={{ cursor: 'pointer' }}>
            {isFetching || !account ? (
              <CircularProgress />
            ) : (
              <>
                <Avatar sx={{ bgcolor: theme.palette.primary.main, marginRight: '10px' }}>
                  {capitalize(account.email[0])}
                </Avatar>
                <ListItemText primary={account.email} />
              </>
            )}
          </ListItem>

          <SideBarMenu anchorEl={anchorEl} onCloseMenuHandler={closeMenu} />
        </Box>
      </ClickAwayListener>
    </StyledDrawer>
  )
}
