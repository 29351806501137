import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const AuthForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
}))

export const AuthFormInputsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  width: '500px',

  '@media only screen and (max-width: 900px)': {
    width: '100%',
    padding: '0 10px',
  },
}))
