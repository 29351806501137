import { Box, Chip, CircularProgress } from '@mui/material'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { Text } from 'components/atoms/Text/Text'
import { PageContainer } from 'components/organisms/PageContainer/PageContainer'
import SanitizeHTML from 'components/organisms/SanitizedHTML/SanitizedHTML.component'
import useSubscription from 'features/hooks/useSubscription'
import { toUpper } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { isMobile } from 'react-device-detect'
import 'react-quill/dist/quill.core.css'
import { useGetPostQuery } from 'store/apis/posts/postsApi'
import { PostBox } from './ManagePosts.styles'

const PrivatePost = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = useParams()

  const { isAdminUser, isFreeUser } = useSubscription()
  const { data: post, isFetching: isPostFetching } = useGetPostQuery(params.key || '')

  const visiblePost = isAdminUser || (post?.publishedAt && post?.publishedAt < DateTime.now())

  const chipClickHandler = (tag: string) => {
    setSearchParams(`?${new URLSearchParams({ tag })}`)
  }

  useEffect(() => {
    if (post) {
      document.title = `LikeFolio Vault - ${post.title}`
    }
  }, [post])

  return (
    <Box
      className='ql-editor'
      sx={{
        height: '100%',
        background: 'linear-gradient(0deg,rgba(255,255,255,1) 0%, rgba(114,191,85,1) 100%)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!isMobile && (
        <Box
          component='img'
          src='/assets/imgs/LikeFolioVaultWhite.png'
          width='300px'
          alignSelf='center'
          marginTop='10px'
        />
      )}
      <PageContainer marginTop='0px'>
        {isPostFetching ? (
          <FlexBoxFullHeight justifyContent='center' alignItems='center'>
            <CircularProgress />
          </FlexBoxFullHeight>
        ) : (
          post && (
            <PostBox className='post'>
              {visiblePost ? (
                <>
                  <Text variant='h1' fontWeight={500} textAlign='center'>
                    {post.title}
                  </Text>
                  <div className='author'>
                    <Text component='p' textAlign='center' fontSize='20px' margin='0 !important'>
                      {post.publishedAt?.toLocaleString(DateTime.DATETIME_SHORT)}
                    </Text>
                  </div>
                  <Box display='flex' gap='6px'>
                    {!isFreeUser &&
                      post.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={`#${toUpper(tag)}`}
                          onClick={() => chipClickHandler(tag)}
                        />
                      ))}
                  </Box>
                  <SanitizeHTML html={post.body} />
                </>
              ) : (
                <Box>
                  <Text textAlign='center' variant='h1'>
                    The post does not exist
                  </Text>
                </Box>
              )}
            </PostBox>
          )
        )}
      </PageContainer>
    </Box>
  )
}

export default PrivatePost
