import { TextField, styled } from '@mui/material'
import { theme } from 'utils/theme/index'

const RoundInput = styled(TextField, { shouldForwardProp: (prop) => prop !== 'sideButton' })<{
  sideButton?: boolean
}>(({ sideButton = false }) => ({
  border: `${theme.palette.primary.main} 2px solid`,
  borderRadius: sideButton ? '30px 0 0 30px' : '30px',

  input: {
    fontSize: '20px',
    padding: '8px 16px',

    ':-webkit-autofill': {
      borderRadius: sideButton ? '30px 0 0 30px' : '30px',
    },

    '@media only screen and (max-width: 900px)': {
      fontSize: '16px',
      padding: '6px 12px',
    },
  },

  fieldset: {
    border: 0,
  },
}))

export default RoundInput
