import { Box, CircularProgress } from '@mui/material'
import { GlobalMessageContext } from 'App'
import LandingPageContainer from 'components/organisms/LandingPageContainer/LandingPageContainer.component'
import SignInForm from 'features/components/SignInForm/SignInForm.component'
import { useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePostOTPCodeMutation } from 'store/apis/authApi'
import { selectPreviousUrl } from 'store/apis/selectors'
import { useAppSelector } from 'store/store'

const SignIn = () => {
  const navigate = useNavigate()

  const [queryParameters] = useSearchParams()

  const [postOTPCode, { isLoading }] = usePostOTPCodeMutation()

  const { setGlobalMessage } = useContext(GlobalMessageContext)

  const previousUrl = useAppSelector(selectPreviousUrl)

  useEffect(() => {
    const code = queryParameters.get('code')

    const postOTP = async () => {
      if (code) {
        await postOTPCode(code).unwrap()

        setGlobalMessage({
          type: 'success',
          message: 'Login Success',
        })

        if (previousUrl) {
          navigate(previousUrl)
        } else {
          navigate('/')
        }
      }
    }
    postOTP()
  }, [queryParameters])

  return (
    <LandingPageContainer>
      {isLoading ? (
        <Box display='flex' width='100%' height='100%' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
      ) : (
        <SignInForm />
      )}
    </LandingPageContainer>
  )
}

export default SignIn
