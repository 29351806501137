import { Text } from 'components/atoms/Text/Text'
import SubscribeFormPure from 'components/organisms/SuscribeForm/SubscribeFormPure.component'
import useAuth from 'features/hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { usePostRegistrationMutation } from 'store/apis/authApi'
import { selectPreviousUrl } from 'store/apis/selectors'
import { useAppSelector } from 'store/store'
import { isApiError } from 'store/utils'
import { TRegistrationForm } from 'types/authForm'
import { theme } from 'utils/theme/index'

const SubscribeForm = ({ registrationLabel }: { registrationLabel: string }) => {
  const navigate = useNavigate()

  const { login } = useAuth()

  const previousUrl = useAppSelector(selectPreviousUrl)

  const [postRegistration, { error: postRegistrationError, isLoading }] =
    usePostRegistrationMutation()

  const onSubmitHandler = async (values: TRegistrationForm) => {
    const randomPassword = Math.random().toString(36).slice(-8)

    await postRegistration({
      ...values,
      password: randomPassword,
      passwordConfirmation: randomPassword,
      notify: true,
    }).unwrap()

    await login({ email: values.email, password: randomPassword }, false)

    navigate(previousUrl || '/')
  }

  return (
    <>
      <SubscribeFormPure
        registrationLabel={registrationLabel}
        onSubmitHandler={onSubmitHandler}
        isSubmiting={isLoading}
      />
      {postRegistrationError && isApiError(postRegistrationError) && (
        <Text color={theme.palette.error.contrastText} textAlign='center'>
          {postRegistrationError.message} <Link to='/sign_in'> Click here to log in</Link>
        </Text>
      )}
    </>
  )
}

export default SubscribeForm
