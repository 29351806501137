import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { toLower } from 'lodash'
import queryString from 'query-string'
import { TPost, TPostGroup } from 'store/types/entities'
import { API_HOST, API_PATH } from 'store/utils'
import autheHeaders from '../utils/authHeaders'
import transformPostResponse from './transformPostResponse'
import { TGetPostParams, TUserPostResponse } from './types'

const groupPostsApi = createApi({
  reducerPath: 'groupPostsApi',
  tagTypes: ['PostGroups'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: autheHeaders,
  }),
  endpoints: (builder) => ({
    getPostGroups: builder.query<Array<TPostGroup>, void>({
      query: () => 'post-groups',
      providesTags: ['PostGroups'],
    }),

    getPostGroupPost: builder.query<TUserPostResponse, { id: number } & TGetPostParams>({
      query: ({ id, page, title, tags }) => {
        const params = queryString.stringify(
          {
            title,
            page,
            ...(tags && { tags: tags.map(toLower) }),
          },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )

        return {
          url: `post-groups/${id}/posts?${params}`,
        }
      },
      transformResponse: (response: any, meta: any) => {
        const headers = meta.response.headers

        return {
          posts: response.map(transformPostResponse) as TPost[],
          currentPage: headers.get('X-Currentpage'),
          totalPages: headers.get('X-Totalpages'),
        }
      },
      providesTags: ['PostGroups'],
    }),
  }),
})

export const { useGetPostGroupsQuery, useGetPostGroupPostQuery, useLazyGetPostGroupPostQuery } =
  groupPostsApi
export default groupPostsApi
