import * as am5xy from '@amcharts/amcharts5/xy'
import ChartDiv from 'components/molecules/ChartDiv/ChartDiv'
import {
  addDataSeries,
  generateCategoryAxis,
  generateRootElement,
  generateValueAxis,
  generateXYChart,
} from 'components/organisms/Charts/utils/chartUtils'
import { SerieXYConfig } from 'components/organisms/Charts/utils/types'
import { maxBy, minBy } from 'lodash'
import { useEffect, useLayoutEffect, useState } from 'react'

const StockChart = ({ seriesConfig }: { seriesConfig: SerieXYConfig }) => {
  // const [movAvgIndicator, setMovAvgIndicator] = useState<am5stock.Indicator>()
  const [XYChart, setXYChart] = useState<am5xy.XYChart>()
  const [categoryAxis, setCategoryAxis] = useState<am5xy.CategoryAxis<am5xy.AxisRenderer>>()
  const [dataSeries, setDataSeries] = useState<{ [key: string]: am5xy.LineSeries }>({})

  const id = 'stockChart'
  const controlsId = 'stockChartControls'

  const data = seriesConfig.data || []
  const hasNegative = data.some((it) => it.value < 0)
  const allNegative = data.every((it) => it.value < 0)

  const min = minBy(data, 'value')?.value || 0
  const max = maxBy(data, 'value')?.value || 0

  useLayoutEffect(() => {
    const root = generateRootElement(id as string)

    const chart = generateXYChart(root)
    setXYChart(chart)

    const categoryAxis = generateCategoryAxis(root, chart, {
      orientation: 'vertical',
      categoryFontSize: 20,
    })
    setCategoryAxis(categoryAxis)

    const valueAxisOrientation = 'horizontal'
    const valueAxis = generateValueAxis(root, chart, { opposite: false }, valueAxisOrientation)

    valueAxis.setAll({
      min: hasNegative ? min - 20 : 0,
      max: allNegative ? 0 : max + 20,
    })

    const series = addDataSeries(
      root,
      chart,
      'column',
      categoryAxis,
      [seriesConfig],
      valueAxisOrientation,
    )

    setDataSeries(series)

    return () => {
      root.dispose()
    }
  }, [])

  useEffect(() => {
    if (dataSeries[seriesConfig.id] && categoryAxis) {
      categoryAxis.data.setAll(seriesConfig.data ?? ([] as any))
      dataSeries[seriesConfig.id].data.setAll(seriesConfig.data as any)
    }
  }, [seriesConfig])

  return <ChartDiv id={id} controlsId={controlsId} />
}

export default StockChart
