import ImageCompress from 'quill-image-compress'
import ImageResize from 'quill-image-resize-module-react'
import { useMemo } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { usePostImageMutation } from 'store/apis/imagesApi'

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'align',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
]

const Embed = Quill.import('formats/image')
class Image extends Embed {
  static create(data) {
    let node = super.create(data)
    if (typeof data === 'string') {
      node.setAttribute('src', this.sanitize(data))
    } else if (typeof data === 'object') {
      node.setAttribute('src', this.sanitize(data.url))
      data.id && node.setAttribute('image-id', data.id)
    }

    return node
  }

  static value(node) {
    return { id: node.getAttribute('image-id'), url: node.getAttribute('src') }
  }
}

Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageCompress', ImageCompress)
Quill.register('formats/image', Image)

// eslint-disable-next-line react/prop-types
const QuillEditor = ({ componentRef, onImagesChange, ...props }) => {
  const [postImage] = usePostImageMutation()

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }, { font: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image', 'video'],
          ['clean'],
        ],
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
        toolbarStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white',
          // other camelCase styles for size display
        },
        toolbarButtonStyles: {
          // ...
        },
        toolbarButtonSvgStyles: {
          // ...
        },
      },
      imageCompress: {
        quality: 0.7, // default
        maxWidth: 1000, // default
        maxHeight: 1000, // default
        imageType: 'image/jpeg', // default
        debug: true, // default
        suppressErrorLogging: false, // default
        insertIntoEditor: (imageBase64URL, imageBlob, editor) => {
          const formData = new FormData()
          formData.append('file', imageBlob)

          const file = new File([imageBlob], 'filename.jpg')

          postImage(file)
            .unwrap()
            .then((response) => {
              const range = editor.getSelection()
              const index = range?.index || 0
              editor.insertEmbed(index, 'image', response, 'user')
            })
        },
      },
    }),
    [],
  )

  const onQuillChanged = (delta, oldDelta, source, quill) => {
    // eslint-disable-next-line react/prop-types
    props.onChange(delta, oldDelta, source, quill)

    const regex = /<img\s+[^>]*>/g
    const imgTags = delta.match(regex)
    const domParser = new DOMParser()
    const images =
      imgTags?.map((i) => {
        const doc = domParser.parseFromString(i, 'text/html')
        const imgTag = doc.getElementsByTagName('img')[0]

        return { id: imgTag.getAttribute('image-id'), url: imgTag.getAttribute('src') }
      }) || []

    onImagesChange(images)
  }

  return (
    <ReactQuill
      theme='snow'
      scrollingContainer='.quill-container'
      formats={formats}
      modules={modules}
      ref={componentRef}
      {...props}
      onChange={onQuillChanged}
    />
  )
}

export default QuillEditor
