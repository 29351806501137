import { Box } from '@mui/material'
import { GlobalMessageContext } from 'App'
import DrawerForm, { TDrawerForm } from 'components/organisms/DrawerForm/DrawerForm.component'
import LikefolioDialog from 'components/organisms/LikefolioDialog'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { TDrawer, usePatchDrawerMutation, usePostDrawerMutation } from 'store/apis/drawersApi'

const DrawerModal = ({
  open,
  drawer,
  onClose,
}: {
  open: boolean
  drawer?: TDrawer
  onClose: () => void
}) => {
  const [postDrawer, { isLoading: postLoading }] = usePostDrawerMutation()
  const [patchDrawer, { isLoading: patchLoading }] = usePatchDrawerMutation()

  const { setGlobalMessage } = useContext(GlobalMessageContext)

  const onSubmitHandler = async (form: TDrawerForm) => {
    if (drawer) {
      await patchDrawer({ ...form, id: drawer.id }).unwrap()

      setGlobalMessage({
        type: 'success',
        message: 'Drawer updated successfully',
      })
    } else {
      await postDrawer(form).unwrap()

      setGlobalMessage({
        type: 'success',
        message: 'Drawer added successfully',
      })
    }

    onClose()
  }

  const onClosetHandler = () => {
    onClose()
  }

  return (
    <>
      <LikefolioDialog
        title='New Post'
        isOpen={!isMobile && open}
        onClose={onClosetHandler}
        closeOutside={false}
      >
        <DrawerForm
          drawer={drawer}
          onSubmit={onSubmitHandler}
          submitting={postLoading || patchLoading}
        />
      </LikefolioDialog>

      {isMobile && open && (
        <Box
          sx={{
            position: 'absolute',
            width: '100vw',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            paddingTop: '30px',
            zIndex: 10,
            overflow: 'auto',
          }}
        >
          <DrawerForm
            drawer={drawer}
            onSubmit={onSubmitHandler}
            submitting={postLoading || patchLoading}
          />
        </Box>
      )}
    </>
  )
}

export default DrawerModal
