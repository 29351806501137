import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'

export type TDrawer = {
  id: number
  name: string
  collapsed: boolean
}

type TDrawerRequest = Pick<TDrawer, 'name' | 'collapsed'>

// export const transformPostResponse = (entry: any) => {
//   const parsedEntry = mapKeys(entry, (_v, k) => camelCase(k))

//   if (entry.published_at) {
//     parsedEntry.publishedAt = DateTime.fromSeconds(entry.published_at)
//   }

//   if (entry.created_at) {
//     parsedEntry.createdAt = DateTime.fromISO(entry.created_at)
//   }

//   if (entry.teaser) {
//     parsedEntry.body = entry.summary
//   }

//   parsedEntry.isCore = entry.access_flag_required === 'chart_explorer_core'
//   parsedEntry.isPro = entry.access_flag_required === 'chart_explorer_pro'

//   return parsedEntry as TPost
// }

const drawersApi = createApi({
  reducerPath: 'drawersApi',
  tagTypes: ['Drawers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    getDrawers: builder.query<Array<TDrawer>, void>({
      query: () => ({
        url: `/user/post-groups`,
      }),
      providesTags: ['Drawers'],
    }),

    postDrawer: builder.mutation<void, TDrawerRequest>({
      query: ({ ...postGroup }) => ({
        url: `user/post-groups/`,
        method: 'POST',
        body: postGroup,
      }),
      invalidatesTags: ['Drawers'],
    }),

    patchDrawer: builder.mutation<void, TDrawerRequest & { id: number }>({
      query: ({ id, ...postGroup }) => ({
        url: `user/post-groups/${id}`,
        method: 'PATCH',
        body: postGroup,
      }),
      invalidatesTags: ['Drawers'],
    }),

    deleteDrawer: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `user/post-groups/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Drawers'],
    }),
  }),
})

export const {
  useGetDrawersQuery,
  usePostDrawerMutation,
  usePatchDrawerMutation,
  useDeleteDrawerMutation,
} = drawersApi
export default drawersApi
