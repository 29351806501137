import CreatePostModal from 'features/components/CreatePostModal/CreatePostModal.component'
import UpdatePostModal from 'features/components/CreatePostModal/UpdatePostModal.component'
import { isUndefined } from 'lodash'
import { ReactNode, createContext, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactGA from 'react-ga4'
import { BrowserRouter } from 'react-router-dom'
import { TPost } from 'store/types/entities'
import { MainRouter } from 'utils/AppRoutes/AppRouter'
import ErrorHandler from 'utils/AppRoutes/error/fallback'
import './App.css'

// Initialize React Ga with your tracking ID
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'id')

type TGlobalMessage =
  | {
      message: string
      overridable?: boolean
      type: 'info' | 'warning' | 'error' | 'success'
    }
  | undefined

type TGlobalMessageContext = {
  globalMessage: TGlobalMessage
  globalMessageDisplayed: boolean
  setGlobalMessage: (message: TGlobalMessage) => void
  setGlobalMessageDisplayed: (displayed: boolean) => void
}

export const GlobalMessageContext = createContext<TGlobalMessageContext>({
  globalMessage: undefined,
  globalMessageDisplayed: false,
  setGlobalMessage: (message: TGlobalMessage) => {
    return undefined
  },
  setGlobalMessageDisplayed: (displayed: boolean) => {
    return undefined
  },
})

type TPostModalContext = {
  editingPost: Partial<TPost> | undefined
  duplicate: boolean
  openPostModal: (post?: Partial<TPost>, duplicate?: boolean) => void
  closePostModal: () => void
}

export const PostModalContext = createContext<TPostModalContext>({
  editingPost: undefined,
  duplicate: false,
  openPostModal: (post?: Partial<TPost>) => {
    return undefined
  },
  closePostModal: () => {
    return undefined
  },
})

const PostModalProvider = ({ children }: { children: ReactNode }) => {
  const [editingPost, setEditingPost] = useState<Partial<TPost> | undefined>()
  const [duplicate, setDuplicate] = useState<boolean>(false)

  const openPostModal = (post?: Partial<TPost>, duplicate = false) => {
    setEditingPost(post || { body: '' })
    setDuplicate(duplicate)
  }

  const closePostModal = () => {
    setEditingPost(undefined)
    setDuplicate(false)
  }

  const value: TPostModalContext = {
    editingPost,
    duplicate,
    openPostModal,
    closePostModal,
  }

  return (
    <PostModalContext.Provider value={value}>
      <CreatePostModal
        post={editingPost}
        open={Boolean(editingPost && editingPost?.id === undefined)}
      />

      {editingPost && (
        <UpdatePostModal
          post={editingPost}
          duplicate={duplicate}
          open={Boolean(editingPost && editingPost.id !== undefined)}
        />
      )}
      {children}
    </PostModalContext.Provider>
  )
}

function App() {
  const [globalMessage, setGlobalMessage] = useState<TGlobalMessage>(undefined)
  const [globalMessageDisplayed, setGlobalMessageDisplayed] = useState<boolean>(false)

  const safeSetGlobalMessage = (message: TGlobalMessage) => {
    if (
      isUndefined(message) ||
      isUndefined(globalMessage) ||
      globalMessageDisplayed ||
      globalMessage?.overridable
    ) {
      setGlobalMessage(message ? { overridable: true, ...message } : message)
      setGlobalMessageDisplayed(false)
    }
  }
  const value = {
    globalMessage,
    globalMessageDisplayed,
    setGlobalMessage: safeSetGlobalMessage,
    setGlobalMessageDisplayed,
  }

  return (
    <BrowserRouter basename='/'>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <GlobalMessageContext.Provider value={value}>
          <PostModalProvider>
            <MainRouter />
          </PostModalProvider>
        </GlobalMessageContext.Provider>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
