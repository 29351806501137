import { Typography } from '@mui/material'
import { ElementType } from 'react'

export const Text = ({
  fontWeight,
  children,
  ...otherProps
}: React.ComponentProps<typeof Typography> & { component?: ElementType }) => {
  let fontFamily = 'Modern Era Regular'

  if (fontWeight === 500 || fontWeight === '500' || fontWeight === 'medium') {
    fontFamily = 'Modern Era Medium'
  }

  if (fontWeight === 700 || fontWeight === '700' || fontWeight === 'bold') {
    fontFamily = 'Modern Era Bold'
  }

  return (
    <Typography {...otherProps} fontFamily={fontFamily}>
      {children}
    </Typography>
  )
}
