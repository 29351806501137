import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from 'store/slices/authSlice'
import stripeReducer from 'store/slices/stripeSlice'
import authApi from './apis/authApi'
import chartsApi from './apis/chartsApi'
import drawersApi from './apis/drawersApi'
import imageApi from './apis/imagesApi'
import metricsApi from './apis/metricsApi'
import groupPostsApi from './apis/posts/groupPostsApi'
import postsApi from './apis/posts/postsApi'
import userPostsApi from './apis/posts/userPostsApi'
import stripeApi from './apis/stripeApi'
import subscriptionsApi from './apis/subscriptionsApi'
import tagsApi from './apis/tagsApi'
import userApi from './apis/userApi'
import userPasswordApi from './apis/userPasswordApi'
import authInterceptor from './middlewares/authInterceptor'

const persistedAuthReducer = persistReducer({ key: 'auth', storage }, authReducer)
const persistedStripeReducer = persistReducer({ key: 'stripe', storage }, stripeReducer)

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  stripe: persistedStripeReducer,
  [authApi.reducerPath]: authApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [chartsApi.reducerPath]: chartsApi.reducer,
  [postsApi.reducerPath]: postsApi.reducer,
  [userPostsApi.reducerPath]: userPostsApi.reducer,
  [groupPostsApi.reducerPath]: groupPostsApi.reducer,
  [drawersApi.reducerPath]: drawersApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [userPasswordApi.reducerPath]: userPasswordApi.reducer,
  [metricsApi.reducerPath]: metricsApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [imageApi.reducerPath]: imageApi.reducer,
})

export const storeConfig = {
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production' && Boolean(process.env?.REACT_APP_DEV_TOOLS),
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authInterceptor)
      .concat(authApi.middleware)
      .concat(stripeApi.middleware)
      .concat(subscriptionsApi.middleware)
      .concat(chartsApi.middleware)
      .concat(postsApi.middleware)
      .concat(userPostsApi.middleware)
      .concat(groupPostsApi.middleware)
      .concat(drawersApi.middleware)
      .concat(userApi.middleware)
      .concat(userPasswordApi.middleware)
      .concat(metricsApi.middleware)
      .concat(tagsApi.middleware)
      .concat(imageApi.middleware),
}

const store = configureStore(storeConfig)

type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store)

export default store
