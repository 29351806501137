import { Box, styled } from '@mui/material'

const BorderChartContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  border: '2px solid #A1A1A1FF',
  marginTop: '20px',
  padding: '20px',
}))

export default BorderChartContainer
