import { Box } from '@mui/material'

const ChartDiv = ({ id, controlsId }: { id: string; controlsId?: string }) => {
  return (
    <Box className='chart-div' sx={{ position: 'relative' }}>
      {controlsId && <div id={controlsId as string} style={{ display: 'none' }}></div>}
      <div id={id} style={{ width: '95%', aspectRatio: '2/1', margin: '0 auto' }}></div>
      <Box
        component='img'
        src='/assets/imgs/LikeolioCompleteLogo.png'
        sx={{ display: 'block', position: 'absolute', right: '0', width: '180px' }}
      />
    </Box>
  )
}

export default ChartDiv
