import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'

// type TPostRequest = Pick<
//   TPost,
//   'title' | 'altTitle' | 'summary' | 'body' | 'accessFlagRequired' | 'pinned'
// > &
//   Partial<Pick<TPost, 'publishedAt' | 'postGroupId' | 'chartSettings'>>

const tagsApi = createApi({
  reducerPath: 'tagsApi',
  tagTypes: ['Tags'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    getTags: builder.query<Array<string>, void>({
      query: () => ({
        url: `post-tags`,
      }),
      providesTags: ['Tags'],
    }),
  }),
})

export const { useGetTagsQuery } = tagsApi
export default tagsApi
