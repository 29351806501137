import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TPost } from 'store/types/entities'
import { API_HOST, API_PATH } from 'store/utils'
import autheHeaders from '../utils/authHeaders'
import postsApi from './postsApi'
import transformPostBody from './transformPostBody'
import transformPostResponse from './transformPostResponse'
import { TPostFilter, TPostRequest, TUserPostResponse } from './types'

const userPostsApi = createApi({
  reducerPath: 'userPostsApi',
  tagTypes: ['UserPosts'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: autheHeaders,
  }),
  endpoints: (builder) => ({
    getUserPosts: builder.query<TUserPostResponse, { page: number } & TPostFilter>({
      providesTags: ['UserPosts'],
      query: ({ page, title, postGroupId, published, draft }) => {
        const isPublished = published === 'all' ? undefined : published === 'published'

        return {
          url: 'user/posts/',
          params: {
            page,
            post_group_id: postGroupId !== 0 ? postGroupId : undefined,
            published: isPublished,
            draft,
            title,
          },
        }
      },

      transformResponse: (response: any, meta: any) => {
        const headers = meta.response.headers

        return {
          posts: response.map(transformPostResponse) as TPost[],
          currentPage: headers.get('X-Currentpage'),
          totalPages: headers.get('X-Totalpages'),
        }
      },
    }),

    getUserPost: builder.query<TPost, number>({
      providesTags: ['UserPosts'],
      query: (id) => `user/posts/${id}`,
      transformResponse: transformPostResponse,
    }),

    postUserPost: builder.mutation<void, TPostRequest>({
      query: (request) => {
        const requestBody = transformPostBody(request)

        return {
          url: 'user/posts',
          method: 'POST',
          body: requestBody,
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(userPostsApi.util.invalidateTags(['UserPosts']))
        dispatch(postsApi.util.invalidateTags(['Posts']))
      },
    }),

    patchUserPost: builder.mutation<TPost, TPostRequest & { id: number }>({
      query: (request) => {
        const requestBody = transformPostBody(request)

        return {
          url: `user/posts/${request.id}`,
          method: 'PATCH',
          body: requestBody,
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(userPostsApi.util.invalidateTags(['UserPosts']))
        dispatch(postsApi.util.invalidateTags(['Posts']))
      },
    }),

    deleteUserPost: builder.mutation<TPost, number>({
      query: (id) => {
        return {
          url: `user/posts/${id}`,
          method: 'DELETE',
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(userPostsApi.util.invalidateTags(['UserPosts']))
        dispatch(postsApi.util.invalidateTags(['Posts']))
      },
    }),
  }),
})

export const {
  useGetUserPostQuery,
  useGetUserPostsQuery,
  useLazyGetUserPostsQuery,
  usePostUserPostMutation,
  usePatchUserPostMutation,
  useDeleteUserPostMutation,
} = userPostsApi
export default userPostsApi
