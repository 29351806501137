import { GlobalMessageContext } from 'App'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyGetAuthTokenQuery } from 'store/apis/authApi'
import metricsApi from 'store/apis/metricsApi'
import postsApi from 'store/apis/posts/postsApi'
import { selectIsLoggedIn, selectPreviousUrl } from 'store/apis/selectors'
import userApi from 'store/apis/userApi'
import { setAuthToken } from 'store/slices/authSlice'
import { useAppDispatch, useAppSelector } from 'store/store'
import { TSignInForm } from 'types/authForm'

export default function useAuth() {
  const [getAuthToken, { data: response, isLoading, error, isError }] = useLazyGetAuthTokenQuery()

  const navigate = useNavigate()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const previousUrl = useAppSelector(selectPreviousUrl)
  const { setGlobalMessage } = useContext(GlobalMessageContext)
  const dispatch = useAppDispatch()

  const login = async (auth: TSignInForm, redirect = true) => {
    dispatch(userApi.util.resetApiState())
    dispatch(metricsApi.util.resetApiState())
    dispatch(postsApi.util.resetApiState())

    await getAuthToken(auth)
      .unwrap()
      .then(() => {
        if (redirect) {
          setGlobalMessage({
            type: 'success',
            message: 'Login Success',
          })

          if (previousUrl) {
            navigate(previousUrl)
          } else {
            navigate('/')
          }
        }
      })
  }

  const loginAuthToken = (access_token: string, refresh_token: string, expires_in: number) => {
    dispatch(setAuthToken({ access_token, refresh_token, expires_in }))
  }

  return {
    login,
    loginAuthToken,
    isLoggedIn,
    isLoading,
    isError,
    error,
    response,
  }
}
