import { Box, CircularProgress } from '@mui/material'
import { GlobalMessageContext, PostModalContext } from 'App'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import LikefolioDialog from 'components/organisms/LikefolioDialog'
import { isUndefined } from 'lodash'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import {
  useGetUserPostQuery,
  usePatchUserPostMutation,
  usePostUserPostMutation,
} from 'store/apis/posts/userPostsApi'
import { TPost } from 'store/types/entities'
import PublishPostForm, {
  TPublishPostFormAndImages,
} from '../../../components/organisms/FormPost/PostForm.component'

const UpdatePostModal = ({
  open,
  duplicate,
  post,
}: {
  open: boolean
  duplicate: boolean
  post: Partial<TPost>
}) => {
  const { data, isFetching } = useGetUserPostQuery(post?.id || 0, {
    skip: isUndefined(post) || isUndefined(post.id),
  })

  const [updatePost, { isLoading: isUpdateLoading }] = usePatchUserPostMutation()
  const [postPost, { isLoading: isPostLoading }] = usePostUserPostMutation()

  const { setGlobalMessage } = useContext(GlobalMessageContext)
  const { closePostModal } = useContext(PostModalContext)

  const onSubmitHandler = async (form: TPublishPostFormAndImages) => {
    if (data) {
      closePostModal()

      if (duplicate) {
        await postPost({ ...form }).unwrap()

        setGlobalMessage({
          type: 'success',
          message: 'Post duplicated successfully',
        })
      } else {
        await updatePost({ ...form, id: data.id }).unwrap()

        setGlobalMessage({
          type: 'success',
          message: 'Post updated successfully',
        })
      }
    }
  }

  const onClosetHandler = () => {
    closePostModal()
  }

  return (
    <>
      <LikefolioDialog
        title='Update Post'
        isOpen={!isMobile && open}
        onClose={onClosetHandler}
        closeOutside={false}
      >
        {isFetching ? (
          <FlexBoxFullHeight>
            <CircularProgress />
          </FlexBoxFullHeight>
        ) : (
          <PublishPostForm
            post={{ ...data, publishedAt: duplicate ? DateTime.now() : data?.publishedAt }}
            onSubmit={onSubmitHandler}
            submitting={isUpdateLoading || isPostLoading}
          />
        )}
      </LikefolioDialog>

      {isMobile && open && (
        <Box
          sx={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            paddingTop: '30px',
            zIndex: 10,
            overflow: 'auto',
          }}
        >
          <PublishPostForm
            post={{ ...data, publishedAt: duplicate ? DateTime.now() : data?.publishedAt }}
            onSubmit={onSubmitHandler}
            submitting={isUpdateLoading}
          />
        </Box>
      )}
    </>
  )
}

export default UpdatePostModal
