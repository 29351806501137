import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import LogoutIcon from '@mui/icons-material/Logout'
import PostAddIcon from '@mui/icons-material/PostAdd'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import { Box, Divider, Menu, MenuItem } from '@mui/material'
import { Text } from 'components/atoms/Text/Text'
import { Link } from 'react-router-dom'

export type TSideBarMenuPureProps = {
  isAdminUser: boolean
  isFreeUser: boolean
  anchorEl: Element | ((element: Element) => Element) | null | undefined
  closeMenuHandler: () => void
  onNewPostHandler: () => void
  onLogoutHandler: () => void
}

const width = 340

const SideBarMenuPure = ({
  anchorEl,
  isAdminUser,
  isFreeUser,
  closeMenuHandler,
  onNewPostHandler,
  onLogoutHandler,
}: TSideBarMenuPureProps) => {
  const openMenu = Boolean(anchorEl)

  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={openMenu}
      onClose={closeMenuHandler}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 140,
        horizontal: 'left',
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      PaperProps={{
        style: {
          width: `${width - 32}px`,
          backgroundColor: '#202123',
          color: 'white',
          border: '1px #373839 solid',
        },
      }}
    >
      {isAdminUser ? (
        <Box>
          <MenuItem onClick={closeMenuHandler} component={Link} to='/posts'>
            <PostAddIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
            <Text>Manage Posts</Text>
          </MenuItem>
          <MenuItem onClick={closeMenuHandler} component={Link} to='/drawers'>
            <SettingsApplicationsIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
            <Text>Manage Drawers</Text>
          </MenuItem>
        </Box>
      ) : (
        <MenuItem onClick={closeMenuHandler} component={Link} to='/archive'>
          <SettingsApplicationsIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
          <Text>Archive</Text>
        </MenuItem>
      )}

      <MenuItem onClick={closeMenuHandler} component={Link} to='/profile'>
        <AccountBoxIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
        <Text>Profile</Text>
      </MenuItem>
      {isFreeUser ? (
        <MenuItem component={'a'} href={process.env.REACT_APP_SUBSCRIPTIONS_URL} target='_blank'>
          <UpgradeIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
          <Text>Upgrade to Pro</Text>
        </MenuItem>
      ) : (
        <MenuItem component={'a'} href={process.env.REACT_APP_BILLING_URL} target='_blank'>
          <AttachMoneyIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
          <Text>My Billing Info</Text>
        </MenuItem>
      )}
      {isAdminUser && (
        <MenuItem onClick={onNewPostHandler}>
          <AddCircleOutlineIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
          <Text>New Post</Text>
        </MenuItem>
      )}
      <Divider
        sx={{
          borderColor: '#373839',
        }}
      />
      <MenuItem onClick={onLogoutHandler}>
        <LogoutIcon sx={{ color: 'white', minWidth: 0, marginRight: '10px' }} />
        <Text>Logout</Text>
      </MenuItem>
    </Menu>
  )
}

export default SideBarMenuPure
