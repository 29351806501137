import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { compact, toInteger, toUpper, uniq } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputEmoji from 'react-input-emoji'
import * as yup from 'yup'
import QuillEditor from '../../../features/components/Quill/QuillEditor.component'
import { ControlledAutocomplete } from '../ControledAutocomplete/ControledAutocomplete.component'

import { useGetTickersQuery } from 'store/apis/chartsApi'
import { useGetPostGroupsQuery } from 'store/apis/posts/groupPostsApi'
import { EAccessFlag } from 'store/apis/posts/types'
import { useGetTagsQuery } from 'store/apis/tagsApi'
import { TPost, TPostImage } from 'store/types/entities'
import defaultTeaserBody from './defaultTeaserBody'

type TPublishPostForm = {
  title: string
  altTitle?: string | undefined
  body: string
  summary?: string | undefined
  accessFlagRequired: string
  pinned: boolean
  draft: boolean
  tags: Array<string>
  publishedAt?: DateTime
  postGroupId?: number
}

export type TPublishPostFormAndImages = TPublishPostForm & {
  imageIds: Array<number>
}

const schema = yup
  .object({
    title: yup.string().label('Title').required(),
    altTitle: yup
      .string()
      .label('Teaser Title')
      .when('accessFlagRequired', {
        is: (value: EAccessFlag) => value !== EAccessFlag.FREE,
        then: (schema) => schema.required(),
      }),
    body: yup.string().required(),
    summary: yup.string().when('accessFlagRequired', {
      is: (value: EAccessFlag) => value !== EAccessFlag.FREE,
      then: (schema) => schema.required(),
    }),
    accessFlagRequired: yup.string().required(),
    pinned: yup.boolean().required(),
    draft: yup.boolean().required(),
    tags: yup.array().of(yup.string().required()).required(),
  })
  .required()

const EmojiContainer = styled(Box)(() => ({
  '.react-input-emoji--container': {
    margin: 0,
  },

  '.react-input-emoji--button': {
    position: 'absolute',
    right: 0,
    zIndex: 10,
  },
}))

const PublishPostForm = ({
  submitting,
  post,
  onSubmit,
}: {
  submitting: boolean
  post?: Partial<TPost>
  onSubmit: (form: TPublishPostFormAndImages) => void
}) => {
  const { data: tags = [], isFetching: isTagsFetching } = useGetTagsQuery()
  const { data: postGroups = [], isFetching: isPostGroupsFetching } = useGetPostGroupsQuery()
  const { data: companies = [], isFetching: isCompaniesFetching } = useGetTickersQuery()

  useEffect(() => {
    const regex = /<img[^>]+image-id=['"]([^'"]+)['"][^>]*>/gi
    const matches = regex.exec(post?.body || '')

    if (matches) {
      const srcAttributes = compact(
        matches.map(function (match) {
          const attribute = match.match(/image-id=['"]([^'"]+)['"]/)

          return attribute ? toInteger(attribute[1]) : null
        }),
      )

      setImageIds(srcAttributes)
    }
  }, [post])

  const [imageIds, setImageIds] = useState<Array<number>>([])

  const onImageChangeHandler = (images: Array<TPostImage>) => {
    setImageIds(images.map((i) => toInteger(i.id)))
  }

  const allTags = useMemo(
    () => uniq(tags.concat(companies.map((company) => company.ticker))),
    [tags, companies],
  )

  const localOnSubmitHandler = (form: TPublishPostForm) => {
    onSubmit({ ...form, imageIds })
  }

  const { control, watch, handleSubmit } = useForm<TPublishPostForm>({
    defaultValues: {
      title: '',
      altTitle: '',
      body: '',
      summary: defaultTeaserBody,
      postGroupId: 0,
      pinned: false,
      accessFlagRequired: EAccessFlag.FREE,
      publishedAt: undefined,
      tags: [],
    },
    resolver: yupResolver(schema),
    values: {
      title: post?.title || '',
      altTitle: post?.altTitle || '',
      body: post?.body || '',
      summary: post?.summary || defaultTeaserBody,
      pinned: post?.pinned || false,
      draft: post?.draft || false,
      tags: post?.tags || [],
      accessFlagRequired: post?.accessFlagRequired || EAccessFlag.FREE,
      publishedAt: post?.publishedAt || undefined,
      postGroupId: post?.postGroupId || 0,
    },
  })

  const accessFlagRequired = watch('accessFlagRequired')

  return (
    <Box display='flex' flexDirection='column' alignItems='center' rowGap='10px' height='100%'>
      <Box
        width='90%'
        display='flex'
        flexDirection='column'
        rowGap='8px'
        sx={{
          flex: 1,

          iframe: {
            width: '100%',
            aspectRatio: '2/1',
          },
        }}
      >
        <Controller
          name='accessFlagRequired'
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              color='primary'
              exclusive
              size='small'
              aria-label='Platform'
              sx={{ mt: '8px' }}
              {...field}
            >
              <ToggleButton value={EAccessFlag.FREE}>Free</ToggleButton>
              <ToggleButton value={EAccessFlag.CORE}>Core</ToggleButton>
              <ToggleButton value={EAccessFlag.PRO}>Pro</ToggleButton>
            </ToggleButtonGroup>
          )}
        />
        <Controller
          name='pinned'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label='Pinned'
            />
          )}
        />
        <Controller
          name='draft'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label='Draft'
            />
          )}
        />

        <Controller
          name='title'
          control={control}
          render={({ field }) => (
            <EmojiContainer>
              <InputEmoji
                {...field}
                borderRadius={3}
                borderColor='#CCC'
                placeholder='Type a Title'
              />
            </EmojiContainer>
          )}
        />
        <Controller
          name='publishedAt'
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              label='Published At'
              shouldDisableDate={(day) => day < DateTime.now().startOf('day')}
              slotProps={{
                actionBar: { actions: ['today'] },
                inputAdornment: {
                  position: 'start',
                },
              }}
              sx={{
                width: '100%',
              }}
            />
          )}
        />

        <Controller
          name='postGroupId'
          control={control}
          render={({ field }) => (
            <Select {...field} displayEmpty size='small'>
              <MenuItem value={0}>
                <em>Select a Drawer (optional)</em>
              </MenuItem>

              {postGroups &&
                postGroups.map((pg) => (
                  <MenuItem key={pg.id} value={pg.id}>
                    {pg.name}
                  </MenuItem>
                ))}
            </Select>
          )}
        />

        <ControlledAutocomplete
          multiple
          name='tags'
          label='Tags'
          control={control}
          options={allTags}
          isLoading={isCompaniesFetching || isTagsFetching}
          getOptionLabel={(option: any) => toUpper(option) ?? ''}
          getOptionIdentifier={(option: any) => option}
          size='small'
          freeSolo
        />

        <Box className='body-container' sx={{ marginBottom: '50px', height: '50vh' }}>
          <Controller
            name='body'
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <QuillEditor
                {...rest}
                onImagesChange={onImageChangeHandler}
                componentRef={ref}
                theme='snow'
                scrollingContainer='.body-container'
                style={{ flex: 1 }}
              />
            )}
          />
        </Box>

        {accessFlagRequired !== EAccessFlag.FREE && (
          <>
            <Controller
              name='altTitle'
              control={control}
              render={({ field }) => (
                <EmojiContainer>
                  <InputEmoji
                    {...field}
                    value={field.value || ''}
                    borderRadius={3}
                    borderColor='#CCC'
                    placeholder='Type a teaser Tittle'
                  />
                </EmojiContainer>
              )}
            />

            <Box className='summary-container' sx={{ height: '20vh', marginBottom: '50px' }}>
              <Controller
                name='summary'
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <QuillEditor
                    {...rest}
                    onImagesChange={onImageChangeHandler}
                    componentRef={ref}
                    theme='snow'
                    scrollingContainer='.summary-container'
                    style={{ flex: 1 }}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Box>

      <Button
        variant='contained'
        onClick={handleSubmit(localOnSubmitHandler)}
        disabled={submitting}
        sx={{ width: '300px', margin: '10px auto' }}
      >
        Publish
      </Button>
    </Box>
  )
}

export default PublishPostForm
