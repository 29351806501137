import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControlLabel, Switch, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { TDrawer } from 'store/apis/drawersApi'
import * as yup from 'yup'

export type TDrawerForm = {
  name: string
  collapsed: boolean
}

const schema = yup
  .object({
    name: yup.string().label('Name').required(),
    collapsed: yup.boolean().required(),
  })
  .required()

const DrawerForm = ({
  submitting,
  drawer,
  onSubmit,
}: {
  submitting: boolean
  drawer?: TDrawer
  onSubmit: (form: TDrawerForm) => void
}) => {
  const { control, handleSubmit } = useForm<TDrawerForm>({
    defaultValues: {
      name: '',
      collapsed: false,
    },
    resolver: yupResolver(schema),
    values: {
      name: drawer?.name || '',
      collapsed: drawer?.collapsed || false,
    },
  })

  return (
    <Box display='flex' flexDirection='column' alignItems='center' rowGap='10px'>
      <Box width='90%' display='flex' flexDirection='column' rowGap='8px'>
        <Controller
          name='name'
          control={control}
          render={({ field }) => <TextField {...field} label='Name' />}
        />

        <Controller
          name='collapsed'
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label='Collapsed'
            />
          )}
        />

        <Button
          variant='contained'
          onClick={handleSubmit(onSubmit)}
          disabled={submitting}
          sx={{ width: '300px', margin: '10px auto' }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default DrawerForm
