import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { isNull, snakeCase } from 'lodash'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'
import { TCapability } from './userApi'

type TSubscriptionPrice = {
  id: string
  interval: string
  intervalCount: number
  amount: number
}

export type TSubscription = {
  id: string
  name: string
  capability: TCapability
  prices: TSubscriptionPrice[]
}

const subscriptionsApi = createApi({
  reducerPath: 'subscriptionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchSubscriptions: builder.query<Array<TSubscription>, void>({
      query: () => '/user/billing/chart-explorer-subscriptions',
      transformResponse: (response: any) => {
        return response
          .filter((entry: any) => !isNull(entry.stripe_product))
          .map(
            (entry: any): TSubscription => ({
              id: entry.id,
              name: entry.name,
              capability: `access_${snakeCase(entry.stripe_handle)}` as TCapability,
              prices: entry.stripe_prices.map((price: any) => {
                return {
                  id: price.id,
                  interval: price.recurring.interval,
                  intervalCount: price.recurring.interval_count,
                  amount: price.unit_amount,
                }
              }),
            }),
          )
      },
    }),
  }),
})

export const { useFetchSubscriptionsQuery } = subscriptionsApi
export default subscriptionsApi
