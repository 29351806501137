import styled from '@emotion/styled'
import { TableRow } from '@mui/material'
import { theme } from 'utils/theme/index'

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default StyledTableRow
