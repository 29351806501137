import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    chipBase: Palette['primary']
    chipGreen: Palette['primary']
    chipRed: Palette['primary']
    chipNeutral: Palette['primary']
  }

  interface PaletteOptions {
    chipBase: PaletteOptions['primary']
    chipGreen: PaletteOptions['primary']
    chipRed: PaletteOptions['primary']
    chipNeutral: PaletteOptions['primary']
  }

  interface TypeText {
    disabledButtons: string
    blue: string
    red: string
  }

  interface TypeBackground {
    default: string
    dark: string
    paper: string
  }
}

const COLORS = {
  green: {
    100: '#e2f2dc',
    200: '#c5e5b9',
    300: '#a8d997',
    400: '#8bcc74',
    500: '#6ebf51',
    600: '#589941',
    700: '#427331',
    800: '#2c4c20',
    900: '#162610',
  },
  red: {
    100: '#f8d7d7',
    200: '#f0b0b0',
    300: '#e98888',
    400: '#e16161',
    500: '#da3939',
    600: '#ae2e2e',
    700: '#832222',
    800: '#571717',
    900: '#2c0b0b',
  },
  blue: {
    100: '#d1e9ff',
    200: '#a3d3ff',
    300: '#74bcff',
    400: '#46a6ff',
    500: '#1890ff',
    600: '#1373cc',
    700: '#0e5699',
    800: '#0a3a66',
    900: '#051d33',
  },
}

const palette = {
  primary: {
    main: '#6EBF52',
  },
  text: {
    primary: '#333333',
    secondary: '#4F4F4F',
    disabled: '#D9D9D9',
    disabledButtons: '#BDBDBD',
    blue: '#4090DB',
    red: '#FF5C5F',
  },
  secondary: {
    main: '#4090DB',
  },
  background: {
    default: '#FDFDFD',
    dark: '#202123',
  },
  divider: '#EFEFEF',
}

export const theme = createTheme({
  palette: {
    text: palette.text,
    primary: palette.primary,
    secondary: palette.secondary,
    background: palette.background,
    divider: palette.divider,
    chipBase: {
      main: 'rgba(80, 116, 138, 0.1)',
      contrastText: '#50748A',
    },
    chipGreen: {
      main: 'rgba(163, 201, 115, 0.12)',
      contrastText: palette.primary.main,
    },
    chipRed: {
      main: 'rgba(255, 92, 95, 0.12)',
      contrastText: '#FF5C5F',
    },
    chipNeutral: {
      main: 'rgba(64, 144, 219, 0.12)',
      contrastText: palette.secondary.main,
    },
    success: {
      main: COLORS.green[100],
      contrastText: COLORS.green[600],
    },
    error: {
      main: COLORS.red[100],
      contrastText: COLORS.red[500],
    },
  },
  typography: {
    fontFamily: 'Modern Era Regular',
    allVariants: {
      color: 'palette.text.primary',
    },
    caption: {
      fontSize: 12,
    },
    body2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 16,
    },
    button: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 20,
    },
    subtitle2: {
      fontSize: 16,
    },
    h4: {
      fontSize: 18,
    },
    h3: {
      fontSize: 22,
    },
    h2: {
      fontSize: 28,
    },
    h1: {
      fontSize: 32,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: COLORS.green[400],
          color: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && {
            color: 'white',
          }),
        }),
      },
    },
    // Name of the component
    // MuiTab: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       Mui
    //     },
    //   },
    // },
  },
})
