import { Box, CircularProgress } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

type InfinitListProps = {
  height?: `${number}px`
  perPage: number
  fetchData: (page: number, perPage: number) => Promise<any[]>
  children: (items: any[], isLoading: boolean) => ReactNode
}

const InfinitList = ({ height, perPage, fetchData, children }: InfinitListProps) => {
  const [page, setPage] = useState<number>(1)
  const [items, setItems] = useState<any[]>([])
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const hasMoreItemsFn = (newItems: any[]) => {
    return newItems && newItems.length === perPage
  }

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true)

      const newItems = await fetchData(page, perPage)

      setHasMoreItems(hasMoreItemsFn(newItems))
      setItems(newItems)
      setIsLoading(false)
    }

    fetchInitialData()
  }, [fetchData])

  const fetchMoreData = async () => {
    const newPage = page + 1
    const newItemsResponse = await fetchData(newPage, perPage)
    const newItems = items.concat(newItemsResponse)
    setHasMoreItems(hasMoreItemsFn(newItemsResponse))
    setItems(newItems)
    setPage(newPage)
  }

  return isLoading ? (
    <Box height='100px' display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Box>
  ) : items.length > 0 ? (
    <InfiniteScroll
      dataLength={items.length} //This is important field to render the next data
      next={fetchMoreData}
      hasMore={hasMoreItems}
      scrollThreshold={0.95}
      scrollableTarget='infinite-scroll'
      height={items.length >= 7 ? height : undefined}
      style={{
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
      loader={
        <Box
          display='flex'
          width='100%'
          alignItems='center'
          justifyContent='center'
          height='40px'
          marginTop='10px'
        >
          <CircularProgress size={30} />
        </Box>
      }
    >
      {children(items, isLoading)}
    </InfiniteScroll>
  ) : null
}

export default InfinitList
