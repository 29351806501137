import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  EChartMetric,
  EDatasetResolution,
  EDatasetScope,
  EDatasetSource,
  TChartData,
} from 'components/organisms/Charts/utils/types'
import { round } from 'lodash'
import { DateTime } from 'luxon'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'

type TDatasetRequest = {
  chartId?: string
  companyId: number
  metric: EChartMetric
  normalized: boolean
  scope: EDatasetScope
  source: EDatasetSource
  resolution: EDatasetResolution
  startDate: DateTime
  movingAverageInDays?: number
}

type TDatasetPriceRequest = {
  chartId?: string
  companyId: number
  resolution: EDatasetResolution
  startDate: DateTime
  movingAverageInDays?: number
}

type TDatasetRequestParams = {
  start_date: string | null
  mavg?: number
}

const metricsApi = createApi({
  reducerPath: 'metricsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchCompanyMetric: builder.query<TChartData[], TDatasetRequest>({
      query: ({
        chartId,
        companyId,
        metric,
        normalized,
        source = 'forecasted',
        scope,
        resolution,
        startDate,
        movingAverageInDays,
      }) => {
        let parsedMetric = metric as string

        if (!normalized) {
          switch (metric) {
            case EChartMetric.Demand:
              parsedMetric = 'mentions'
              break
            case EChartMetric.Mentions:
              parsedMetric = 'volume'
              break
            case EChartMetric.Visits:
              parsedMetric = 'visits-count'
              break

            default:
              parsedMetric = metric
              break
          }
        }

        const baseUrl = chartId ? `/charts/public/${chartId}/datasets` : '/datasets'
        let url = `${baseUrl}/company/${companyId}/${source}/${scope}/${parsedMetric}/${resolution}`

        if (metric === EChartMetric.Visits) {
          url = `${baseUrl}/company/${companyId}/web-traffic/${parsedMetric}/${resolution}`
        }

        let params: TDatasetRequestParams = {
          start_date: startDate.toISODate(),
        }

        if (movingAverageInDays) {
          params['mavg'] = movingAverageInDays
        }

        return {
          url,
          params,
        }
      },
      transformResponse: (response: TChartData[]) => {
        if (response === null) {
          return []
        }

        return response.map((item) => ({ ...item, value: round(item.value, 2) }))
      },
    }),
    fetchCompanyPrice: builder.query<TChartData[], TDatasetPriceRequest>({
      query: ({ chartId, companyId, resolution, startDate, movingAverageInDays }) => {
        const baseUrl = chartId ? `/charts/public/${chartId}/datasets` : '/datasets'
        let url = `${baseUrl}/company/${companyId}/market/price/${resolution}`

        let params: TDatasetRequestParams = {
          start_date: startDate.toISODate(),
        }

        if (movingAverageInDays) {
          params['mavg'] = movingAverageInDays
        }

        return {
          url,
          params,
        }
      },
      transformResponse: (response: TChartData[]) => {
        return response.map((item) => ({ ...item, value: round(item.value, 2) }))
      },
    }),
  }),
})

export const { useLazyFetchCompanyMetricQuery, useLazyFetchCompanyPriceQuery } = metricsApi
export default metricsApi
