import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { mapKeys, snakeCase } from 'lodash'
import { setAuthToken } from 'store/slices/authSlice'
import { API_HOST, API_PATH } from 'store/utils'
import { TRegistrationRequest, TSignInForm } from 'types/authForm'
import { AuthResponse } from './types'

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers) => {
      headers.set(
        'Authorization',
        `Basic ${btoa(
          `${process.env.REACT_APP_AUTH_CLIENT_ID}:${process.env.REACT_APP_AUTH_CLIENT_SECRET}`,
        )}`,
      )
      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    postRegistration: builder.mutation<AuthResponse, TRegistrationRequest>({
      query: (registrationForm) => ({
        method: 'POST',
        url: 'oauth2/likefolio/register',
        body: mapKeys(registrationForm, (_v, k) => snakeCase(k)),
      }),
      transformErrorResponse: (response: any) => {
        if (response.data.error === 'Invalid') {
          return {
            message: 'Account already exists.',
          }
        }

        return response
      },
    }),
    loginCode: builder.mutation<void, string>({
      query: (email) => ({
        method: 'POST',
        url: 'oauth2/login-code',
        body: { email, callback: `${process.env.REACT_APP_HOST}/sign_in` },
      }),
    }),

    postGoogleRegistration: builder.mutation<AuthResponse & { new_user: boolean }, string>({
      query: (accessToken) => ({
        method: 'POST',
        url: 'oauth2/google/register',
        body: { access_token: accessToken },
      }),
      transformResponse: (body: any, meta) => {
        body.new_user = meta?.response?.status === 201

        return body
      },
    }),

    postOTPCode: builder.mutation<AuthResponse, string>({
      query: (code) => ({
        method: 'POST',
        url: 'oauth2/token',
        body: { grant_type: 'authorization_code', code, redirect_uri: 'internal', scopes: 'login' },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        dispatch(setAuthToken(data))
      },
    }),

    getAuthToken: builder.query<AuthResponse, TSignInForm>({
      query: (auth) => ({
        url: 'oauth2/token?grant_type=password',
        params: {
          username: auth.email,
          password: auth.password,
          client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        },
      }),
      transformErrorResponse: (response: any) => {
        if (response.data.error === 'invalid_grant') {
          return { message: 'Invalid email or password' }
        }

        return response
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        dispatch(setAuthToken(data))
      },
    }),

    getAuthTokenWithRefreshToken: builder.query<AuthResponse, string>({
      query: (refreshToken) => ({
        url: 'oauth2/token?grant_type=refresh_token',
        params: {
          refresh_token: refreshToken,
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        dispatch(setAuthToken(data))
      },
    }),
  }),
})

export const {
  useLazyGetAuthTokenQuery,
  useLazyGetAuthTokenWithRefreshTokenQuery,
  usePostGoogleRegistrationMutation,
  usePostRegistrationMutation,
  useLoginCodeMutation,
  usePostOTPCodeMutation,
} = authApi
export default authApi
