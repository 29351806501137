import { CircularProgress } from '@mui/material'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { Text } from 'components/atoms/Text/Text'
import {
  EChartMetric,
  EChartType,
  TCompany,
  metricString,
} from 'components/organisms/Charts/utils/types'
import { ReactNode } from 'react'
import { TChartParameters } from '../../../../features/hooks/useChart'

type ChartContainerType = {
  formData: TChartParameters | Record<string, never>
  chartData: any
  isChartLoading: boolean
  showPrice: boolean
  children: ReactNode
}

const ChartContainer = ({
  formData,
  chartData,
  isChartLoading,
  showPrice,
  children,
}: ChartContainerType) => {
  const { comparisonPeriodInDays, movingAverageInDays, metric, chartType } = formData

  return (
    <>
      {isChartLoading && (
        <FlexBoxFullHeight justifyContent='center' alignItems='center'>
          <CircularProgress />
        </FlexBoxFullHeight>
      )}

      {!isChartLoading && chartData.length > 0 && (
        <>
          {chartType === EChartType.Scatter && (
            <Text align='center'>
              Use the scroll wheel to zoom the X axis and ctrl + the scroll wheel to zoom the Y axis
            </Text>
          )}
          <ChartTitle
            metric={metric}
            chartType={chartType}
            movingAverageInDays={movingAverageInDays}
            comparisonPeriod={comparisonPeriodInDays}
            companies={formData.companies}
            company={formData.company}
            showPrice={showPrice}
          />
          {children}
          <MetricTitle
            metric={metric}
            chartType={chartType}
            movingAverageInDays={movingAverageInDays}
            comparisonPeriod={comparisonPeriodInDays}
          />
        </>
      )}
    </>
  )
}

const dateComparisonStringHash: { [key: number]: string } = {
  365: 'YoY',
  90: 'QoQ',
  30: 'MoM',
  730: '2-year Compare',
  1095: '3-year Compare',
}

const movingAverageStringHash: { [key: number]: string } = {
  7: '7-day',
  30: '30-day',
  90: '90-day',
  365: '365-day',
}

const MetricTitle = ({
  chartType,
  metric,
  movingAverageInDays,
  comparisonPeriod,
}: {
  chartType: EChartType
  metric: EChartMetric
  movingAverageInDays: number
  comparisonPeriod: number
}) => {
  const capMetric = metric === EChartMetric.Visits ? 'Web Traffic Score' : metricString[metric]
  const prefix = metric === EChartMetric.Visits ? '' : 'Consumer '

  let title = `Percent change in ${prefix}${capMetric} ${dateComparisonStringHash[comparisonPeriod]}, ${movingAverageInDays} day moving avg`

  if (chartType === EChartType.Pie) {
    title = `Percent Share of ${prefix}${capMetric} over the last ${movingAverageInDays} days`
  } else if (chartType === EChartType.Line) {
    title = `${prefix}${capMetric}, ${movingAverageInDays}-day moving avg`
  } else if (chartType === EChartType.Scatter) {
    title = `Change in ${prefix}${capMetric} ${dateComparisonStringHash[comparisonPeriod]}, ${movingAverageInDays} day moving avg`
  }

  if (metric === EChartMetric.Happiness && chartType !== EChartType.BarChange) {
    title = `${capMetric}, past ${movingAverageInDays} days`
  }

  return (
    <Text
      fontWeight={700}
      textAlign='center'
      marginTop='20px
    '
    >
      {title}
    </Text>
  )
}

const ChartTitle = ({
  chartType,
  metric,
  movingAverageInDays,
  comparisonPeriod,
  companies,
  company,
  showPrice,
}: {
  chartType: EChartType
  metric: EChartMetric
  movingAverageInDays: number
  comparisonPeriod: number
  companies: TCompany[]
  company: TCompany | null
  showPrice: boolean
}) => {
  const capMetric = metric === EChartMetric.Visits ? 'Web Traffic Score' : metricString[metric]
  const prefix = metric === EChartMetric.Visits ? '' : 'Consumer '

  let title = `${prefix}${capMetric} Change ${dateComparisonStringHash[comparisonPeriod]}`

  if (chartType === EChartType.Pie) {
    title = `${prefix}${capMetric} Share, past ${movingAverageInDays} days`

    if (metric === EChartMetric.Happiness) {
      title = `${company?.ticker} ${prefix}${capMetric}`
    }
  } else if (chartType === EChartType.BarNow) {
    title = `${prefix}${capMetric}`
  } else if (chartType === EChartType.Line) {
    if (companies.length > 1) {
      title = `${prefix}${capMetric} Comparison`
    } else {
      title = `${companies[0].ticker} ${prefix}${capMetric}`

      if (showPrice) {
        title = `${companies[0].ticker} ${prefix}${capMetric} vs Stock Price`
      }
    }
  } else if (chartType === EChartType.Scatter) {
    title = `${prefix}${capMetric} vs Happiness`
  }

  return (
    <Text variant='h2' marginTop='40px' textAlign='center'>
      {title}
    </Text>
  )
}

export default ChartContainer
