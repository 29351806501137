import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  EChartMetric,
  EDatasetScope,
  EDatasetSource,
  TCompany,
} from 'components/organisms/Charts/utils/types'
import { TChartParameters } from 'features/hooks/useChart'
import { camelCase, mapKeys } from 'lodash'
import { DateTime } from 'luxon'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'

export type TChart = {
  id: number
  title: string
  description: string
  preview: { [key: string]: string }
  createdAt: DateTime
  chartParams: any
}

const chartsApi = createApi({
  reducerPath: 'chartApi',
  tagTypes: ['Charts'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    getTickers: builder.query<Array<TCompany>, void>({
      query: () => 'companies/tickers',
    }),

    postChart: builder.mutation<
      void,
      TChartParameters & {
        metric: EChartMetric
        scope: EDatasetScope
        title: string
        description: string
        image: string
        showPrice: boolean
      }
    >({
      query: ({ image, title, description, ...chartForm }) => {
        const { companies, resolution, metric, scope, showPrice } = chartForm

        const source =
          metric === EChartMetric.Visits ? EDatasetSource.WebTraffic : EDatasetSource.Forecasted

        const metrics = companies.flatMap((company) => {
          const retMetrics = [
            {
              object_id: company.id,
              object_type: 'Company',
              resolution: resolution,
              name: metric,
              source,
              ...(metric !== EChartMetric.Visits && { scope }),
            } as any,
          ]

          if (showPrice) {
            retMetrics.push({
              object_id: company.id,
              object_type: 'Company',
              resolution: resolution,
              name: EChartMetric.Price,
              source: EDatasetSource.Market,
            })
          }

          return retMetrics
        })

        return {
          url: '/user/generated-charts',
          method: 'POST',
          body: {
            title,
            description,
            chart_params: {
              metrics,
              chart_settings: chartForm,
            },
            base_url: process.env.REACT_APP_HOST,
            expires_at: null,
            private: false,
            preview: image,
          },
        }
      },
      invalidatesTags: ['Charts'],
    }),

    fetchPublicChart: builder.query<any, string>({
      query: (id) => `/charts/public/${id}`,
      transformResponse: ({ link, chart_params, preview, title, description, created_at }: any) => {
        const { chart_settings, ...restChartParams } = chart_params
        return {
          link,
          title,
          description,
          preview,
          createdAt: DateTime.fromISO(created_at),
          chartParams: {
            ...restChartParams,
            chartSettings: {
              ...chart_settings,
              referenceDate: DateTime.fromISO(chart_settings.referenceDate),
              startDate: DateTime.fromISO(chart_settings.startDate),
            },
          },
        }
      },
    }),

    fetchUsersChart: builder.query<any, string>({
      query: (id) => `/charts/users/${id}`,
      transformResponse: ({ link, chart_params, preview, title }: any) => {
        const { chart_settings, ...restChartParams } = chart_params
        return {
          link,
          title,
          preview,
          chartParams: {
            ...restChartParams,
            chartSettings: {
              ...chart_settings,
              referenceDate: DateTime.fromISO(chart_settings.referenceDate),
              startDate: DateTime.fromISO(chart_settings.startDate),
            },
          },
        }
      },
    }),

    fetchPublicCharts: builder.query<TChart[], void>({
      query: () => '/charts/public',
      transformResponse: (response: any) => {
        const parsedResponse: TChart[] = response.map((entry: any): TChart => {
          const parsedEntry = mapKeys(entry, (_v, k) => camelCase(k))

          if (entry.created_at) {
            parsedEntry.createdAt = DateTime.fromISO(entry.created_at)
          }

          return parsedEntry as TChart
        })

        return parsedResponse
      },
    }),

    // fetchCharts: builder.query<any, void>({
    //   query: () => '/user/generated-charts',
    //   providesTags: ['Charts'],
    // }),

    deleteChart: builder.mutation<any, number>({
      query: (id) => ({
        url: `/user/generated-charts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Charts'],
    }),
  }),
})

export const {
  useGetTickersQuery,
  usePostChartMutation,
  useFetchUsersChartQuery,
  useFetchPublicChartQuery,
  useFetchPublicChartsQuery,
  useDeleteChartMutation,
} = chartsApi

export default chartsApi
