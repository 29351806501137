import { Alert, Box, Toolbar, styled } from '@mui/material'
import { GlobalMessageContext } from 'App'
import { Text } from 'components/atoms/Text/Text'
import { ReactNode, useContext } from 'react'
import { Link, useMatch } from 'react-router-dom'

const MenuLink = styled(Link)(() => ({
  justifySelf: 'center',
  textDecoration: 'none',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
}))

const LandingPageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',

  '.toolbar': {
    padding: '0 60px !important',
  },

  '.children': {
    width: '700px',
  },

  '.content': {
    padding: '100px 0',

    '.title': {
      fontSize: '45px',
      fontWeight: '600',
    },
  },

  '.footer': {
    backgroundColor: '#F1F1F1',

    '.items-container': {
      padding: '60px 150px 30px',
      display: 'flex',
      gap: '30%',
      justifyContent: 'space-around',

      '.item': {
        width: 0,
        flex: '1 1 0px',
      },
    },
  },

  '.rights': {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    padding: '70px 0',

    img: {
      width: '200px',
    },
  },

  '@media only screen and (max-width: 900px)': {
    '.children': {
      width: '50%',
      padding: '20px',
      gap: '10px',
    },

    '.toolbar': {
      padding: '0 20px !important',
    },

    '.content': {
      padding: '20px 0',

      '.title': {
        fontSize: '25px',
        fontWeight: '600',
      },

      'p, a': {
        fontSize: '12px',
      },
    },

    '.footer': {
      '.items-container': {
        padding: '30px 20px',
        gap: '60px',
        '.item': {
          width: '100%',

          h2: {
            fontWeight: 600,
            fontSize: '16px',
          },

          p: {
            fontSize: '12px',
          },
        },
      },
    },

    '.rights': {
      padding: '30px 0',

      p: {
        fontSize: '10px',
      },

      img: {
        width: '150px',
      },
    },
  },

  '@media only screen and (max-width: 600px)': {
    '.children': {
      width: '100%',
    },

    '.footer': {
      flexDirection: 'column',
      gap: '20px',
      padding: '30px',
    },
  },
}))

const LandingPageContainer = ({ children }: { children: ReactNode }) => {
  const { globalMessage, setGlobalMessage } = useContext(GlobalMessageContext)

  const isSignIn = useMatch('/sign_in')

  const onAlertCloseHandler = () => {
    setGlobalMessage(undefined)
  }

  return (
    <LandingPageBox>
      <Toolbar
        className='toolbar'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          width: '100%',
          height: '100px',
          backgroundColor: '#222E41',
        }}
      >
        <a href='https://likefolio.com/' style={{ justifySelf: 'center' }}>
          <Box component='img' src='/assets/imgs/likefolio_logo_white.png' width='170px' />
        </a>
        <Box display='flex' gap='70px'>
          {isSignIn ? (
            <MenuLink to='/subscribe'>
              <Text variant='h3'>Sign Up</Text>
            </MenuLink>
          ) : (
            <MenuLink to='/sign_in'>
              <Text variant='h3'>Log In</Text>
            </MenuLink>
          )}
        </Box>
      </Toolbar>

      {globalMessage && (
        <Alert
          color={globalMessage.type}
          variant='standard'
          onClose={onAlertCloseHandler}
          sx={{ margin: '10px 0px' }}
        >
          {globalMessage.message}
        </Alert>
      )}

      <Box
        flex={1}
        className='content'
        display='flex'
        height='100%'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Box className='children' display='flex' flexDirection='column' gap='30px'>
          {children}
        </Box>
      </Box>
      <Box className='footer'>
        <Box className='items-container'>
          <Box className='item'>
            <Text variant='h2' color='primary' fontWeight={500} marginBottom='10px'>
              About LikeFolio
            </Text>
            <Text variant='body2'>
              LikeFolio analyzes social media data to accurately predict shifts in consumer behavior
              and <b>identify big opportunities in stock and crypto markets.</b>
            </Text>
          </Box>
          <Box className='item'>
            <Text variant='h2' color='primary' fontWeight={500} marginBottom='10px'>
              Quick Links
            </Text>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <a href='https://likefolio.com/' style={{ textDecoration: 'none', color: 'black' }}>
                <Text variant='body2'>LikeFolio Home</Text>
              </a>
              <a
                href='https://likefolio.com/privacy-policy/'
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <Text variant='body2'>Privacy Policy</Text>
              </a>
            </Box>
          </Box>
        </Box>
        <Text textAlign='center' sx={{ paddingBottom: '20px' }}>
          ©2024 LikeFolio. All Right Reserved.
        </Text>
      </Box>
    </LandingPageBox>
  )
}

export default LandingPageContainer
