import { Button, styled } from '@mui/material'
import { theme } from 'utils/theme/index'

const RoundButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'sideInput' })<{
  sideInput?: boolean
}>(({ sideInput = false }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  textTransform: 'none',
  textDecoration: 'none',
  borderRadius: sideInput ? '0 30px 30px 0' : '30px',
  fontSize: '20px',
  fontWeight: 600,
  padding: '7px 30px 7px 22px',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },

  '@media only screen and (max-width: 900px)': {
    fontSize: '16px',
    padding: '4px 12px',
  },
}))

export default RoundButton
