import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import RoundInput from 'components/atoms/RoundInput/RoundInput.component'
import { Text } from 'components/atoms/Text/Text'
import { Controller, useForm } from 'react-hook-form'
import { TRegistrationForm } from 'types/authForm'
import { theme } from 'utils/theme/index'
import * as yup from 'yup'

const schema = yup
  .object({
    email: yup.string().label('Email').required().email(),
  })
  .required()

export type TSubscribeFormProps = {
  registrationLabel: string
  isSubmiting: boolean
  onSubmitHandler: (form: TRegistrationForm) => void
}

const SubscribeFormPure = ({
  registrationLabel,
  isSubmiting,
  onSubmitHandler,
}: TSubscribeFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TRegistrationForm>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  })

  const emailError = errors['email']

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
      }}
    >
      <Box display='flex' justifyContent='left' flexDirection='column'>
        <Text
          color={theme.palette.error.contrastText}
          variant='body2'
          marginBottom='6px'
          textAlign='center'
        >
          {emailError?.message}
        </Text>
        <Box display='flex' justifyContent='center'>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <RoundInput
                {...field}
                name='email'
                placeholder='Type your email...'
                sideButton
                sx={{ width: '75%' }}
              />
            )}
          />
          <RoundButton sideInput type='submit' disabled={isSubmiting}>
            {registrationLabel}
          </RoundButton>
        </Box>
      </Box>
    </form>
  )
}

export default SubscribeFormPure
