import * as am5 from '@amcharts/amcharts5'
import { IXYAxis } from '@amcharts/amcharts5/.internal/charts/xy/series/XYSeries'
import * as am5stock from '@amcharts/amcharts5/stock'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { SerieXYConfig } from './types'

type TChartOrientation = 'vertical' | 'horizontal'

type TAxisSettings = {
  orientation?: TChartOrientation
  categoryFontSize?: number
}

export function generateRootElement(id: string) {
  const root = am5.Root.new(id)

  const myTheme = am5.Theme.new(root)

  myTheme.rule('Label').setAll({
    fontFamily: 'Modern Era Regular',
  })

  root.setThemes([am5themes_Animated.new(root), myTheme])

  return root
}

export function generateStockChart(root: am5.Root, settings?: am5stock.IStockPanelSettings) {
  const chart = root.container.children.push(am5stock.StockChart.new(root, {}))
  const mainPanel = chart.panels.push(
    am5stock.StockPanel.new(root, {
      panX: true,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.horizontalLayout,
      ...settings,
    }),
  )

  return { chart, mainPanel }
}

export function generateXYChart(root: am5.Root, settings?: am5stock.IStockPanelSettings) {
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      panY: false,
      wheelX: 'panX',
      wheelY: 'zoomX',
      layout: root.verticalLayout,
      ...settings,
    }),
  )

  chart.zoomOutButton.set('forceHidden', true)

  return chart
}

const generateAxisRenderer = (root: am5.Root, orientation: TChartOrientation, opposite = false) => {
  if (orientation == 'vertical') {
    return am5xy.AxisRendererY.new(root, { opposite })
  }

  return am5xy.AxisRendererX.new(root, { opposite })
}

export function generateValueAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  settings: am5xy.IAxisRendererYSettings & { title?: string; symbol?: string },
  orientation: TChartOrientation = 'vertical',
) {
  const axisRenderer = generateAxisRenderer(root, orientation, settings.opposite)

  const valueAxis = am5xy.ValueAxis.new(root, {
    renderer: axisRenderer,
    numberFormat: ` ${settings.symbol || ''} #.##`,
    tooltip: am5.Tooltip.new(root, { themeTags: ['axis'] }),
  })

  if (orientation === 'vertical') {
    chart.yAxes.push(valueAxis)
  } else {
    chart.xAxes.push(valueAxis)
  }

  let rotation = orientation === 'horizontal' ? 0 : 90
  rotation = settings.opposite ? rotation : rotation * -1

  let positionX = orientation === 'horizontal' ? am5.p50 : 120
  positionX = settings.opposite ? positionX : am5.percent(20)

  let positionY = orientation === 'horizontal' ? am5.percent(50) : am5.percent(50)

  if (settings.title) {
    valueAxis.children.unshift(
      am5.Label.new(root, {
        rotation: rotation,
        text: settings.title,
        y: positionY,
        x: positionX,
        centerX: am5.p50,
        fontSize: 18,
        fontWeight: 'bold',
      }),
    )
  }

  if (orientation === 'horizontal') {
    axisRenderer.labels.template.setAll({
      paddingTop: 30,
      isMeasured: false,
      centerY: 30,
    })
  }

  return valueAxis
}

export function generateDateAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  orientation: TChartOrientation = 'horizontal',
) {
  const axisRenderer = generateAxisRenderer(root, orientation)

  const dateAxis = am5xy.GaplessDateAxis.new(root, {
    baseInterval: {
      timeUnit: 'day',
      count: 1,
    },
    renderer: axisRenderer,
  })

  if (orientation === 'vertical') {
    chart.yAxes.push(dateAxis)
  } else {
    chart.xAxes.push(dateAxis)
  }

  axisRenderer.labels.template.setAll({ paddingTop: 16 })

  return dateAxis
}

export function generateCategoryAxis(
  root: am5.Root,
  chart: am5xy.XYChart,
  { orientation = 'horizontal', categoryFontSize = 14 }: TAxisSettings,
) {
  const axisRenderer = generateAxisRenderer(root, orientation)

  const categoryAxis = am5xy.CategoryAxis.new(root, {
    categoryField: 'category',
    renderer: axisRenderer,
  })

  if (orientation === 'vertical') {
    chart.yAxes.push(categoryAxis)
  } else {
    chart.xAxes.push(categoryAxis)
  }

  axisRenderer.labels.template.setAll({
    fontSize: `${categoryFontSize}px`,
    fontWeight: 'bold',
    paddingTop: 16,
  })

  return categoryAxis
}

export function addDataSeries(
  root: am5.Root,
  chart: am5xy.XYChart,
  seriesType: 'line' | 'column',
  categoryAxis: IXYAxis | am5xy.CategoryDateAxis<am5xy.AxisRenderer>,
  seriesConfig: SerieXYConfig[],
  orientation: TChartOrientation = 'vertical',
) {
  return seriesConfig.reduce((map, serieConfig) => {
    let currentSerie: am5xy.LineSeries | am5xy.ColumnSeries

    const type = serieConfig.seriesType ?? seriesType

    switch (type) {
      case 'line': {
        const seriesTooltip = am5.Tooltip.new(root, {
          labelText: "{name} {valueY.formatNumber('#.##')}",
        })

        const options = {
          name: serieConfig.name,
          valueXField: 'date',
          valueYField: serieConfig.fieldY,
          xAxis: categoryAxis,
          yAxis: chart.yAxes.getIndex(serieConfig.axis) as IXYAxis,
          tooltip: seriesTooltip,
        } as any

        seriesTooltip.adapters.add('labelText', (value, target) => {
          const dataItem = target.dataItem as any

          if (dataItem && serieConfig.id === 'price') {
            if (Math.abs(Number(dataItem.get('valueY'))) < 10) {
              return `{name}: ${
                serieConfig.metricStart ? serieConfig.metricStart : ''
              }{valueY.formatNumber('#.#')}${serieConfig.metricEnd ? serieConfig.metricEnd : ''}`
            }

            return `{name}: ${
              serieConfig.metricStart ? serieConfig.metricStart : ''
            }{valueY.formatNumber('#.')}${serieConfig.metricEnd ? serieConfig.metricEnd : ''}`
          }

          return `{name}: ${
            serieConfig.metricStart ? serieConfig.metricStart : ''
          }{valueY.formatNumber('#.#')}${serieConfig.metricEnd ? serieConfig.metricEnd : ''}`
        })

        if (serieConfig.color) {
          const color = am5.color(serieConfig.color)
          options.stroke = color
          options.fill = color
        }

        if (serieConfig.colorIndex) {
          const color = chart.get('colors')?.getIndex(serieConfig.colorIndex * 2)
          options.stroke = color
          options.fill = color
        }

        currentSerie = chart.series.push(am5xy.LineSeries.new(root, options))

        currentSerie.strokes.template.setAll({
          strokeWidth: 3,
        })

        if (serieConfig.dotted) {
          currentSerie.strokes.template.setAll({
            strokeDasharray: [4, 4],
          })
        }

        // const bulletTooltip = am5.Tooltip.new(root, {
        //   pointerOrientation: 'horizontal',
        // })

        // currentSerie.bullets.push(function (root, series, dataItem) {
        //   const last = series.dataItems[series.dataItems.length - 1]
        //   if (last.uid === dataItem.uid) {
        //     return am5.Bullet.new(root, {
        //       sprite: am5.Circle.new(root, {
        //         radius: 1,
        //         centerY: am5.p100,
        //         centerX: am5.p50,
        //         fill: series.get('stroke'),
        //         showTooltipOn: 'always',
        //         tooltipText: '{name}',
        //         tooltip: bulletTooltip,
        //       }),
        //     })
        //   }
        // })

        currentSerie.data.setAll(serieConfig.data ?? ([] as any))

        break
      }

      case 'column': {
        const directionalOptions =
          orientation === 'vertical'
            ? {
                valueYField: serieConfig.fieldY,
                categoryXField: 'category',
                yAxis: chart.yAxes.getIndex(serieConfig.axis) as IXYAxis,
                xAxis: categoryAxis,
              }
            : {
                valueXField: serieConfig.fieldY,
                categoryYField: 'category',
                yAxis: categoryAxis,
                xAxis: chart.xAxes.getIndex(serieConfig.axis) as IXYAxis,
              }

        currentSerie = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            ...directionalOptions,
            name: serieConfig.name,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'left',
              labelText: "{valueXWorking.formatNumber('#.')}",
            }),
          }),
        )

        currentSerie.bullets.push(function () {
          const label = am5.Label.new(root, {
            fill: am5.color('#000'),
            centerY: 20,
            fontSize: 20,
            fontWeight: 'bold',
            centerX: 0,
            populateText: true,
          })

          label.adapters.add('text', function (fill, target) {
            const dataItem = target.dataItem as any

            if (dataItem) {
              let value = dataItem.get('valueX', 0) as number

              if (orientation === 'vertical') value = dataItem.get('valueY', 0) as number

              if (value < 0 || !serieConfig.plusSign) {
                return `${Math.round(value)}${serieConfig.metricEnd}`
              }

              return `+${Math.round(value)}${serieConfig.metricEnd}`
            }
          })

          const allNegativeOrZero = serieConfig.data?.every((data) => data.value <= 0)

          label.adapters.add('centerX', function (centerY, target) {
            const dataItem = target.dataItem as any
            if (dataItem) {
              const value = dataItem.get('valueX', 0) as number
              if (value === 0 && allNegativeOrZero) {
                return am5.percent(100)
              } else if (value < 0) {
                return am5.percent(100)
              }
            }

            return centerY
          })

          return am5.Bullet.new(root, {
            locationX: 1,
            sprite: label,
          })
        })

        const columns = currentSerie.columns

        columns.template.adapters.add('fill', function (fill, target) {
          return chart.get('colors')?.getIndex(columns.indexOf(target))
        })

        categoryAxis.data.setAll(serieConfig.data ?? ([] as any))
        currentSerie.data.setAll(serieConfig.data ?? ([] as any))

        break
      }
    }
    map[serieConfig.id] = currentSerie

    return map
  }, {} as { [key: string]: any })
}

export function toggleVisibility(index: number, chart: am5xy.XYChart, visible: boolean) {
  const series = chart.series.getIndex(index)

  if (!series?.get('visible') || visible) {
    chart.yAxes.getIndex(0)?.show()
    series?.show()
  } else {
    chart.yAxes.getIndex(0)?.hide()
    series?.hide()
  }
}

export async function copyToClipboard(textToCopy: string) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy)
  } else {
    const textArea = document.createElement('textarea')
    textArea.value = textToCopy

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = 'absolute'
    textArea.style.left = '-999999px'

    document.body.prepend(textArea)
    textArea.select()

    try {
      document.execCommand('copy')
    } catch (error) {
      console.error(error)
    } finally {
      textArea.remove()
    }
  }
}
