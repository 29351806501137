import styled from '@emotion/styled'
import { Badge, Box } from '@mui/material'
import { Text } from 'components/atoms/Text/Text'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { TPost } from 'store/types/entities'
import { theme } from 'utils/theme'

export type TPostBoxProps = {
  posts: Array<TPost>
  lastSignInAt: DateTime | undefined
  postKey: string | undefined
  onSideMenuCloseClickHandler: () => void
}

const PostContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '5px 0 0',
  justifyContent: 'space-between',
}))

const PostLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'seeing',
})<{ seeing?: boolean }>(({ seeing = false }) => ({
  textDecoration: 'none',
  display: 'flex',
  columnGap: '6px',
  alignItems: 'center',
  padding: '10px 0 0 40px',
  width: '95%',
  color: seeing ? theme.palette.primary.main : 'white',
}))

const PostBox = ({ posts, postKey, lastSignInAt, onSideMenuCloseClickHandler }: TPostBoxProps) => {
  const [internalPosts, setInternalPosts] = useState<Array<TPost>>(posts)

  useEffect(() => {
    setInternalPosts(posts)
  }, [posts])

  const onLinkClickHandler = useCallback(
    (post: TPost) => {
      onSideMenuCloseClickHandler()

      const newPosts = internalPosts.map((postFromMap) => {
        if (postFromMap.id === post.id) {
          return { ...postFromMap, visited: true }
        }

        return postFromMap
      })

      setInternalPosts(newPosts)
    },
    [internalPosts],
  )

  return (
    <>
      {internalPosts.map((post) => (
        <PostContainer key={post.key}>
          <PostLink
            onClick={() => {
              onLinkClickHandler(post)
            }}
            to={`/posts/${post.key}`}
            seeing={postKey === post.key}
            rel='noreferrer'
          >
            <Text flex={1} variant='body2' padding='0 2px' fontWeight={post.visited ? '' : 'bold'}>
              {post.title}

              {!post.visited && lastSignInAt && post.publishedAt >= lastSignInAt && (
                <Badge
                  badgeContent='New'
                  color='primary'
                  sx={{ paddingRight: '24px' }}
                  data-testid='badge'
                />
              )}
            </Text>
          </PostLink>
        </PostContainer>
      ))}
    </>
  )
}
export default PostBox
