import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { AuthState } from '../apis/types'

const initialState: AuthState = {
  authToken: null,
  refreshToken: null,
  authTokenExpires: null,
  previousUrl: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (
      state,
      action: PayloadAction<{ access_token: string; refresh_token: string; expires_in: number }>,
    ) => {
      state.authToken = action.payload.access_token

      if (action.payload.refresh_token) {
        state.refreshToken = action.payload.refresh_token
      }

      state.authTokenExpires = DateTime.now()
        .plus({ seconds: action.payload.expires_in })
        .toUnixInteger()
    },
    setPreviousUrl: (state, action: PayloadAction<string>) => {
      state.previousUrl = action.payload
    },
    logout: (state) => {
      state.authToken = null
      state.refreshToken = null
      state.authTokenExpires = null
      state.previousUrl = null
    },
  },
})

export const { setAuthToken, setPreviousUrl, logout } = authSlice.actions
export default authSlice.reducer
