import useSubscription from 'features/hooks/useSubscription'
import { useCallback, useMemo } from 'react'
import { useLazyGetPostsQuery } from 'store/apis/posts/postsApi'
import { TPostTimeFrame } from 'store/apis/posts/types'
import { useDebounce } from 'use-debounce'
import PostList, { TPostsList } from './PostList.component'

const TimeFramePostsList = ({
  onSideMenuCloseClickHandler,
  timeFrame,
  filter,
  tags,
  color = '#70BF54',
}: {
  filter: string
  tags: Array<string>
  color?: string
  timeFrame: TPostTimeFrame
} & Pick<TPostsList, 'onSideMenuCloseClickHandler'>) => {
  const debouncedSearchQuery = useDebounce(filter, 200)
  const [getPosts] = useLazyGetPostsQuery()
  const { isFreeUser } = useSubscription()

  const getTimeFramePosts = useCallback(
    async (page: number, perPage: number) => {
      return (
        await getPosts({
          title: filter,
          tags,
          pinned: false,
          timeframe: timeFrame,
          page,
        }).unwrap()
      ).posts
    },
    [debouncedSearchQuery[0], isFreeUser, tags],
  )

  const name = useMemo(() => {
    switch (timeFrame) {
      case 'this-month':
        return 'Previous 30 days:'

      case 'this-week':
        return 'Previous 7 days:'
    }

    return "Today's posts:"
  }, [timeFrame])

  return (
    <PostList
      name={name}
      fetchData={getTimeFramePosts}
      color={color}
      onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
    />
  )
}

export default TimeFramePostsList
