import { Box } from '@mui/material'
import { GlobalMessageContext, PostModalContext } from 'App'
import LikefolioDialog from 'components/organisms/LikefolioDialog'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { usePostUserPostMutation } from 'store/apis/posts/userPostsApi'
import { TPost } from 'store/types/entities'
import PublishPostForm, {
  TPublishPostFormAndImages,
} from '../../../components/organisms/FormPost/PostForm.component'

const CreatePostModal = ({ open, post }: { open: boolean; post?: Partial<TPost> }) => {
  const [postPost, { isLoading }] = usePostUserPostMutation()

  const { setGlobalMessage } = useContext(GlobalMessageContext)
  const { closePostModal } = useContext(PostModalContext)

  const onSubmitHandler = async (form: TPublishPostFormAndImages) => {
    await postPost(form).unwrap()

    closePostModal()

    setGlobalMessage({
      type: 'success',
      message: 'Post created successfully',
    })
  }

  const onClosetHandler = () => {
    closePostModal()
  }

  return (
    <>
      <LikefolioDialog
        title='New Post'
        isOpen={!isMobile && open}
        onClose={onClosetHandler}
        closeOutside={false}
      >
        <PublishPostForm post={post} onSubmit={onSubmitHandler} submitting={isLoading} />
      </LikefolioDialog>

      {isMobile && open && (
        <Box
          sx={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            paddingTop: '30px',
            zIndex: 10,
            overflow: 'auto',
          }}
        >
          <PublishPostForm onSubmit={onSubmitHandler} submitting={isLoading} />
        </Box>
      )}
    </>
  )
}

export default CreatePostModal
