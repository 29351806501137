import { TextFieldProps } from '@mui/material'
import RoundInput from 'components/atoms/RoundInput/RoundInput.component'
import { Text } from 'components/atoms/Text/Text'
import { Control, useController } from 'react-hook-form'
import { theme } from 'utils/theme/index'

type MTextFieldProps = {
  name: string
  control: Control<any, any>
  sideButton?: boolean
} & TextFieldProps

const TextFieldWithError = ({ name, control, ...props }: MTextFieldProps) => {
  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  })

  const error = errors[name] as any

  return (
    <>
      <RoundInput autoComplete='off' {...field} {...props} />
      {error && (
        <Text color={theme.palette.error.contrastText} variant='body2' marginTop='6px'>
          {error.message}
        </Text>
      )}
    </>
  )
}

export default TextFieldWithError
