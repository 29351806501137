import { GlobalMessageContext } from 'App'
import { DateTime } from 'luxon'
import { useContext, useEffect } from 'react'
import { useMatch, useNavigate, useOutlet } from 'react-router-dom'
import { useLazyGetAuthTokenWithRefreshTokenQuery } from 'store/apis/authApi'
import metricsApi from 'store/apis/metricsApi'
import postsApi from 'store/apis/posts/postsApi'
import { selectAuthToken, selectIsLoggedIn, selectRefreshToken } from 'store/apis/selectors'
import userApi from 'store/apis/userApi'
import { logout, setPreviousUrl } from 'store/slices/authSlice'
import { useAppDispatch, useAppSelector } from 'store/store'

const PrivateRoute = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const outlet = useOutlet()

  const { authTokenExpires } = useAppSelector(selectAuthToken)
  const refreshToken = useAppSelector(selectRefreshToken)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const isPost = Boolean(useMatch('/posts/:key'))

  const { setGlobalMessage } = useContext(GlobalMessageContext)

  const [getAuthTokenWithRefreshToken] = useLazyGetAuthTokenWithRefreshTokenQuery()

  useEffect(() => {
    const checkAuth = async () => {
      const sessionHasExpired = authTokenExpires && authTokenExpires <= DateTime.now()

      if (!isLoggedIn || sessionHasExpired) {
        let message = 'You must login first'

        if (sessionHasExpired && refreshToken) {
          message = 'Your session has expired'

          const response = await getAuthTokenWithRefreshToken(refreshToken).unwrap()

          if (response.access_token) {
            return
          }
        }

        if (isPost) {
          dispatch(setPreviousUrl(location.pathname))

          return
        }

        dispatch(logout())
        dispatch(userApi.util.resetApiState())
        dispatch(metricsApi.util.resetApiState())
        dispatch(postsApi.util.resetApiState())

        setGlobalMessage({
          type: 'warning',
          message: message,
        })

        navigate('/subscribe')
      }
    }

    checkAuth()
  }, [isLoggedIn])

  return isLoggedIn ? outlet : null
}

export default PrivateRoute
