import { DataItem } from 'types/dataItem'

export enum EChartType {
  Line,
  BarChange,
  BarNow,
  Pie,
  Scatter,
}

export type TCompany = {
  id: number
  ticker: string
  name: string
}

export type TChartData = {
  date: string
  value: number
}

export enum EChartMetric {
  Mentions = 'mentions',
  // Demand is called volume in the backend
  Demand = 'volume',
  Happiness = 'happiness',
  Visits = 'visits',
  PositiveSentiment = 'positive-sentiment',
  NegativeSentiment = 'negative-sentiment',
  Price = 'price',
}

export const metricString = {
  [EChartMetric.Mentions.toString()]: 'Mentions',
  [EChartMetric.Demand.toString()]: 'Demand Score',
  [EChartMetric.Happiness.toString()]: 'Consumer Happiness',
  [EChartMetric.Visits.toString()]: 'Web Traffic Score',
}

export enum EDatasetScope {
  Consumer = 'consumer',
}

export enum EDatasetSource {
  Forecasted = 'forecasted',
  WebTraffic = 'web-traffic',
  Market = 'market',
}

export enum EDatasetResolution {
  Daily = 'daily',
  Quarterly = 'quarterly',
}

export const EChartTypeLabels = {
  [EChartType.Line]: 'Line Chart',
  [EChartType.BarChange]: 'Bar Chart (Change)',
  [EChartType.BarNow]: 'Bar Chart (Now)',
  [EChartType.Pie]: 'Pie Chart',
  [EChartType.Scatter]: 'Outlier Grid',
}

type BaseSerieConfig = {
  id: string
  name: string
  color: string
  colorIndex?: number
}

export type ChartConfig = {
  mainAxisTitle: string
  mainAxisSymbol?: string
  secondatyAxisTitle?: string | undefined
  secondaryAxisSymbol?: string
}

export type SerieXYConfig = {
  axis: 0 | 1
  hide?: boolean
  fieldY: string
  data: DataItem[] | undefined
  seriesType?: 'line' | 'column'
  metricEnd?: string
  metricStart?: string
  plusSign?: boolean
  dotted?: boolean
} & BaseSerieConfig
