import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StripeState } from '../apis/types'

const initialState: StripeState = {
  sessionId: null,
}

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload
    },
    expireSessionId: (state) => {
      state.sessionId = null
    },
  },
})

export const { setSessionId, expireSessionId } = stripeSlice.actions
export default stripeSlice.reducer
