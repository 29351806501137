import useSubscription from 'features/hooks/useSubscription'
import { useCallback } from 'react'
import { useLazyGetPostsQuery } from 'store/apis/posts/postsApi'
import { useDebounce } from 'use-debounce'
import PostList, { TPostsList } from './PostList.component'

const TopTradeIdeasPostsList = ({
  onSideMenuCloseClickHandler,
  filter,
  tags,
}: { filter: string; tags: Array<string> } & Pick<TPostsList, 'onSideMenuCloseClickHandler'>) => {
  const debouncedSearchQuery = useDebounce(filter, 400)
  const [getPosts] = useLazyGetPostsQuery()
  const { isFreeUser } = useSubscription()

  const getPinnedPosts = useCallback(
    async (page: number, perPage: number) => {
      return (
        await getPosts({ title: debouncedSearchQuery[0], tags, pinned: true, page }, false).unwrap()
      ).posts
    },
    [debouncedSearchQuery[0], isFreeUser, tags],
  )

  return (
    <PostList
      name='Top Trade Ideas'
      fetchData={getPinnedPosts}
      color='#70BF54'
      onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
    />
  )
}

export default TopTradeIdeasPostsList
