import CloseIcon from '@mui/icons-material/Close'
import { Chip } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'
import { Text } from 'components/atoms/Text/Text'
import { isUndefined, toUpper, uniq } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useGetTagsQuery } from 'store/apis/tagsApi'

const InputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  border: `${theme.palette.primary.main} 2px solid`,
  borderRadius: '30px',
  padding: '8px 8px',

  input: {
    fontSize: '20px',
    backgroundColor: 'transparent',
    color: 'white',
    height: '30px',
    boxSizing: 'border-box',
    padding: '4px 6px',
    maxWidth: '500px',
    width: '0',
    flexGrow: 1,
    border: 0,
    margin: 0,
    outline: 0,
  },

  fieldset: {
    border: 0,
  },
}))

const StyledChip = styled(Chip)({
  fontSize: '20px',
  color: 'white',
  margin: '0 2px',

  svg: {
    color: 'white !important',
  },
})

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  left: 20px;
  top: 55px;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    color: black;
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
)

const ListBoxWrapper = ({
  tagList,
  selectedTags,
  currentTag,
  selectedIndex,
  setSelectedIndex,
  onTagClickHandler,
}: {
  tagList: Array<string>
  selectedTags: Array<string>
  currentTag: string | undefined
  selectedIndex: number | undefined
  setSelectedIndex: (index: number | undefined) => void
  onTagClickHandler: (tag: string) => void
}) => {
  const filteredTags = useMemo(() => {
    if (isUndefined(currentTag)) return []

    const availableTags = tagList.filter((tag) => !selectedTags.includes(tag))

    if (currentTag === '#') return availableTags

    return availableTags.filter((tag) => tag.includes(toUpper(currentTag.substring(1))))
  }, [currentTag])

  const onTagClickLocalHandler = (tag: string) => {
    setSelectedIndex(undefined)
    onTagClickHandler(tag)
  }

  const isOpen = filteredTags.length > 0

  useEffect(() => {
    const keyListener = (ev: KeyboardEvent) => {
      if (
        isOpen &&
        (ev.key === 'ArrowDown' || ev.key === 'ArrowUp' || ev.key === 'Enter' || ev.key === 'Tab')
      ) {
        if (isUndefined(selectedIndex)) {
          setSelectedIndex(0)

          return
        }

        if (ev.key === 'ArrowDown' && selectedIndex < filteredTags.length - 1) {
          const index = selectedIndex + 1
          setSelectedIndex(index)
        }

        if (ev.key === 'ArrowUp' && selectedIndex >= 0) {
          const index = selectedIndex - 1
          setSelectedIndex(index)
        }

        if (ev.key === 'Enter' || ev.key === 'Tab') {
          const tag = filteredTags[selectedIndex]
          onTagClickLocalHandler(tag)
        }
      }
    }

    window.addEventListener('keydown', keyListener)

    return () => window.removeEventListener('keydown', keyListener)
  }, [filteredTags, currentTag, selectedIndex])

  return isOpen ? (
    <Listbox>
      {filteredTags.map((tag, index) => (
        <li
          onClick={() => onTagClickLocalHandler(tag)}
          key={tag}
          onMouseEnter={() => setSelectedIndex(index)}
          onMouseLeave={() => setSelectedIndex(undefined)}
          style={{
            backgroundColor: selectedIndex === index ? '#eaeaea' : 'white',
          }}
        >
          <Text>#{tag}</Text>
        </li>
      ))}
    </Listbox>
  ) : null
}

function getTextWidth(text: string) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (context) {
    context.font = getComputedStyle(document.body).font
    return context.measureText(text).width
  }

  return 0
}

const TagTextFilterField = ({
  onFilterChange,
  onTagsChange,
  filter,
  tags,
}: {
  filter: string
  tags: Array<string>
  onFilterChange: (filter: string) => void
  onTagsChange: (tags: Array<string>) => void
}) => {
  const [internalFilter, setInternalFilter] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)

  const { data: tagList = [], isFetching: isTagsFetching } = useGetTagsQuery()

  const allTags = tagList.map(toUpper)

  const currentTag = useMemo(() => {
    const matches = internalFilter.match(/#\w*/gi)

    if (matches) return matches[0]
  }, [internalFilter])

  const currentTagWithoutHash = useMemo(() => {
    return currentTag?.substring(1)
  }, [currentTag])

  const onFieldChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setInternalFilter(event.target.value)

    const matches = event.target.value.match(/#\w*/gi)

    if (!matches) onFilterChange(event.target.value)
  }

  const pushNewTag = (tag: string) => {
    const newTags = [...tags]
    newTags.push(tag)
    onTagsChange(uniq(newTags))

    const newText = internalFilter.replace(currentTag || '', '')

    setInternalFilter(newText)
  }

  const removeTag = (targetTag: string) => {
    const newTags = tags.filter((tag) => tag !== targetTag)
    onTagsChange(newTags)
  }

  const onKeyPressedHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault()
        break

      case ' ':
      case 'Enter':
      case 'Tab':
        if (currentTagWithoutHash && isUndefined(selectedIndex)) {
          event.preventDefault()

          pushNewTag(currentTagWithoutHash)
        }
        break
    }
  }

  const onTagClickHandler = (tag: string) => {
    pushNewTag(tag)
  }

  return (
    <>
      <InputWrapper>
        <input
          value={internalFilter}
          onChange={onFieldChangeHandler}
          onKeyDown={onKeyPressedHandler}
          placeholder='Filter...'
          style={{ width: `${getTextWidth(filter) * 1.6}px` }}
        />
        {tags.map((tag) => (
          <StyledChip
            key={tag}
            label={`#${toUpper(tag)}`}
            variant='outlined'
            deleteIcon={<CloseIcon />}
            onDelete={() => {
              removeTag(tag)
            }}
          />
        ))}
      </InputWrapper>
      <ListBoxWrapper
        tagList={allTags}
        selectedTags={tags}
        currentTag={currentTag}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        onTagClickHandler={onTagClickHandler}
      />
    </>
  )
}

export default TagTextFilterField
