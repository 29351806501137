import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { camelCase, mapKeys } from 'lodash'
import { DateTime } from 'luxon'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'

export type TAccount = {
  email: string
  name: string
  lastSignInAt: DateTime
  currentSignInAt: DateTime
}

export type TPassword = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export type TCapability = 'chart_explorer' | TCapabilitySubscription

export type TCapabilitySubscription =
  | 'chart_explorer_core'
  | 'chart_explorer_pro'
  | 'chart_explorer_admin'

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    fetchAccount: builder.query<TAccount, void>({
      query: () => 'user/account',
      transformResponse: (response: any) => {
        const account = mapKeys(response, (_v, k) => camelCase(k)) as TAccount

        account.lastSignInAt = DateTime.fromISO(response.last_sign_in_at)
        account.currentSignInAt = DateTime.fromISO(response.current_sign_in_at)

        return account
      },
    }),
    fetchCapabilities: builder.query<Array<TCapability>, void>({
      query: () => 'user/capabilities',
    }),
    putAccount: builder.mutation<void, { name: string }>({
      query: ({ name }) => ({
        url: 'user/account',
        method: 'PUT',
        body: {
          name,
        },
      }),
    }),
    putPassword: builder.mutation<void, TPassword>({
      query: ({ currentPassword, newPassword, newPasswordConfirmation }) => ({
        url: 'user/password',
        method: 'PUT',
        body: {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordConfirmation,
        },
      }),
    }),
  }),
})

export const {
  useFetchCapabilitiesQuery,
  useFetchAccountQuery,
  usePutAccountMutation,
  usePutPasswordMutation,
} = userApi
export default userApi
