import { renderToString } from 'react-dom/server'

import { Box, ClickAwayListener, Tooltip } from '@mui/material'
import { PostModalContext } from 'App'
import BorderChartContainer from 'components/atoms/BorderChartContainer/BorderChartContainer.component'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import { Text } from 'components/atoms/Text/Text'
import LineChartWrapper from 'components/organisms/Charts/LineChartWrapper/LineChartWrapper.component'
import PieChart from 'components/organisms/Charts/PieChart'
import StockChart from 'components/organisms/Charts/StockChart'
import { EChartType, EDatasetResolution } from 'components/organisms/Charts/utils/types'
import { PageContainer } from 'components/organisms/PageContainer/PageContainer'
import SanitizeHTML from 'components/organisms/SanitizedHTML/SanitizedHTML.component'
import useSubscription from 'features/hooks/useSubscription'
import { toPng } from 'html-to-image'
import { keys } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { TChart } from 'store/apis/chartsApi'
import { selectIsLoggedIn } from 'store/apis/selectors'
import { useAppSelector } from 'store/store'
import { theme } from 'utils/theme/index'
import BubbleChart from '../../components/organisms/Charts/BubbleChart'
import ChartContainer from '../../components/organisms/Charts/ChartContainer/ChartContainer'
import ChartsForm from '../../features/components/ChartForm/ChartsForm'
import { TChartParameters, useChart } from '../../features/hooks/useChart'

const Charts = ({ chart, isPublic }: { chart?: TChart; isPublic?: boolean }) => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const { openPostModal } = useContext(PostModalContext)

  const urlParams = Object.fromEntries(searchParams.entries()) as any
  if (urlParams.companies) {
    urlParams.companies = urlParams.companies.split(',')
  }

  const [chartData, setChartData] = useState<any[]>([])
  const [formData, setFormData] = useState<any>(urlParams || ({} as any))
  const [isChartLoading, setIsChartLoading] = useState<boolean>(false)
  const [showPrice, setShowPrice] = useState<boolean>(true)
  const [showClipboardMessage, setShowClipboardMessage] = useState<boolean>(false)
  const [publishChart, setPublishChart] = useState<any>(null)

  const { getChartData, error } = useChart(isPublic ? params.id : undefined)
  const { canPost } = useSubscription()

  const imageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (chart) {
      const submitChart = async () => {
        await onSubmit(chart.chartParams.chartSettings)
      }
      submitChart()
    }
  }, [chart])

  const onSaveChart = () => {
    if (imageRef.current === null) {
      return
    }

    toPng(imageRef.current, { cacheBust: true }).then(async (imageData) => {
      const chartData = {
        ...formData,
        image: imageData,
        resolution: EDatasetResolution.Daily,
        showPrice,
      } as any

      const image = renderToString(
        <>
          <h1 style={{ textAlign: 'center' }}>Write a description for your idea</h1>
          <img src={imageData} />
        </>,
      )

      openPostModal({ body: image })
      setPublishChart(chartData)
    })
  }

  const onSubmit: SubmitHandler<TChartParameters> = async (form) => {
    setIsChartLoading(true)

    const chartData = await getChartData(form)
    setFormData(form)
    setIsChartLoading(false)
    setChartData(chartData)
  }

  const { chartType, metric } = formData

  return (
    <PageContainer>
      <Box className='rotate'>
        <Text fontWeight={500} textAlign='center'>
          Please rotate your phone to start building a chart
        </Text>
        <Box component='img' src='/assets/imgs/rotate.png' />
      </Box>

      <Box className='chart-creator'>
        <ChartsForm onSubmit={onSubmit} initialValues={chart?.chartParams.chartSettings} />

        {error ? (
          <Text textAlign='center' color={theme.palette.error.contrastText}>
            {error}
          </Text>
        ) : (
          <>
            {chart && (
              <Box marginTop='30px'>
                <Text variant='h1'>{chart.title}</Text>
                <Text variant='body2'>Created at {chart.createdAt.toISODate()}</Text>
              </Box>
            )}
            <BorderChartContainer>
              <div style={{ background: 'white' }} ref={imageRef}>
                <ChartContainer
                  formData={formData}
                  chartData={chartData}
                  isChartLoading={isChartLoading}
                  showPrice={showPrice}
                >
                  {(chartType === EChartType.BarChange || chartType === EChartType.BarNow) && (
                    <StockChart
                      seriesConfig={{
                        id: 'metric',
                        name: 'Metric',
                        color: '#65af37',
                        axis: 0,
                        fieldY: 'value',
                        data: chartData,
                        seriesType: 'column',
                        plusSign: chartType == EChartType.BarChange,
                        metricEnd: '%',
                      }}
                    />
                  )}
                  {chartType === EChartType.Scatter && (
                    <BubbleChart
                      seriesConfig={[
                        {
                          id: 'metric',
                          name: 'Metric',
                          color: '#65af37',
                          axis: 0,
                          fieldY: 'value',
                          data: chartData,
                          seriesType: 'column',
                        },
                      ]}
                    />
                  )}
                  {chartType === EChartType.Pie && (
                    <PieChart
                      seriesConfig={[
                        {
                          id: 'metric',
                          name: 'Metric',
                          color: '#65af37',
                          axis: 0,
                          fieldY: 'value',
                          data: chartData,
                          seriesType: 'column',
                        },
                      ]}
                    />
                  )}
                  {chartType === EChartType.Line && (
                    <LineChartWrapper
                      metric={metric}
                      chartData={chartData}
                      setShowPrice={setShowPrice}
                      showPrice={showPrice}
                    />
                  )}
                </ChartContainer>
              </div>

              {!isChartLoading && keys(formData).length === 0 && (
                <Box
                  height='400px'
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  gap='10px'
                  justifyContent='center'
                >
                  <Text variant='h3' textAlign='center'>
                    Use inputs above to create your own chart
                  </Text>
                  <Text variant='h3' textAlign='center'>
                    Or
                  </Text>
                  <Text variant='h3' textAlign='center'>
                    Click on a post on the left from our Research Desk
                  </Text>
                </Box>
              )}

              {isLoggedIn && canPost && keys(formData).length > 0 && (
                <ClickAwayListener onClickAway={() => setShowClipboardMessage(false)}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setShowClipboardMessage(false)}
                    open={showClipboardMessage}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title='Link copied to clipboard'
                  >
                    <RoundButton
                      variant='contained'
                      onClick={onSaveChart}
                      // disabled={postingChart}
                      sx={{ width: '300px', margin: '30px auto' }}
                    >
                      Publish this chart
                    </RoundButton>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </BorderChartContainer>

            {chart && (
              <Box marginTop='30px'>
                <SanitizeHTML html={chart.description} />
              </Box>
            )}
          </>
        )}
      </Box>
    </PageContainer>
  )
}

export default Charts
