import { Box, CircularProgress, Divider, Link as MUILink } from '@mui/material'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { Text } from 'components/atoms/Text/Text'
import { PageContainer } from 'components/organisms/PageContainer/PageContainer'
import SanitizeHTML from 'components/organisms/SanitizedHTML/SanitizedHTML.component'
import { DateTime } from 'luxon'
import { useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import SignInWithGoogle from 'features/components/SignInWithGoogle/SignInWithGoogle.component'
import SubscribeForm from 'features/components/SubscribeForm/SubscribeForm.component'
import 'react-quill/dist/quill.core.css'
import { useGetPublicPostQuery } from 'store/apis/posts/postsApi'
import { setPreviousUrl } from 'store/slices/authSlice'
import { useAppDispatch } from 'store/store'
import { PostBox } from './ManagePosts.styles'

const PublicPost = () => {
  const params = useParams()
  const dispatch = useAppDispatch()

  const { data: post, isFetching: isPostFetching } = useGetPublicPostQuery(params.key || '')

  useEffect(() => {
    dispatch(setPreviousUrl(location.pathname))
  }, [])

  const body = useMemo(() => {
    if (post) {
      const dom = new DOMParser().parseFromString(
        `<div id='content'>${post.body}</div>`,
        'text/html',
      )
      const elements = dom.getElementsByTagName('p')
      const elementsList = Array.prototype.slice.call(elements)
      const text = elementsList
        .map((element: HTMLParagraphElement) => element.innerText)
        .join('\n\n')
        .trim()
        .substring(0, 300)

      return `${text}...`
    }

    return ''
  }, [post])

  useEffect(() => {
    if (post) {
      document.title = `LikeFolio Vault - ${post.title}`
    }
  }, [post])

  return (
    <Box
      className='ql-editor'
      sx={{
        height: '100%',
        background: 'linear-gradient(0deg,rgba(255,255,255,1) 0%, rgba(114,191,85,1) 100%)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Link to='/subscribe' style={{ alignSelf: 'center', cursor: 'pointer' }}>
        <Box
          component='img'
          src='/assets/imgs/LikeFolioVaultWhite.png'
          width='300px'
          alignSelf='center'
          marginTop='10px'
        />
      </Link>
      <PageContainer marginTop='0px'>
        {isPostFetching ? (
          <FlexBoxFullHeight justifyContent='center' alignItems='center'>
            <CircularProgress />
          </FlexBoxFullHeight>
        ) : post ? (
          <PostBox className='post'>
            <Text variant='h1' fontWeight={500} textAlign='center'>
              {post.title}
            </Text>
            <div className='author'>
              <Text component='p' textAlign='center' fontSize='20px' margin='0 !important'>
                {post.publishedAt?.toLocaleString(DateTime.DATETIME_SHORT)}
              </Text>
            </div>
            <SanitizeHTML html={body} />
            <Box
              sx={{
                marginTop: '40px',
                img: { width: '250px', margin: '0 auto' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  img: { width: 'auto' },
                }}
              >
                <Text variant='h1' className='title' fontWeight='500' textAlign='center'>
                  To continue Reading...
                </Text>
                <Text variant='h1' className='title' fontWeight='500' textAlign='center'>
                  Sign in with your LikeFolio Vault account
                </Text>
              </Box>
              <Text textAlign='center'>
                <MUILink
                  underline='none'
                  component={Link}
                  textAlign='center'
                  to='/sign_in'
                  fontSize='20px'
                >
                  Sign In
                </MUILink>
              </Text>
              <Divider>
                <Text>Or</Text>
              </Divider>

              <SignInWithGoogle sx={{ zIndex: 2 }} />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '30px',
                }}
              >
                <Text variant='h1' textAlign='center'>
                  ...Or sign up for Vault Access
                </Text>

                <Box width='100%'>
                  <SubscribeForm registrationLabel='Sign Up' />
                </Box>
              </Box>
            </Box>
          </PostBox>
        ) : (
          <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
            <Text variant='h1' textAlign='center' color='white' fontWeight='500'>
              The post doesn&apos;t exist
            </Text>
          </Box>
        )}
      </PageContainer>
    </Box>
  )
}

export default PublicPost
