import styled from '@emotion/styled'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import { Text } from 'components/atoms/Text/Text'
import InfinitList from 'components/organisms/InfinitList/InfinitList.component'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { TPost } from 'store/types/entities'
import PostBox from './PostBox.component'

const PostGroupTitle = styled(Text)<{ color?: string }>(({ color = 'white' }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#262626',
  width: '100%',
  padding: '10px 20px',
  alignSelf: 'end',
  borderRadius: '30px 0 0 30px',
  color: `${color} !important`,
}))

export type TPostsListProps = {
  name: string
  color?: string
  lastSignInAt: DateTime | undefined
  postKey: string | undefined
  startsCollapsed: boolean
  fetchData: (page: number, perPage: number) => Promise<any[]>
  onSideMenuCloseClickHandler: () => void
}

const PostsList = ({
  name,
  color = '#70BF54',
  lastSignInAt,
  startsCollapsed,
  postKey,
  fetchData,
  onSideMenuCloseClickHandler,
}: TPostsListProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(startsCollapsed)

  const toogleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  return (
    <Box>
      <PostGroupTitle variant='h4' fontWeight={500} color={color} onClick={toogleCollapsed}>
        {name} {isCollapsed ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
      </PostGroupTitle>
      {isCollapsed ? null : (
        <InfinitList fetchData={fetchData} perPage={15} height='200px'>
          {(posts: TPost[], isLoading) => {
            return (
              <PostBox
                posts={posts}
                postKey={postKey}
                lastSignInAt={lastSignInAt}
                onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
              />
            )
          }}
        </InfinitList>
      )}
    </Box>
  )
}
export default PostsList
