import LandingPageContainer from 'components/organisms/LandingPageContainer/LandingPageContainer.component'
import ResetPasswordForm from 'features/components/ResetPasswordForm/ResetPasswordForm.component'

const ResetPassword = () => {
  return (
    <LandingPageContainer>
      <ResetPasswordForm />
    </LandingPageContainer>
  )
}

export default ResetPassword
