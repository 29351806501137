import { TPostRequest } from './types'

const transformPostBody = ({
  title,
  body,
  altTitle,
  summary,
  accessFlagRequired,
  chartSettings,
  pinned,
  draft,
  imageIds,
  publishedAt,
  postGroupId,
  tags,
}: TPostRequest) => {
  const replaceExp = /<a\s+(?:[^>]*?\s+)?href=("[^"]*"|'[^']*')[^>]*>/gi
  const regexFunction = function (match: any, p1: any) {
    return '<a target="_blank" href=' + p1 + '>'
  }

  const newBody = body.replace(replaceExp, regexFunction)
  const newSummary = summary?.replace(replaceExp, regexFunction)
  const isFree = accessFlagRequired === 'free'

  const requestBody: any = {
    pinned,
    draft,
    title,
    body: newBody,
    alt_title: isFree ? null : altTitle,
    summary: isFree ? null : newSummary,
    post_group_id: postGroupId !== 0 ? postGroupId : undefined,
    chart_settings: chartSettings,
    image_ids: imageIds,
    tags,
    access_flag_required: isFree ? null : accessFlagRequired,
  }

  if (publishedAt) {
    requestBody.published_at = publishedAt.toUnixInteger()
  }

  return requestBody
}

export default transformPostBody
