import { TChartData } from 'components/organisms/Charts/utils/types'
import { NoDataError } from 'features/hooks/useChart'
import { DateTime, Interval } from 'luxon'

export const calculateDatasetDiff = (
  movingAverageDataset: TChartData[],
  referenceDate: DateTime,
  comparisonPeriodInDays: number,
  happiness: boolean,
) => {
  const lastEntry = movingAverageDataset[movingAverageDataset.length - 1]
  const lastEntryDate = DateTime.fromISO(lastEntry.date).startOf('day')
  const startOfDayReferenceDate =
    lastEntryDate < referenceDate.startOf('day') ? lastEntryDate : referenceDate.startOf('day')

  const previousDate = startOfDayReferenceDate.minus({ days: comparisonPeriodInDays })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentPeriodAmount = movingAverageDataset.find((a) =>
    startOfDayReferenceDate.equals(DateTime.fromISO(a.date)),
  )?.value

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const previousPeriodAmount = movingAverageDataset.find((a) =>
    previousDate.equals(DateTime.fromISO(a.date)),
  )?.value

  if (currentPeriodAmount === undefined || previousPeriodAmount === undefined) {
    throw new NoDataError('Data does not go back far enough for this reference date')
  }

  if (happiness) {
    return Math.round(currentPeriodAmount - previousPeriodAmount)
  }

  if (previousPeriodAmount == 0) {
    return 0
  }

  return Math.round((currentPeriodAmount * 100) / previousPeriodAmount - 100)
}

export const calculateMetricSum = (
  dataset: TChartData[],
  referenceDate: DateTime,
  movingAverage: number,
) => {
  const lastEntry = dataset[dataset.length - 1]
  const lastEntryDate = DateTime.fromISO(lastEntry.date).startOf('day')
  const startOfDayReferenceDate =
    lastEntryDate < referenceDate.startOf('day') ? lastEntryDate : referenceDate.startOf('day')

  const interval = Interval.fromDateTimes(
    startOfDayReferenceDate.minus({ days: movingAverage - 1 }),
    startOfDayReferenceDate.plus({ hours: 1 }),
  )

  const filtedDataset = dataset.filter((entry) => interval.contains(DateTime.fromISO(entry.date)))

  const sum = filtedDataset.reduce((sum, entry) => sum + Number(entry.value), 0)

  return Number(sum)
}
