import { Box } from '@mui/system'
import { Text } from 'components/atoms/Text/Text'
import LandingPageContainer from 'components/organisms/LandingPageContainer/LandingPageContainer.component'

const NoAccess = () => {
  return (
    <LandingPageContainer>
      <Text variant='h1' textAlign='center'>
        PRO MEMBERS ONLY
      </Text>
      <Text textAlign='center'>
        The page you`re trying to access is restricted to PRO members. If interested in a PRO
        membership ($1995/yr) please schedule a demo below
      </Text>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          src='https://scheduler.zoom.us/andy-swan/andy-swan-open-chat?embed=true'
          frameBorder='0'
          style={{ width: '1000px', height: '700px' }}
        />
      </Box>
    </LandingPageContainer>
  )
}

export default NoAccess
