import { TPost } from 'store/types/entities'

export enum EAccessFlag {
  FREE = 'free',
  CORE = 'chart_explorer_core',
  PRO = 'chart_explorer_pro',
  ADMIN = 'chart_explorer_admin',
}

export type TPostRequest = Pick<
  TPost,
  'title' | 'altTitle' | 'summary' | 'body' | 'accessFlagRequired' | 'pinned' | 'draft' | 'tags'
> &
  Partial<Pick<TPost, 'publishedAt' | 'postGroupId' | 'chartSettings'>> & {
    imageIds: Array<number>
  }

export type TUserPostResponse = {
  currentPage: number
  totalPages: number
  posts: Array<TPost>
}

export type TPostFilter = {
  title: string
  postGroupId: number
  draft: boolean
  published?: 'all' | 'published' | 'unpublished'
}

export type TPostTimeFrame = 'today' | 'this-week' | 'this-month'

export type TGetPostParams = {
  title?: string
  tags?: string[]
  pinned?: boolean
  timeframe?: TPostTimeFrame
  page: number
}
