import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { GlobalMessageContext } from 'App'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import TextFieldWithError from 'components/molecules/TextFieldWithError/TextFieldWithError.component'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  useFetchAccountQuery,
  usePutAccountMutation,
  usePutPasswordMutation,
} from 'store/apis/userApi'
import * as yup from 'yup'
import { AuthForm, AuthFormInputsContainer } from '../LoginForm/LoginForm.styled'

type TProfileForm = {
  name: string
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

const ProfileForm = () => {
  const navigate = useNavigate()

  const { setGlobalMessage } = useContext(GlobalMessageContext)

  const [putAccount, { isLoading: isAccountLoading }] = usePutAccountMutation()
  const [putPassword, { isLoading: isPasswordLoading }] = usePutPasswordMutation()

  const { data: account, isFetching } = useFetchAccountQuery()

  const schema = yup
    .object({
      name: yup.string().label('Name').required('Name is required'),
      currentPassword: yup.string().ensure(),
      newPassword: yup
        .string()
        .ensure()
        .when('currentPassword', {
          is: (currentPassword: string) => {
            return currentPassword.length > 0
          },
          then: (schema) => schema.required('New Password is required'),
        }),
      newPasswordConfirmation: yup
        .string()
        .ensure()
        .when('currentPassword', {
          is: (currentPassword: string) => currentPassword.length > 0,
          then: (schema) =>
            schema
              .required('New Password Confirmation is required')
              .oneOf(
                [yup.ref('newPassword'), ''],
                'New Password and New Password Confirmation must match',
              ),
        }),
    })
    .required()

  const onSubmitHandler = async ({ name, ...passwordValues }: TProfileForm) => {
    try {
      await putAccount({ name }).unwrap()

      if (passwordValues.currentPassword) {
        await putPassword(passwordValues).unwrap()
      }

      setGlobalMessage({
        type: 'success',
        message: 'Profile updated successfully',
      })

      navigate('/')
    } catch (error) {
      setGlobalMessage({
        type: 'error',
        message: 'Current password is invalid',
      })
    }
  }

  const { control, handleSubmit } = useForm<TProfileForm>({
    values: {
      name: account?.name || '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  return (
    <AuthForm onSubmit={handleSubmit(onSubmitHandler)}>
      <AuthFormInputsContainer gap='30px !important'>
        <Box display='flex' flexDirection='column' gap='8px'>
          <TextFieldWithError
            name='name'
            control={control}
            placeholder='Name'
            sx={{ width: '100%' }}
          />
        </Box>
        <Box display='flex' flexDirection='column' gap='8px'>
          <TextFieldWithError
            name='currentPassword'
            control={control}
            placeholder='Current Password'
            type='password'
            sx={{ width: '100%' }}
          />
          <TextFieldWithError
            name='newPassword'
            control={control}
            placeholder='New Password'
            type='password'
            sx={{ width: '100%' }}
          />
          <TextFieldWithError
            name='newPasswordConfirmation'
            control={control}
            placeholder='New Password Confirmation'
            type='password'
            sx={{ width: '100%' }}
          />
        </Box>
        <RoundButton
          type='submit'
          variant='contained'
          color='primary'
          disabled={isAccountLoading || isPasswordLoading}
        >
          Save
        </RoundButton>
      </AuthFormInputsContainer>
    </AuthForm>
  )
}

export default ProfileForm
