import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { PostModalContext } from 'App'
import StyledTableCell from 'components/atoms/Table/StylizedTableCell.style'
import StyledTableRow from 'components/atoms/Table/StylizedTableRow.style'
import TableRowsLoader from 'components/atoms/Table/TableLoader.component'
import { Text } from 'components/atoms/Text/Text'
import { PageContainer } from 'components/organisms/PageContainer/PageContainer'
import { toInteger } from 'lodash'
import { DateTime } from 'luxon'
import { useContext, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useGetPostGroupsQuery } from 'store/apis/posts/groupPostsApi'
import { TPostFilter } from 'store/apis/posts/types'
import { useDeleteUserPostMutation, useGetUserPostsQuery } from 'store/apis/posts/userPostsApi'
import { TPost } from 'store/types/entities'

const DEFAULT_FORM_VALUES: TPostFilter = {
  title: '',
  postGroupId: 0,
  published: 'all',
  draft: false,
}

const ManagePosts = () => {
  const { getValues, control, handleSubmit } = useForm<TPostFilter>({
    defaultValues: DEFAULT_FORM_VALUES,
    shouldUnregister: true,
  })

  const [page, setPage] = useState<number>(1)

  const { data: postGroups, isFetching: isPostGroupsFetching } = useGetPostGroupsQuery()

  const {
    refetch: refetchUserPosts,
    data,
    isFetching: isPostFetching,
  } = useGetUserPostsQuery({ ...getValues(), page })

  const [deletePost] = useDeleteUserPostMutation()

  const posts =
    useMemo(() => {
      if (data && postGroups) {
        return data.posts.map((post) => {
          return {
            ...post,
            postGroupName: postGroups.find((pg) => pg.id === post.postGroupId)?.name,
          }
        })
      }
    }, [data, postGroups]) || []

  const isFetching = isPostFetching && isPostGroupsFetching

  const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const onSubmitHandler = () => {
    setPage(1)
    refetchUserPosts()
  }

  const { openPostModal } = useContext(PostModalContext)

  const onDeleteClickHandler = (post: TPost) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      deletePost(post.id)
    }
  }

  const onEditIconClick = async (post: TPost) => {
    openPostModal(post)
  }

  const onDuplicateIconClick = async (post: TPost) => {
    openPostModal(post, true)
  }

  return (
    <PageContainer>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        style={{ marginBottom: '30px', display: 'flex', flexDirection: 'row', gap: '10px' }}
      >
        <Controller
          name='title'
          control={control}
          render={({ field }) => (
            <TextField {...field} sx={{ flex: 1 }} placeholder='Filter by title' />
          )}
        />

        <Controller
          name='postGroupId'
          control={control}
          render={({ field }) => (
            <Select {...field} displayEmpty>
              <MenuItem key='all' value={0}>
                All
              </MenuItem>
              {postGroups?.map((postGroup) => (
                <MenuItem key={postGroup.id} value={postGroup.id}>
                  {postGroup.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <Controller
          name='published'
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              {...field}
              size='small'
              color='primary'
              exclusive
              aria-label='Platform'
            >
              <ToggleButton value='all'>All</ToggleButton>
              <ToggleButton value='published'>Published</ToggleButton>
              <ToggleButton value='unpublished'>Unpublished</ToggleButton>
            </ToggleButtonGroup>
          )}
        />

        <FormControlLabel
          control={
            <Controller
              name='draft'
              control={control}
              render={({ field: props }) => <Checkbox {...props} />}
            />
          }
          label='Only draft'
        />

        <Button type='submit' variant='contained' sx={{ marginLeft: '10px' }}>
          Filter
        </Button>
      </form>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align='center'>Drawer</StyledTableCell>
              <StyledTableCell align='center'>Draft</StyledTableCell>
              <StyledTableCell align='center'>Published</StyledTableCell>
              <StyledTableCell align='left'>Published At</StyledTableCell>
              <StyledTableCell align='right'>Actions</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRowsLoader rowsNum={10} />
            ) : posts.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Text textAlign='center'>No records found</Text>
                </TableCell>
              </TableRow>
            ) : (
              posts.map((post) => (
                <StyledTableRow
                  key={post.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component='th' scope='row'>
                    <Link to={`/posts/${post.key}`} style={{ color: 'black' }}>
                      {post.title}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell align='center'>{post.postGroupName}</StyledTableCell>
                  <StyledTableCell align='center'>{post.draft ? 'True' : 'False'}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {DateTime.now() >= post.publishedAt ? 'True' : 'False'}
                  </StyledTableCell>
                  <StyledTableCell align='left'>
                    {post.publishedAt?.toLocaleString(DateTime.DATETIME_SHORT)}
                  </StyledTableCell>
                  <StyledTableCell align='right' width='150px'>
                    <ContentCopyIcon
                      onClick={() => onDuplicateIconClick(post)}
                      sx={{
                        cursor: 'pointer',
                        width: '20px',
                        top: '1px',
                        position: 'relative',
                        marginRight: '6px',
                      }}
                    />
                    <EditIcon
                      onClick={() => onEditIconClick(post)}
                      sx={{
                        cursor: 'pointer',
                        width: '20px',
                        top: '1px',
                        position: 'relative',
                        marginRight: '4px',
                      }}
                    />
                    <DeleteIcon
                      fontSize='small'
                      onClick={() => onDeleteClickHandler(post)}
                      sx={{ cursor: 'pointer', marginRight: '10px' }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display='flex' justifyContent='end' margin='20px 0'>
        <Pagination
          count={data ? toInteger(data.totalPages) : 0}
          page={page}
          onChange={onPageChange}
        />
      </Box>
    </PageContainer>
  )
}

export default ManagePosts
