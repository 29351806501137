import styled from '@emotion/styled'
import { Box } from '@mui/material'

export const PostBox = styled(Box)(() => ({
  width: '100%',
  margin: '0 auto',
  background: 'white',
  WebkitBoxShadow: '0px 0px 10px 1px rgba(156,156,156,1)',
  MozBoxShadow: '0px 0px 10px 1px rgba(156,156,156,1)',
  boxShadow: '0px 0px 10px 1px rgba(156,156,156,1)',
  padding: '60px 100px',
  maxWidth: '1100px',

  img: {
    width: '100%',
  },

  '@media only screen and (max-width: 900px)': {
    padding: '30px 30px',

    img: {
      padding: '20px 0',
    },
  },
}))
