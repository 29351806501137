import { yupResolver } from '@hookform/resolvers/yup'
import { Link as MUILink } from '@mui/material'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import TextFieldWithError from 'components/molecules/TextFieldWithError/TextFieldWithError.component'
import { AuthForm, AuthFormInputsContainer } from 'features/components/LoginForm/LoginForm.styled'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

type TResetPasswordForm = {
  email: string
}

export type TResetPasswordProps = {
  onSubmitHandler: (form: TResetPasswordForm) => void
  isSubmiting: boolean
}

const schema = yup
  .object({
    email: yup.string().label('Email').required().email(),
  })
  .required()

const ResetPasswordFormPure = ({ onSubmitHandler, isSubmiting }: TResetPasswordProps) => {
  const { control, handleSubmit } = useForm<TResetPasswordForm>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  })

  return (
    <AuthForm onSubmit={handleSubmit(onSubmitHandler)}>
      <AuthFormInputsContainer gap='8px !important'>
        <TextFieldWithError
          name='email'
          control={control}
          placeholder='Email'
          sx={{ width: '100%' }}
        />
        <RoundButton type='submit' variant='contained' color='primary' disabled={isSubmiting}>
          Recover Password
        </RoundButton>
        <MUILink variant='body1' underline='none' component={Link} to='/subscribe'>
          Back to login
        </MUILink>
      </AuthFormInputsContainer>
    </AuthForm>
  )
}

export default ResetPasswordFormPure
