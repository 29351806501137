import { BoxProps } from '@mui/material'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { ReactNode } from 'react'

type PageContainerProps = {
  children: ReactNode
} & BoxProps

export const PageContainer = ({ children, ...boxProps }: PageContainerProps) => {
  const isPhone = window.innerWidth <= 500

  return (
    <FlexBoxFullHeight paddingX={isPhone ? '1em' : '3em'} mt={4} flex={1}>
      <FlexBoxFullHeight mt='30px' {...boxProps}>
        {children}
      </FlexBoxFullHeight>
    </FlexBoxFullHeight>
  )
}
