import { Box, Divider, Link as MUILink, styled } from '@mui/material'
import { Text } from 'components/atoms/Text/Text'
import LandingPageContainer from 'components/organisms/LandingPageContainer/LandingPageContainer.component'
import SignInWithGoogle from 'features/components/SignInWithGoogle/SignInWithGoogle.component'
import SubscribeForm from 'features/components/SubscribeForm/SubscribeForm.component'
import { Link } from 'react-router-dom'

const LandingTextContainer = styled(Text)(() => ({
  p: {
    fontSize: '24px',
    padding: '5px 0',
  },
}))

const Subscribe = () => {
  return (
    <LandingPageContainer>
      <Text variant='h1' className='title' fontWeight='500'>
        Break into The Vault
      </Text>
      <LandingTextContainer component='div'>
        <p>
          Your <b>free access</b> is ready... simply input your email address below to get inside --
          you&apos;ll also receive our Daily Drop emails each business day.
        </p>
      </LandingTextContainer>

      <SubscribeForm registrationLabel='Let Me In' />

      <Text textAlign='center'>
        Already have an account?
        <MUILink
          underline='none'
          component={Link}
          textAlign='right'
          to='/sign_in'
          sx={{ margin: '0px 8px 0px 6px' }}
        >
          Log in
        </MUILink>
      </Text>
      <Divider>
        <Text>Or</Text>
      </Divider>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <SignInWithGoogle />
      </Box>
    </LandingPageContainer>
  )
}

export default Subscribe
