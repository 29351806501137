import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { toLower } from 'lodash'
import queryString from 'query-string'
import { TPost } from 'store/types/entities'
import { API_HOST, API_PATH } from 'store/utils'
import autheHeaders from '../utils/authHeaders'
import transformPostResponse from './transformPostResponse'
import { TGetPostParams, TUserPostResponse } from './types'

const postsApi = createApi({
  reducerPath: 'postsApi',
  tagTypes: ['Posts'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: autheHeaders,
  }),
  endpoints: (builder) => ({
    getPosts: builder.query<TUserPostResponse, TGetPostParams>({
      // providesTags: (result, error, arg) => [
      //   { type: 'Posts', timeFrame: arg.timeframe, pinned: arg.pinned },
      // ],
      // providesTags: ['Posts'],
      providesTags: (result, error, arg) => [
        { type: 'Posts', timeFrame: arg.timeframe, pinned: arg.pinned },
      ],
      query: ({ tags, ...rest }) => {
        const params = queryString.stringify(
          { ...rest, ...(tags && { tags: tags.map(toLower) }) },
          { arrayFormat: 'bracket', skipNull: true, skipEmptyString: true },
        )

        return {
          url: `posts?${params}`,
        }
      },
      transformResponse: (response: any, meta: any) => {
        const headers = meta.response.headers

        return {
          posts: response.map(transformPostResponse) as TPost[],
          currentPage: headers.get('X-Currentpage'),
          totalPages: headers.get('X-Totalpages'),
        }
      },
    }),

    getPost: builder.query<TPost, string>({
      query: (key) => `posts/${key}`,
      transformResponse: transformPostResponse,
      providesTags: ['Posts'],
    }),

    getPublicPost: builder.query<TPost, string>({
      query: (id) => `public/posts/${id}`,
      transformResponse: transformPostResponse,
    }),
  }),
})

export const { useGetPostQuery, useGetPostsQuery, useGetPublicPostQuery, useLazyGetPostsQuery } =
  postsApi
export default postsApi
