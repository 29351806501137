import {
  EChartMetric,
  EChartType,
  EDatasetResolution,
  EDatasetScope,
  EDatasetSource,
  TCompany,
} from 'components/organisms/Charts/utils/types'
import { round, sortBy } from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
  useLazyFetchCompanyMetricQuery,
  useLazyFetchCompanyPriceQuery,
} from 'store/apis/metricsApi'
import {
  calculateDatasetDiff,
  calculateMetricSum,
} from '../../components/organisms/Charts/utils/chartsCalc'

export type TChartParameters = {
  chartType: EChartType
  companies: TCompany[]
  company: TCompany | null
  metric: EChartMetric
  scope: EDatasetScope
  source: EDatasetSource
  resolution: EDatasetResolution
  comparisonPeriodInDays: number
  movingAverageInDays: number
  startDate: DateTime
  referenceDate: DateTime
}
export class NoDataError extends Error {
  constructor(message = '', ...args: any[]) {
    super(message, ...args)
    this.message = message
  }
}

export const useChart = (chartId?: string) => {
  const [fetchCompanyMetric] = useLazyFetchCompanyMetricQuery()
  const [fetchCompanyPrice] = useLazyFetchCompanyPriceQuery()
  const [error, setError] = useState<string | undefined>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchMovingAverageMetric = async (
    company: TCompany,
    metric: EChartMetric,
    normalized: boolean,
    scope: EDatasetScope,
    source: EDatasetSource,
    resolution: EDatasetResolution,
    startDate: DateTime,
    movingAverageInDays: number,
    referenceDate: DateTime,
  ) => {
    const dataset = await fetchCompanyMetric({
      chartId,
      companyId: company.id,
      metric,
      normalized,
      source,
      scope,
      resolution,
      startDate,
      movingAverageInDays,
    }).unwrap()
    const lastEntry = dataset[dataset.length - 1]
    const lastEntryDate = DateTime.fromISO(lastEntry.date).startOf('day')
    const startOfDayReferenceDate =
      lastEntryDate < referenceDate.startOf('day') ? lastEntryDate : referenceDate.startOf('day')

    const retDataset = dataset.filter(
      (entry) => DateTime.fromISO(entry.date).startOf('day') <= startOfDayReferenceDate,
    )

    if (retDataset.length === 0) {
      throw new NoDataError(
        `Data does not go back far enough for this reference date for ${company.ticker}`,
      )
    }

    return retDataset
  }

  const fetchAverageMetricDiff = async (
    companies: TCompany[],
    metric: EChartMetric,
    scope: EDatasetScope,
    source: EDatasetSource,
    resolution: EDatasetResolution,
    startDate: DateTime,
    comparisonPeriodInDays: number,
    movingAverageInDays: number,
    referenceDate: DateTime,
  ) => {
    return await Promise.all(
      companies.map(async (company) => {
        const dataset = await fetchMovingAverageMetric(
          company,
          metric,
          true,
          scope,
          source,
          resolution,
          startDate,
          movingAverageInDays,
          referenceDate,
        )

        const diff = calculateDatasetDiff(
          dataset,
          referenceDate,
          comparisonPeriodInDays,
          metric === EChartMetric.Happiness,
        )

        const point = {
          category: company.ticker,
          value: diff,
        }

        return point
      }),
    )
  }

  const fetchNowMetric = async (
    companies: TCompany[],
    metric: EChartMetric,
    scope: EDatasetScope,
    source: EDatasetSource,
    resolution: EDatasetResolution,
    startDate: DateTime,
    comparisonPeriodInDays: number,
    movingAverageInDays: number,
    referenceDate: DateTime,
  ) => {
    return await Promise.all(
      companies.map(async (company) => {
        const dataset = await fetchMovingAverageMetric(
          company,
          metric,
          true,
          scope,
          source,
          resolution,
          startDate,
          movingAverageInDays,
          referenceDate,
        )

        const entry = dataset.find((entry) =>
          DateTime.fromISO(entry.date).startOf('day').equals(referenceDate.startOf('day')),
        )

        return {
          category: company.ticker,
          value: entry?.value,
        }
      }),
    )
  }

  const fetchPlotMetric = async (
    companies: TCompany[],
    metric: EChartMetric,
    scope: EDatasetScope,
    source: EDatasetSource,
    resolution: EDatasetResolution,
    startDate: DateTime,
    comparisonPeriodInDays: number,
    movingAverageInDays: number,
    referenceDate: DateTime,
  ) => {
    return await Promise.all(
      companies.map(async (company) => {
        const happinessResponse = await fetchMovingAverageMetric(
          company,
          EChartMetric.Happiness,
          true,
          scope,
          source,
          resolution,
          startDate,
          movingAverageInDays,
          referenceDate,
        )

        const metricResponse = await fetchMovingAverageMetric(
          company,
          metric,
          true,
          scope,
          source,
          resolution,
          startDate,
          movingAverageInDays,
          referenceDate,
        )

        const metricValueResponse = await fetchMovingAverageMetric(
          company,
          metric,
          false,
          scope,
          source,
          resolution,
          startDate,
          movingAverageInDays,
          referenceDate,
        )

        let xValue, value

        xValue = calculateDatasetDiff(metricResponse, referenceDate, comparisonPeriodInDays, false)

        value = calculateMetricSum(metricValueResponse, referenceDate, comparisonPeriodInDays)
        value = Math.round(Math.pow(value, 0.5))

        const yValue = happinessResponse.find((entry) =>
          DateTime.fromISO(entry.date).startOf('day').equals(referenceDate.startOf('day')),
        )

        const bubblePoint = {
          name: company.ticker,
          x: round(xValue, 2),
          y: round(yValue?.value ?? 0, 2),
          value: value,
        }

        return bubblePoint
      }),
    )
  }

  const fetchPieMetric = async (
    companies: TCompany[],
    company: TCompany | null,
    metric: EChartMetric,
    scope: EDatasetScope,
    source: EDatasetSource,
    resolution: EDatasetResolution,
    startDate: DateTime,
    comparisonPeriodInDays: number,
    movingAverageInDays: number,
    referenceDate: DateTime,
  ) => {
    if (metric === EChartMetric.Happiness) {
      const dataset = await fetchMovingAverageMetric(
        company!,
        metric,
        false,
        scope,
        source,
        resolution,
        startDate,
        movingAverageInDays,
        referenceDate,
      )

      const entry = dataset.find((entry, index) =>
        DateTime.fromISO(entry.date).startOf('day').equals(referenceDate.startOf('day')),
      )

      return [
        {
          name: 'Happiness',
          value: entry?.value,
        },
        {
          name: 'Unhappiness',
          value: 100 - (entry?.value || 0),
        },
      ]
    } else {
      const metricResult = await Promise.all(
        companies.map(async (company) => {
          let metricResponse = await fetchCompanyMetric({
            chartId,
            companyId: company.id,
            metric,
            normalized: false,
            source,
            scope,
            resolution,
            startDate,
            movingAverageInDays,
          }).unwrap()

          if (metricResponse.length === 0) {
            throw new NoDataError(
              `Data does not go back far enough for this reference date for ${company.ticker}`,
            )
          }

          metricResponse = metricResponse.map((item: any) => ({
            ...item,
            value: Math.round(item.value),
          }))

          const value = calculateMetricSum(metricResponse, referenceDate, movingAverageInDays)

          return {
            name: company.ticker,
            metric: value,
          }
        }),
      )

      const total = metricResult.reduce((sum, entry) => sum + entry.metric, 0)

      return metricResult.map((entry) => ({
        ...entry,
        value: Number((entry.metric * 100) / total),
      }))
    }
  }

  const fetchLineMetric = async (
    companies: TCompany[],
    metric: EChartMetric,
    scope: EDatasetScope,
    source: EDatasetSource,
    resolution: EDatasetResolution,
    startDate: DateTime,
    comparisonPeriodInDays: number,
    movingAverageInDays: number,
    referenceDate: DateTime,
  ) => {
    return await Promise.all(
      companies.map(async (company) => {
        const dataset = await fetchMovingAverageMetric(
          company,
          metric,
          true,
          scope,
          source,
          resolution,
          startDate,
          movingAverageInDays,
          referenceDate,
        )

        const priceDataset = await fetchCompanyPrice({
          chartId,
          companyId: company.id,
          resolution,
          startDate,
          movingAverageInDays,
        }).unwrap()

        const comparisonDate = referenceDate.minus({ days: comparisonPeriodInDays })

        const parsedData = dataset
          .filter((entry) => {
            const date = DateTime.fromISO(entry.date)

            return date <= referenceDate && date >= comparisonDate
          })
          .map((data) => ({
            ...data,
            date: new Date(data.date).getTime(),
            value: data.value,
          }))

        const filteredPriceDataset = priceDataset.filter((entry) => {
          const date = DateTime.fromISO(entry.date)

          return date <= referenceDate && date >= comparisonDate
        })

        const firstValue = filteredPriceDataset[0]?.value

        const parsedPrice =
          companies.length > 1
            ? filteredPriceDataset.map((price, index) => {
                let value = 0

                if (index !== 0) {
                  value = (filteredPriceDataset[index].value * 100) / firstValue - 100
                }

                return {
                  ...price,
                  value: round(value, 2),
                  date: new Date(price.date).getTime(),
                }
              })
            : filteredPriceDataset.map((price) => ({
                ...price,
                date: new Date(price.date).getTime(),
                value: round(price.value as number, 2),
              }))

        return {
          id: company.id,
          name: company.ticker,
          data: parsedData,
          price: parsedPrice,
        }
      }),
    )
  }

  const getChartData = async (data: TChartParameters) => {
    setError(undefined)
    setIsLoading(true)

    try {
      if (data.chartType === EChartType.BarChange) {
        const result = await fetchAverageMetricDiff(
          data.companies,
          data.metric,
          data.scope,
          data.source,
          data.resolution,
          data.startDate,
          data.comparisonPeriodInDays,
          data.movingAverageInDays,
          data.referenceDate,
        )

        return sortBy(result, 'value')
      } else if (data.chartType === EChartType.BarNow) {
        const result = await fetchNowMetric(
          data.companies,
          data.metric,
          data.scope,
          data.source,
          data.resolution,
          data.startDate,
          data.comparisonPeriodInDays,
          data.movingAverageInDays,
          data.referenceDate,
        )

        return sortBy(result, 'value')
      } else if (data.chartType === EChartType.Scatter) {
        return await fetchPlotMetric(
          data.companies,
          data.metric,
          data.scope,
          data.source,
          data.resolution,
          data.startDate,
          data.comparisonPeriodInDays,
          data.movingAverageInDays,
          data.referenceDate,
        )
      } else if (data.chartType === EChartType.Pie) {
        return await fetchPieMetric(
          data.companies,
          data.company,
          data.metric,
          data.scope,
          data.source,
          data.resolution,
          data.startDate,
          data.comparisonPeriodInDays,
          data.movingAverageInDays,
          data.referenceDate,
        )
      } else if (data.chartType === EChartType.Line) {
        return await fetchLineMetric(
          data.companies,
          data.metric,
          data.scope,
          data.source,
          data.resolution,
          data.startDate,
          data.comparisonPeriodInDays,
          data.movingAverageInDays,
          data.referenceDate,
        )
      }

      return []
    } catch (error: any) {
      if (error instanceof NoDataError) {
        setError(error.message)
      } else {
        throw error
      }

      return []
    } finally {
      setIsLoading(false)
    }
  }

  return { getChartData, error, isLoading }
}
