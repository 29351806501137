import { DateTime } from 'luxon'
import { createSelector } from 'reselect'
import { RootState } from 'store/store'

const selectAuth = (state: RootState) => state.auth

export const selectIsLoggedIn = createSelector([selectAuth], (authReducer): boolean =>
  Boolean(authReducer.authToken),
)

export const selectPreviousUrl = createSelector(
  [selectAuth],
  (authReducer): string | null | undefined => authReducer.previousUrl,
)

export const selectRefreshToken = createSelector(
  [selectAuth],
  (authReducer): string | null | undefined => authReducer.refreshToken,
)

export const selectAuthToken = createSelector([selectAuth], (authReducer) => ({
  authToken: authReducer.authToken,
  authTokenExpires: authReducer.authTokenExpires
    ? DateTime.fromSeconds(authReducer.authTokenExpires)
    : null,
}))

const selectStripe = (state: RootState) => state.stripe

export const selectStripeSessionId = createSelector(
  [selectStripe],
  (stripeReducer): string | null => stripeReducer.sessionId,
)
