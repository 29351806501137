import { GlobalMessageContext, PostModalContext } from 'App'
import useSubscription from 'features/hooks/useSubscription'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from 'store/slices/authSlice'
import { useAppDispatch } from 'store/store'
import SideBarMenuPure from './SideBarMenuPure.component'

type SideBarMenuProps = {
  anchorEl: Element | ((element: Element) => Element) | null | undefined
  onCloseMenuHandler: () => void
}

const SideBarMenu = ({ anchorEl, onCloseMenuHandler: onCloseHandler }: SideBarMenuProps) => {
  const { isAdminUser, isFreeUser } = useSubscription()
  const { setGlobalMessage } = useContext(GlobalMessageContext)
  const { openPostModal } = useContext(PostModalContext)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onNewPostHandler = () => {
    onCloseHandler()
    openPostModal()
  }

  const onLogoutHandler = () => {
    onCloseHandler()
    dispatch(logout())
    setGlobalMessage({
      type: 'success',
      overridable: false,
      message: 'Logged out successfully',
    })

    navigate('/subscribe')
  }

  return (
    <SideBarMenuPure
      anchorEl={anchorEl}
      isAdminUser={isAdminUser}
      isFreeUser={isFreeUser}
      closeMenuHandler={onCloseHandler}
      onLogoutHandler={onLogoutHandler}
      onNewPostHandler={onNewPostHandler}
    />
  )
}

export default SideBarMenu
