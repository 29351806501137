import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_HOST, API_PATH } from 'store/utils'

type ResetPasswordRequest = {
  token: string
  password: string
  passwordConfirmation: string
}

type RecoverPasswordRequest = {
  email: string
}

const userPasswordApi = createApi({
  reducerPath: 'userPasswordApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set(
        'Authorization',
        `Basic ${btoa(
          `${process.env.REACT_APP_AUTH_CLIENT_ID}:${process.env.REACT_APP_AUTH_CLIENT_SECRET}`,
        )}`,
      )
      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    recoverPassword: builder.mutation<void, RecoverPasswordRequest>({
      query: ({ email }) => ({
        url: 'user/new-password',
        method: 'POST',
        body: {
          email,
        },
      }),
      transformErrorResponse: (response) => {
        if (response.status === 400) {
          return { message: 'Invalid or expired token' }
        }

        return response
      },
    }),
    resetPasword: builder.mutation<void, ResetPasswordRequest>({
      query: ({ token, password, passwordConfirmation }) => ({
        url: 'user/reset-password',
        method: 'POST',
        body: {
          reset_password_token: token,
          new_password: password,
          new_password_confirmation: passwordConfirmation,
        },
      }),
      transformErrorResponse: (response) => {
        if (response.status === 400) {
          return { message: 'Invalid or expired token' }
        }

        return response
      },
    }),
  }),
})

export const { useResetPaswordMutation, useRecoverPasswordMutation } = userPasswordApi
export default userPasswordApi
