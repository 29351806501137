//authInterceptor.ts
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { logout } from 'store/slices/authSlice'

const authInterceptor: Middleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 402) {
      window.location.replace('no_access')
    } else if (isRejectedWithValue(action) && action.payload.status === 401) {
      const token = getState().auth.authToken

      dispatch(logout())

      const apiUrl = action.meta.baseQueryMeta.request.url

      const message = `Token: ${token} - status: 401 - ${apiUrl} - Payload: ${JSON.stringify(
        action.meta.arg.originalArgs,
      )}`

      fetch(`${process.env.REACT_APP_HOST}/api/log`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      })
    }

    return next(action)
  }

export default authInterceptor
