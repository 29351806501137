import PostsList from 'components/organisms/PostsList/PostsList.component'
import { useMatch } from 'react-router-dom'
import { useFetchAccountQuery } from 'store/apis/userApi'

export type TPostsList = {
  name: string
  color?: string
  collapsed?: boolean
  fetchData: (page: number, perPage: number) => Promise<any[]>
  onSideMenuCloseClickHandler: () => void
}

const PostList = ({
  name,
  color,
  fetchData,
  collapsed = false,
  onSideMenuCloseClickHandler,
}: TPostsList) => {
  const isPost = useMatch('/posts/:key')

  const { data: account, isFetching } = useFetchAccountQuery()

  return (
    <PostsList
      name={name}
      color={color}
      lastSignInAt={account?.lastSignInAt}
      postKey={isPost?.params.key}
      startsCollapsed={collapsed}
      fetchData={fetchData}
      onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
    />
  )
}
export default PostList
