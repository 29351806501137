import { camelCase, mapKeys } from 'lodash'
import { DateTime } from 'luxon'
import { TPost } from 'store/types/entities'

const transformPostResponse = (entry: any) => {
  const parsedEntry = mapKeys(entry, (_v, k) => camelCase(k))

  if (entry.published_at) {
    parsedEntry.publishedAt = DateTime.fromSeconds(entry.published_at)
  }

  if (entry.created_at) {
    parsedEntry.createdAt = DateTime.fromISO(entry.created_at)
  }

  parsedEntry.isCore = entry.access_flag_required === 'chart_explorer_core'
  parsedEntry.isPro = entry.access_flag_required === 'chart_explorer_pro'

  return parsedEntry as TPost
}

export default transformPostResponse
