import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import * as React from 'react'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '1200px',
    width: '75vw',
    borderRadius: '10px',
  },

  '& .MuiDialogContent-root': {
    border: 'none',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface DialogTitleProps {
  id: string
  onClose: () => void
}

function CustomDialogTitle(props: DialogTitleProps) {
  const { onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 4, textAlign: 'center' }} {...other}>
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

type LikefolioDialogProps = {
  title?: string
  isOpen: boolean
  children: React.ReactNode
  closeOutside?: boolean
  onClose: () => void
}

export default function LikefolioDialog({
  isOpen,
  onClose: handleClose,
  closeOutside = true,
  children,
}: LikefolioDialogProps) {
  const onDialogClose = (event = {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if ((closeOutside && reason === 'backdropClick') || reason === 'escapeKeyDown') {
      handleClose()
    }
  }

  return (
    <StyledDialog onClose={onDialogClose} aria-labelledby='customized-dialog-title' open={isOpen}>
      <CustomDialogTitle id='customized-dialog-title' onClose={handleClose} />
      {children}
    </StyledDialog>
  )
}
