import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'

const imageApi = createApi({
  reducerPath: 'imageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      // headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    postImage: builder.mutation<any, string>({
      query: (data) => {
        const bodyFormData = new FormData()
        bodyFormData.append('image', data)

        return {
          method: 'POST',
          url: 'user/posts/images',
          formData: true,
          body: bodyFormData,
        }
      },
    }),
  }),
})

export const { usePostImageMutation } = imageApi

export default imageApi
