import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { Text } from 'components/atoms/Text/Text'
import ResetPasswordFormPure from 'components/organisms/ResetPasswordForm/ResetPasswordFormPure.componet'
import { useState } from 'react'
import { useRecoverPasswordMutation } from 'store/apis/userPasswordApi'
import { theme } from 'utils/theme/index'

type TResetPasswordForm = {
  email: string
}

const ResetPasswordForm = () => {
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const [recoverPassword, { isLoading: isRecoverPasswordLoading }] = useRecoverPasswordMutation()

  const onSubmitHandler = async (values: TResetPasswordForm) => {
    await recoverPassword(values).unwrap()

    setEmailSent(true)
  }

  return emailSent ? (
    <FlexBoxFullHeight justifyContent='center' alignItems='center'>
      <CheckCircleOutlineIcon
        sx={{ color: theme.palette.primary.main, fontSize: '100px', marginBottom: '30px' }}
      />
      <Text variant='h3'>Check your email to continue the password recover process</Text>
    </FlexBoxFullHeight>
  ) : (
    <ResetPasswordFormPure
      isSubmiting={isRecoverPasswordLoading}
      onSubmitHandler={onSubmitHandler}
    />
  )
}

export default ResetPasswordForm
