import { GlobalMessageContext } from 'App'
import { Text } from 'components/atoms/Text/Text'
import RecoverPasswordFormPure, {
  TRecoverForm,
} from 'components/organisms/RecoverPasswordForm/RecoverPasswordFormPure.component'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetPaswordMutation } from 'store/apis/userPasswordApi'
import { isApiError } from 'store/utils'
import { theme } from 'utils/theme/index'

type TRecoverPasswordForm = {
  token: string
}

const RecoverPasswordForm = ({ token }: TRecoverPasswordForm) => {
  const navigate = useNavigate()
  const { setGlobalMessage } = useContext(GlobalMessageContext)
  const [resetPassword, { error, isLoading }] = useResetPaswordMutation()

  const onSubmitHandler = async (form: TRecoverForm) => {
    await resetPassword({ ...form, token }).unwrap()

    setGlobalMessage({
      type: 'success',
      message: 'Password updated successfully',
    })

    navigate('/sign_in', { replace: true })
  }

  return (
    <>
      {error && isApiError(error) && (
        <Text color={theme.palette.error.contrastText} textAlign='center'>
          {error.message}
        </Text>
      )}

      <RecoverPasswordFormPure isSubmiting={isLoading} onSubmitHandler={onSubmitHandler} />
    </>
  )
}

export default RecoverPasswordForm
