import { useCallback } from 'react'
import { useLazyGetPostGroupPostQuery } from 'store/apis/posts/groupPostsApi'
import { TPostGroup } from 'store/types/entities'
import PostList from './PostList.component'

const PostGroupListComponent = ({
  postGroup,
  filter,
  tags,
  onSideMenuCloseClickHandler,
}: {
  postGroup: TPostGroup
  filter: string
  tags: Array<string>
  onSideMenuCloseClickHandler: () => void
}) => {
  const [getPostGroupPosts] = useLazyGetPostGroupPostQuery()

  const fetchPostGroupPosts = useCallback(
    async (page: number, perPage: number) => {
      return (
        await getPostGroupPosts({ id: postGroup.id, page, title: filter, tags: tags }).unwrap()
      ).posts
    },
    [postGroup],
  )

  return (
    <PostList
      fetchData={fetchPostGroupPosts}
      name={postGroup.name}
      collapsed={postGroup.collapsed}
      onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
    />
  )
}

export default PostGroupListComponent
