import { RootState } from 'store/store'

const autheHeaders = (headers: Headers, { getState }: { getState: () => unknown }) => {
  const token = (getState() as RootState).auth.authToken

  if (token) {
    headers.set('authorization', `Bearer ${token}`)
  }

  headers.set('Content-Type', 'application/json')

  return headers
}

export default autheHeaders
