import { Box, BoxProps } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import { GlobalMessageContext } from 'App'
import useAuth from 'features/hooks/useAuth'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePostGoogleRegistrationMutation } from 'store/apis/authApi'
import { selectPreviousUrl } from 'store/apis/selectors'
import { useAppSelector } from 'store/store'

const SignInWithGoogle = (boxProps: BoxProps) => {
  const navigate = useNavigate()

  const { loginAuthToken } = useAuth()

  const [postGoogleRegistration] = usePostGoogleRegistrationMutation()

  const { setGlobalMessage } = useContext(GlobalMessageContext)

  const previousUrl = useAppSelector(selectPreviousUrl)

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await postGoogleRegistration(codeResponse.access_token).unwrap()

      loginAuthToken(response.access_token, response.refresh_token, response.expires_in)

      setGlobalMessage({
        type: 'success',
        message: 'Login Success',
      })

      if (previousUrl) {
        navigate(previousUrl)
      } else {
        navigate('/')
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  })

  return (
    <Box
      component='img'
      src='/assets/imgs/signin-button.png'
      width='250px'
      {...boxProps}
      sx={{ ...boxProps.sx, cursor: 'pointer', position: 'relative' }}
      onClick={() => googleLogin()}
    />
  )
}

export default SignInWithGoogle
