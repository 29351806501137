import { GlobalMessageContext } from 'App'
import PrivateRoute from 'features/components/Auth/PrivateRoute.component'
import { Main } from 'features/components/Main/Main.component'
import useAuth from 'features/hooks/useAuth'
import NoAccess from 'pages/no_access/no_access.page'
import PublicPost from 'pages/posts/PublicPost.page'
import RecoverPassword from 'pages/recover_password/RecoverPassword.page'
import ResetPassword from 'pages/reset_password/ResetPassword.page'
import SignIn from 'pages/sign_in/SignIn.page'
import Subscribe from 'pages/subscribe/Subscribe.page'
import Checkout from 'pages/subscriptions/Checkout.page'
import CheckoutSuccess from 'pages/subscriptions/CheckoutSuccess.page'
import { useContext, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { Route, Routes, useLocation } from 'react-router-dom'

export const MainRouter = () => {
  const location = useLocation()

  const { globalMessage, globalMessageDisplayed, setGlobalMessage, setGlobalMessageDisplayed } =
    useContext(GlobalMessageContext)

  const { isLoggedIn } = useAuth()

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname })

    if (globalMessageDisplayed) {
      setGlobalMessage(undefined)
    } else {
      if (globalMessage) {
        setGlobalMessageDisplayed(true)
      }
    }
  }, [location.pathname])

  return (
    <Routes>
      <Route path='/subscribe' element={<Subscribe />} />
      <Route path='/sign_in' element={<SignIn />} />
      {/* <Route path='/sign_up' element={<SignUp />} /> */}
      <Route path='/reset_password' element={<ResetPassword />} />
      <Route path='/recover_password' element={<RecoverPassword />} />
      {/* <Route path='/subscriptions' element={<Subscriptions />} /> */}
      <Route path='/checkout/:id' element={<Checkout />} />
      <Route path='/checkout_success' element={<CheckoutSuccess />} />
      <Route path='/no_access' element={<NoAccess />} />

      <Route element={<PrivateRoute />}>
        <Route path='*' element={<Main />} />
      </Route>

      {!isLoggedIn && <Route path='/posts/:key' element={<PublicPost />} />}
    </Routes>
  )
}
