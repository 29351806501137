import { Text } from 'components/atoms/Text/Text'
import LandingPageContainer from 'components/organisms/LandingPageContainer/LandingPageContainer.component'
import RecoverPasswordForm from 'features/components/RecoverPasswordForm/RecoverPasswordForm.component'
import { useSearchParams } from 'react-router-dom'

const RecoverPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return (
    <LandingPageContainer>
      {token ? (
        <RecoverPasswordForm token={token} />
      ) : (
        <Text>Error: Token must be present in the url</Text>
      )}
    </LandingPageContainer>
  )
}

export default RecoverPassword
