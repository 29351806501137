import { Box, FormControlLabel, Switch } from '@mui/material'
import { useMemo } from 'react'
import LineChart from '../LineChart'
import { SerieXYConfig, metricString } from '../utils/types'

const LineChartWrapper = ({
  metric,
  chartData,
  showPrice,
  setShowPrice,
}: {
  metric: string
  chartData: any
  showPrice: boolean
  setShowPrice: (show: boolean) => void
}) => {
  const seriesConfig = useMemo(() => {
    return chartData.flatMap((entry: any, index: number) => {
      const retVal = [
        {
          name: showPrice ? `${entry.name} ${metricString[metric]}` : entry.name,
          axis: 0,
          fieldY: 'value',
          data: entry.data,
          seriesType: 'line',
          colorIndex: index,
        } as SerieXYConfig,
      ]

      if (showPrice) {
        retVal.push({
          id: 'price',
          name: entry.name + ' Price',
          axis: 1,
          metricEnd: chartData.length > 1 ? '%' : undefined,
          metricStart: chartData.length === 1 ? '$' : undefined,
          fieldY: 'value',
          data: entry.price,
          seriesType: 'line',
          dotted: true,
          colorIndex: index,
        } as SerieXYConfig)
      }
      return retVal
    })
  }, [chartData, showPrice])

  return (
    <Box>
      <FormControlLabel
        sx={{ margin: '10px 15px' }}
        control={
          <Switch checked={showPrice} onChange={(event, checked) => setShowPrice(checked)} />
        }
        label='Show Stock Price'
      />
      <LineChart
        chartConfig={{
          mainAxisTitle: metricString[metric],
          secondatyAxisTitle: showPrice
            ? chartData.length > 1
              ? 'Stock Price Change'
              : 'Stock Price'
            : undefined,
          secondaryAxisSymbol: chartData.length > 1 ? '%' : '$',
        }}
        seriesConfig={seriesConfig}
      />
    </Box>
  )
}

export default LineChartWrapper
