import { Box } from '@mui/material'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useParams } from 'react-router-dom'
import { useGetCheckoutSessionQuery } from 'store/apis/stripeApi'

const stripePromise = loadStripe('pk_test_naRZVz3m1BfnhDqOJJOuzFGp')

const CheckoutFromWrapper = () => {
  const { id } = useParams()
  const { data: session } = useGetCheckoutSessionQuery(id || '')

  return (
    session && (
      <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '530px',
            height: '800px',
            backgroundColor: '#6ebf52',
            borderRadius: '16px',
          }}
        >
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: session.client_secret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      </Box>
    )
  )
}

export default CheckoutFromWrapper
