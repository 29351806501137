import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import ChartDiv from 'components/molecules/ChartDiv/ChartDiv'
import {
  addDataSeries,
  generateDateAxis,
  generateRootElement,
  generateValueAxis,
  generateXYChart,
} from 'components/organisms/Charts/utils/chartUtils'
import { ChartConfig, SerieXYConfig } from 'components/organisms/Charts/utils/types'
import { useEffect, useState } from 'react'

const LineChart = ({
  chartConfig,
  seriesConfig,
}: {
  chartConfig: ChartConfig
  seriesConfig: SerieXYConfig[]
}) => {
  // const [movAvgIndicator, setMovAvgIndicator] = useState<am5stock.Indicator>()
  const [chart, setChart] = useState<am5xy.XYChart>()
  const [root, setRoot] = useState<am5.Root>()
  const [dateAxis, setDateAxis] = useState<am5xy.GaplessDateAxis<am5xy.AxisRenderer>>()
  const [dataSeries, setDataSeries] = useState<{ [key: string]: am5xy.LineSeries }>({})
  const [legend, setLegend] = useState<am5.Legend>()

  const id = 'stockChart'
  const controlsId = 'stockChartControls'

  useEffect(() => {
    const generatedRoot = generateRootElement(id as string)
    const generatedChart = generateXYChart(generatedRoot)

    setChart(generatedChart)
    setRoot(generatedRoot)

    const dateAxis = generateDateAxis(generatedRoot, generatedChart)
    const valueAxis = generateValueAxis(generatedRoot, generatedChart, {
      opposite: false,
      title: chartConfig.mainAxisTitle,
    })
    generateValueAxis(generatedRoot, generatedChart, {
      opposite: true,
      title: chartConfig.secondatyAxisTitle,
      symbol: chartConfig.secondaryAxisSymbol,
    })

    valueAxis.setAll({
      max: 100,
    })

    setDateAxis(dateAxis)

    setDataSeries(addDataSeries(generatedRoot, generatedChart, 'line', dateAxis, seriesConfig))

    // // Create legend
    // // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    const chartLegend = generatedChart.children.push(
      am5.Legend.new(generatedRoot, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      }),
    )

    setLegend(chartLegend)

    chartLegend.data.setAll(generatedChart.series.values)

    const cursor = am5xy.XYCursor.new(generatedRoot, {
      behavior: 'none',
      xAxis: dateAxis,
      yAxis: valueAxis,
    })

    generatedChart.set('cursor', cursor)

    return () => {
      generatedRoot.dispose()
    }
  }, [seriesConfig])

  return <ChartDiv id={id} controlsId={controlsId} />
}

export default LineChart
