import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { ApiError } from './types/ApiError'

export const API_HOST = `${process.env.REACT_APP_API_HOST}`
export const API_PATH = 'chart-explorer'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isApiError(error: unknown): error is ApiError {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  )
}
