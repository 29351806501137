import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Divider, Link as MUILink } from '@mui/material'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { Text } from 'components/atoms/Text/Text'
import SignInFormPure from 'components/organisms/SignInForm/SignInForm.component'
import useAuth from 'features/hooks/useAuth'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLoginCodeMutation } from 'store/apis/authApi'
import { TSignInForm } from 'types/authForm'
import { theme } from 'utils/theme'
import SignInWithGoogle from '../SignInWithGoogle/SignInWithGoogle.component'

const SignInForm = () => {
  const { login, isLoading: isLoginLoading } = useAuth()

  const [signInError, setSignInError] = useState<string | undefined>(undefined)
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const [postSignInCode, { isLoading }] = useLoginCodeMutation()

  const onPasswordSubmitHandler = async (values: TSignInForm) => {
    try {
      setSignInError(undefined)

      await login({ email: values.email, password: values.password })
    } catch (error: any) {
      setSignInError(error.message)
    }
  }

  const onEmailSubmitHandler = async (values: TSignInForm) => {
    await postSignInCode(values.email)

    setEmailSent(true)
  }

  return emailSent ? (
    <FlexBoxFullHeight justifyContent='center' alignItems='center'>
      <CheckCircleOutlineIcon
        sx={{ color: theme.palette.primary.main, fontSize: '100px', marginBottom: '30px' }}
      />
      <Text variant='h3'>Check your email to continue the sign-in process</Text>
    </FlexBoxFullHeight>
  ) : (
    <>
      <SignInFormPure
        signInError={signInError}
        onEmailSubmitHandler={onEmailSubmitHandler}
        onPasswordSubmitHandler={onPasswordSubmitHandler}
        isSubmiting={isLoginLoading || isLoading}
      />
      <Text textAlign='center'>
        First Time Here?
        <MUILink
          variant='body1'
          underline='none'
          component={Link}
          textAlign='right'
          to='/subscribe'
          sx={{ margin: '0px 8px 0px 6px' }}
        >
          Create an account
        </MUILink>
      </Text>
      <Divider>
        <Text>Or</Text>
      </Divider>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <SignInWithGoogle />
      </Box>
    </>
  )
}

export default SignInForm
