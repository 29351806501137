import styled from '@emotion/styled'
import { TableCell, tableCellClasses } from '@mui/material'
import { theme } from 'utils/theme/index'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export default StyledTableCell
