import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import ChartDiv from 'components/molecules/ChartDiv/ChartDiv'
import { SerieXYConfig } from 'components/organisms/Charts/utils/types'
import { useEffect, useState } from 'react'

const PieChart = ({ seriesConfig }: { seriesConfig: SerieXYConfig[] }) => {
  const id = `pie${Math.random()}`

  // const [categoryAxis, setCategoryAxis] = useState<am5xy.CategoryAxis<am5xy.AxisRenderer>>()
  const [dataSeries, setDataSeries] = useState<am5percent.PieSeries | null>(null)

  useEffect(() => {
    const root = am5.Root.new(id)
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
        radius: am5.percent(70),
      }),
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'name',
        alignLabels: true,
      }),
    )

    setDataSeries(series)

    series.labels.template.setAll({
      textType: 'circular',
      text: "{name}: {value.formatNumber('#.0')}%",
      fontSize: 20,
      fontWeight: 'bold',
      centerX: 0,
      centerY: 0,
    })

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(seriesConfig[0].data as any[])

    // // Create legend
    // // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    // const legend = chart.children.push(
    //   am5.Legend.new(root, {
    //     centerX: am5.percent(50),
    //     x: am5.percent(50),
    //     marginTop: 15,
    //     marginBottom: 15,
    //   }),
    // )

    // legend.data.setAll(series.dataItems)

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100)
    return () => {
      root.dispose()
    }
  }, [])

  useEffect(() => {
    if (dataSeries) {
      dataSeries.data.setAll(seriesConfig[0].data as any[])
    }
  }, [seriesConfig])

  return <ChartDiv id={id} />
}

export default PieChart
