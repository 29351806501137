import { Box, CircularProgress } from '@mui/material'
import { GlobalMessageContext } from 'App'
import { Text } from 'components/atoms/Text/Text'
import { isUndefined } from 'lodash'
import { useContext, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectPreviousUrl, selectStripeSessionId } from 'store/apis/selectors'
import {
  useGetCheckoutSessionStatusQuery,
  useLazyGetCheckoutSessionCommitQuery,
} from 'store/apis/stripeApi'
import { useAppSelector } from 'store/store'

const CheckoutSuccess = () => {
  const navigate = useNavigate()

  const { setGlobalMessage } = useContext(GlobalMessageContext)

  const sessionId = useAppSelector(selectStripeSessionId)

  const sessionDataRef = useRef<any>()

  const previousUrl = useAppSelector(selectPreviousUrl)

  const { data: sessionData } = useGetCheckoutSessionStatusQuery(sessionId || '', {
    pollingInterval: 1000,
    skip: sessionDataRef.current && sessionDataRef.current.status === 'complete',
  })

  const [checkoutSessionCommit, { isFetching: isCheckoutBeingProcessed }] =
    useLazyGetCheckoutSessionCommitQuery()

  useEffect(() => {
    const commitSession = async () => {
      if (sessionData) {
        sessionDataRef.current = sessionData

        if (sessionData.status === 'complete') {
          const response = await checkoutSessionCommit(sessionId || '')

          if (response.status === 'fulfilled') {
            setGlobalMessage({
              type: 'success',
              message: 'Welcome, your payment has been processed successfully',
            })

            if (previousUrl) {
              navigate(previousUrl)
            } else {
              navigate('/')
            }
          }
        }
      }
    }

    commitSession()
  }, [sessionData])

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '30px',
      }}
    >
      {isCheckoutBeingProcessed ||
      isUndefined(sessionDataRef.current) ||
      sessionDataRef.current.status !== 'complete' ? (
        <>
          <Text variant='h3' textAlign='center'>
            Your payment is being process...
          </Text>
          <CircularProgress />
        </>
      ) : (
        <Text variant='h3' textAlign='center'>
          Congratulations, your payment has being processed
        </Text>
      )}
    </Box>
  )
}

export default CheckoutSuccess
