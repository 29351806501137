import { Box } from '@mui/material'
import { FlexBoxFullHeight } from 'components/atoms/FlexBoxFullHeight/FlexBoxFullHeight'
import { Text } from 'components/atoms/Text/Text'
import MainContainerWithTitle from 'components/organisms/MainContainerWithTitle/MainContainerWithTitle.component'
import SideBar from 'components/organisms/SideBar/SideBar.component'
import useSubscription from 'features/hooks/useSubscription'
import Charts from 'pages/charts/Charts'
import Drawers from 'pages/drawers/Drawers.page'
import ArchivePosts from 'pages/posts/ArchivePosts.page'
import ManagePosts from 'pages/posts/ManagePosts.page'
import PrivatePost from 'pages/posts/PrivatePost.page'
import Profile from 'pages/profile/Profile.page'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Route, Routes, useMatch } from 'react-router-dom'

const Empty = () => (
  <FlexBoxFullHeight justifyContent='center' alignItems='center' flexDirection='column'>
    <svg
      style={{
        width: '115px',
        height: '22px',
        position: isMobile ? 'initial' : 'absolute',
        marginBottom: isMobile ? '30px' : 0,
        right: '78%',
      }}
    >
      <path
        d='M 60 14 H 9.0044 L 15.7999 21.7006 L 13.5866 24 L 3 13 L 13.5866 2 L 15.7999 4.2994 L 9.0044 11.3577 H 207 V 14.6423 Z'
        fill='#6EBF52'
      />
    </svg>
    <Text variant='h3' textAlign='center' lineHeight='40px'>
      The Founder&apos;s Feed is ready for you.
      <br /> Click on a post to get started.
    </Text>
  </FlexBoxFullHeight>
)

export const Main = () => {
  const isPost = useMatch('/posts/:key')

  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(!isPost)

  const { isAdminUser } = useSubscription()

  const onSideMenuCloseClickHandler = () => {
    setSideMenuOpen(false)
  }

  useEffect(() => {
    if (!isPost) {
      document.title = `LikeFolio Vault - Data Engine`
    }
  }, [location.pathname])

  return (
    <Box display='flex'>
      <SideBar open={sideMenuOpen} onSideMenuCloseClickHandler={onSideMenuCloseClickHandler} />

      <MainContainerWithTitle
        onMenuClickHandler={() => {
          setSideMenuOpen(true)
        }}
      >
        <FlexBoxFullHeight>
          <Routes>
            <Route path='/' element={isAdminUser ? <Charts /> : <Empty />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/posts/:key' element={<PrivatePost />} />

            {isAdminUser ? (
              <>
                <Route path='/posts' element={<ManagePosts />} />
                <Route path='/drawers' element={<Drawers />} />
              </>
            ) : (
              <Route path='/archive' element={<ArchivePosts />} />
            )}
          </Routes>
        </FlexBoxFullHeight>
      </MainContainerWithTitle>
    </Box>
  )
}
