import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { SerieXYConfig } from 'components/organisms/Charts/utils/types'
import { maxBy } from 'lodash'
import { useEffect, useState } from 'react'
import ChartDiv from '../../molecules/ChartDiv/ChartDiv'

const BubbleChart = ({ seriesConfig }: { seriesConfig: SerieXYConfig[] }) => {
  const id = `bubble${Math.random()}`

  // const [categoryAxis, setCategoryAxis] = useState<am5xy.CategoryAxis<am5xy.AxisRenderer>>()
  const [dataSeries, setDataSeries] = useState<am5xy.LineSeries | null>(null)

  useEffect(() => {
    const root = am5.Root.new(id)
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelY: 'zoomXY',
        panX: true,
        panY: true,
      }),
    )

    chart.get('colors')?.set('step', 2)

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 50,
    })

    xRenderer.labels.template.setAll({
      paddingTop: 20,
      fontSize: 14,
      textAlign: 'center',
    })

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: "#'%'",
        maxDeviation: 2,
        extraMax: 0.3,
        extraMin: 0.3,
      }),
    )
    xAxis.children.moveValue(
      am5.Label.new(root, {
        x: am5.p50,
        fontSize: 18,
        fontWeight: 'bold',
        centerX: am5.p50,
      }),
      xAxis.children.length - 1,
    )

    const yRenderer = am5xy.AxisRendererY.new(root, {})

    yRenderer.labels.template.setAll({
      paddingTop: 20,
      fontSize: 14,
    })

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: "#'%'",
        maxDeviation: 2,
        extraMax: 0.3,
        extraMin: 0.3,
      }),
    )

    yAxis.children.moveValue(
      am5.Label.new(root, {
        textAlign: 'center',
        rotation: -90,
        text: 'Consumer Happiness',
        fontSize: 18,
        fontWeight: 'bold',
        y: am5.p50,
        centerX: am5.p50,
      }),
      0,
    )

    chart.plotContainer.events.on('wheel', function (ev) {
      // Show overlay when wheel is used over chart
      if (ev.originalEvent.ctrlKey) {
        ev.originalEvent.preventDefault()
        chart.set('wheelY', 'zoomY')
      } else {
        chart.set('wheelY', 'zoomX')
      }
    })

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    const series0Tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      labelText:
        "[bold]{name}:[/] | Sum Metric: {valueX.formatNumber('#.')} | Sum Happiness: {valueY.formatNumber('#.')}",
    })

    series0Tooltip.get('background')?.setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
    })

    const series0 = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'y',
        valueXField: 'x',
        valueField: 'value',
        tooltip: series0Tooltip,
        calculateAggregates: true,
      }),
    )

    setDataSeries(series0)

    const colorSet = am5.ColorSet.new(root, { step: 3 })

    // Add bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    const circleTemplate = am5.Template.new<am5.Circle>({})

    // series0.bullets.push(function () {
    //   const container = am5.Container.new(root, {})
    //   const color = colorSet.next()
    //   const circle = container.children.push(
    //     am5.Circle.new(
    //       root,
    //       {
    //         radius: 20,
    //         fill: color,
    //       },
    //       circleTemplate,
    //     ),
    //   )
    //   const label = container.children.push(
    //     am5.Label.new(root, {
    //       text: '{name}',
    //       fontSize: 20,
    //       fontWeight: 'bold',
    //       centerX: am5.percent(100),
    //       centerY: am5.percent(50),
    //       paddingBottom: 0,
    //       populateText: true,
    //     }),
    //   )

    //   label.adapters.add('dy', function (dy, target) {
    //     return (circle.get('radius') ?? 0) * -1
    //   })

    //   circle.on('radius', function (radius) {
    //     label.set('x', radius)
    //   })

    //   return am5.Bullet.new(root, {
    //     sprite: container,
    //   })
    // })

    series0.bullets.push(function (root, _series, dataItem) {
      const container = am5.Container.new(root, {})
      const color = colorSet.next()

      const circle = container.children.push(
        am5.Circle.new(
          root,
          {
            radius: 20,
            fillOpacity: 0.7,
            fill: color,
            cursorOverStyle: 'pointer',
          },
          circleTemplate,
        ),
      )

      const countryLabel = container.children.push(
        am5.Label.new(root, {
          text: '{name}',
          paddingLeft: 5,
          fontSize: 20,
          fontWeight: 'bold',
          populateText: true,
          centerY: am5.p50,
        }),
      )

      circle.on('radius', function (radius) {
        countryLabel.set('x', radius)
      })

      return am5.Bullet.new(root, {
        sprite: container,
        dynamic: true,
      })
    })

    const maxEntry = maxBy(
      seriesConfig.flatMap((serie) => serie.data),
      'value',
    )

    // Add heat rule
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    series0.set('heatRules', [
      {
        target: circleTemplate,
        min: 5,
        max: 100,
        minValue: 0,
        maxValue: maxEntry?.value || 2000,
        dataField: 'value',
        key: 'radius',
      },
    ])

    series0.strokes.template.set('strokeOpacity', 0)

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        snapToSeries: [series0],
      }),
    )

    series0.data.setAll(seriesConfig[0].data as any[])

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series0.appear(1000)

    chart.appear(1000, 100)

    return () => {
      root.dispose()
    }
  }, [])

  useEffect(() => {
    if (dataSeries) {
      dataSeries.data.setAll(seriesConfig[0].data as any[])
    }
  }, [seriesConfig])

  return <ChartDiv id={id} />
}

export default BubbleChart
