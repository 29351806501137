import { Autocomplete, SxProps, TextField, UseAutocompleteProps } from '@mui/material'
import { toLower, toUpper } from 'lodash'
import { useState } from 'react'
import { Control, Controller, useController } from 'react-hook-form'

type ControlledAutocompleteProps = {
  control: Control<any>
  name: string
  label: string
  options: Array<any>
  isLoading: boolean
  getOptionIdentifier: (option: any) => any
  getOptionLabel: (option: any) => string
  listboxComponent?: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>>
  sx?: SxProps
  size?: 'small' | 'medium'
}

export const ControlledAutocomplete = ({
  name,
  label,
  control,
  options,
  isLoading,
  multiple,
  freeSolo,
  sx,
  size,
  getOptionIdentifier,
  getOptionLabel,
  listboxComponent,
  ...fieldOptions
}: ControlledAutocompleteProps & UseAutocompleteProps<any, boolean, undefined, boolean>) => {
  const [highlightedOption, setHighlightedOption] = useState<any>()
  const [open, setOpen] = useState(false)

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  const isOptionEqualToValue = (option: any, value: any) =>
    getOptionIdentifier(option) === getOptionIdentifier(value)

  const onHighlightChangeHandler = (event: any, option: any) => {
    setHighlightedOption(option)
  }

  const onCloseHandler = () => {
    setOpen(false)
    setHighlightedOption(undefined)
  }

  const onOpenHandler = () => setOpen(true)

  const onChangeAddOption = (option: any) => {
    onChange(multiple ? value.concat(option) : option)

    setHighlightedOption(undefined)
  }

  const onChangeRemoveOption = (option: any) => {
    onChange(
      multiple
        ? value.filter((v: any) => getOptionIdentifier(v) !== getOptionIdentifier(option))
        : option,
    )

    setHighlightedOption(undefined)
  }

  const onChangeToggleOption = (option: any) => {
    const includedOrSelected = multiple
      ? value.map((v: any) => getOptionIdentifier(v)).includes(getOptionIdentifier(option))
      : getOptionIdentifier(value) === getOptionIdentifier(option)

    if (includedOrSelected) {
      onChangeRemoveOption(option)
    } else {
      onChangeAddOption(option)
    }
  }

  const onKeyDownHandler = (event: any) => {
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.stopPropagation()
      event.preventDefault()

      if (highlightedOption) {
        onChangeToggleOption(highlightedOption)
      } else if (event.target.value) {
        if (freeSolo) {
          onChangeToggleOption(toUpper(event.target.value))

          return
        }

        const option = options.filter((option) =>
          toLower(option.ticker).includes(toLower(event.target.value)),
        )[0]

        if (option) {
          onChangeToggleOption(option)
        }
      }
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            {...fieldOptions}
            multiple={multiple}
            loading={isLoading}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(event: any, newValue) => {
              field.onChange(newValue)
            }}
            open={open}
            onClose={onCloseHandler}
            onOpen={onOpenHandler}
            onHighlightChange={onHighlightChangeHandler}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label={label}
                size={size}
                placeholder={label}
                onKeyDown={onKeyDownHandler}
                sx={sx}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            ListboxComponent={listboxComponent}
          />
        )
      }}
    />
  )
}
