import { Box } from '@mui/material'
import { Text } from 'components/atoms/Text/Text'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetPostGroupsQuery } from 'store/apis/posts/groupPostsApi'
import PostGroupListComponent from './PostGroupList.component'
import TagTextFilterField from './TagTextFilterField.component'
import TimeFramePostsList from './TimeFramePostsList.component'
import TopTradeIdeasPostsList from './TopTradeIdeasPostsList.component'

const SidebarPosts = ({
  onSideMenuCloseClickHandler,
}: {
  onSideMenuCloseClickHandler: () => void
}) => {
  const [searchParams] = useSearchParams()

  const [filter, setFilter] = useState<string>('')
  const [freeFilter] = useState<string>('')

  const [tags, setTags] = useState<Array<string>>([])

  const { data: postGroups } = useGetPostGroupsQuery()

  useEffect(() => {
    const paramTag = searchParams.get('tag')

    if (paramTag) setTags([paramTag])
  }, [searchParams])

  return (
    <>
      <Box padding='0 10px' position='relative' marginBottom='30px'>
        <TagTextFilterField
          tags={tags}
          filter={filter}
          onTagsChange={(tags) => setTags(tags)}
          onFilterChange={(filter) => {
            setFilter(filter)
          }}
        />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        gap='30px'
        flex='1'
        sx={{
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {freeFilter.length === 0 ? (
          <>
            <TopTradeIdeasPostsList
              filter={filter}
              tags={tags}
              onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
            />
            {postGroups?.map((postGroup) => (
              <PostGroupListComponent
                key={postGroup.id}
                postGroup={postGroup}
                filter={filter}
                tags={tags}
                onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
              />
            ))}
            <TimeFramePostsList
              timeFrame='today'
              filter={filter}
              tags={tags}
              onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
            />
            <TimeFramePostsList
              timeFrame='this-week'
              filter={filter}
              tags={tags}
              color='#1876BD'
              onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
            />
            {/* <TimeFramePostsList
              timeFrame='this-month'
              filter={filter}
              tags={tags}
              color='#1876BD'
              onSideMenuCloseClickHandler={onSideMenuCloseClickHandler}
            /> */}
          </>
        ) : (
          <Text sx={{ padding: '8px 10px', textAlign: 'center', fontSize: '20px', zIndex: 999 }}>
            Archive search available to PRO members --
            <a
              target='_blank'
              href='https://scheduler.zoom.us/d/apaubfwe/likefolio-pro-demo-with-founder'
              rel='noreferrer'
            >
              Book a demo
            </a>
          </Text>
        )}
      </Box>
    </>
  )
}
export default SidebarPosts
