import { useMemo } from 'react'
import { useFetchCapabilitiesQuery } from 'store/apis/userApi'

export default function useSubscription() {
  const { data: capabilities = [], isFetching: isFetchingCapabilities } =
    useFetchCapabilitiesQuery(undefined)

  const isAdminUser = useMemo(() => {
    return capabilities.includes('chart_explorer_admin')
  }, [capabilities])

  const isProUser = useMemo(() => {
    return capabilities.includes('chart_explorer_pro') || isAdminUser
  }, [capabilities])

  const isCoreUser = useMemo(() => {
    return capabilities.includes('chart_explorer_core') || isProUser || isAdminUser
  }, [capabilities])

  const isNewUser = useMemo(() => {
    return !capabilities.includes('chart_explorer')
  }, [capabilities])

  return {
    isCoreUser,
    isProUser,
    isFreeUser: !isCoreUser && !isProUser,
    isNewUser,
    isAdminUser,
    canPost: isAdminUser,
    isFetchingCapabilities,
  }
}
