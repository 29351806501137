import {
  Box,
  FilterOptionsState,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  styled,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import RoundButton from 'components/atoms/RoundButton/RoundButton.component'
import { Text } from 'components/atoms/Text/Text'
import {
  EChartTypeLabels as ChartTypeLabels,
  EChartMetric,
  EChartType,
  EDatasetResolution,
  EDatasetScope,
  EDatasetSource,
  TCompany,
} from 'components/organisms/Charts/utils/types'
import { ControlledAutocomplete } from 'components/organisms/ControledAutocomplete/ControledAutocomplete.component'
import { TChartParameters } from 'features/hooks/useChart'
import useSubscription from 'features/hooks/useSubscription'
import { capitalize, findKey, isEmpty, isEqual, lowerCase, sortBy, toLower, toUpper } from 'lodash'
import { DateTime } from 'luxon'
import { FormEvent, PropsWithChildren, forwardRef, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useGetTickersQuery } from 'store/apis/chartsApi'

const CHART_TYPE_VALUES: {
  [key: number]: { scope: EDatasetScope; metric: EChartMetric; chartType: EChartType }
} = {
  1: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Demand,
    chartType: EChartType.BarChange,
  },
  2: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Demand,
    chartType: EChartType.Pie,
  },
  3: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Demand,
    chartType: EChartType.Line,
  },
  4: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Demand,
    chartType: EChartType.Scatter,
  },

  5: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Happiness,
    chartType: EChartType.BarChange,
  },
  6: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Happiness,
    chartType: EChartType.BarNow,
  },
  7: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Happiness,
    chartType: EChartType.Pie,
  },
  8: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Happiness,
    chartType: EChartType.Line,
  },
  9: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Visits,
    chartType: EChartType.BarChange,
  },
  10: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Visits,
    chartType: EChartType.Pie,
  },
  11: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Visits,
    chartType: EChartType.Line,
  },
  12: {
    scope: EDatasetScope.Consumer,
    metric: EChartMetric.Visits,
    chartType: EChartType.Scatter,
  },
}

type TChartForm = {
  chartType: number
  companies: TCompany[]
  company: TCompany | null
  startDate: DateTime
  referenceDate: DateTime
  movingAverageInDays: number
  comparisonPeriodInDays: number
}

type TChartsFormProps = {
  onSubmit: SubmitHandler<TChartParameters>
  initialValues?: TChartParameters
}

const RoundSelect = styled(Select)(() => ({
  borderRadius: '30px',
}))

const ChartsForm = ({ onSubmit, initialValues }: TChartsFormProps) => {
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const { isCoreUser, isFreeUser } = useSubscription()

  const foundChartType = useMemo(
    () =>
      findKey(CHART_TYPE_VALUES, (value) =>
        isEqual(value, {
          chartType: initialValues?.chartType,
          metric: initialValues?.metric,
          scope: initialValues?.scope,
        }),
      ),
    [initialValues],
  )

  const values = initialValues
    ? ({ ...initialValues, chartType: Number(foundChartType) } as any)
    : undefined

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<TChartForm>({
    defaultValues: {
      chartType: 1,
      startDate: DateTime.now().minus({ years: 3 }).startOf('day'),
      referenceDate: DateTime.now().minus({ day: 1 }).startOf('day'),
      companies: [] as TCompany[],
      company: null,
      movingAverageInDays: 90,
      comparisonPeriodInDays: 365,
    },
    values,
  })

  const chartType = watch('chartType')
  const selectedCompanies = watch('companies')
  const selectedCompany = watch('company')

  const { data: companies = [], isFetching: companiesLoading } = useGetTickersQuery()

  const sortedCompanies = useMemo(() => {
    return sortBy(companies, 'ticker')
  }, [companies])

  const canSubmit = () => {
    if (chartType === 7) {
      return selectedCompany !== null && isDirty
    }
    return selectedCompanies.length > 0 && isDirty
  }

  const internalHandleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    handleSubmit((data) => {
      const chartTypeData = CHART_TYPE_VALUES[data.chartType] || {}
      const { chartType, metric, scope } = chartTypeData

      const retData: TChartParameters = {
        ...data,
        chartType,
        metric,
        scope,
        source: EDatasetSource.Forecasted,
        resolution: EDatasetResolution.Daily,
      }

      onSubmit(retData)

      reset(undefined, { keepValues: true, keepDirty: false, keepDefaultValues: false })
    })()
  }

  const filterOptions = (options: TCompany[], state: FilterOptionsState<any>) => {
    const input = state.inputValue

    const numberedOptions: TCompany[] = []
    const numbersAtEndOptions = options
      .reduce((arr, option) => {
        if (/^\d/.test(option.ticker)) {
          numberedOptions.push(option)
        } else {
          arr.push(option)
        }

        return arr
      }, [] as TCompany[])
      .concat(numberedOptions)

    if (isEmpty(state.inputValue)) {
      return numbersAtEndOptions
    }

    const retOptions = numbersAtEndOptions
      .filter((company) => toLower(company.ticker).includes(lowerCase(input)))
      .sort((companyA, companyB) => {
        const a = companyA.ticker
        const b = companyB.ticker

        const bgnA = a.substr(0, input.length).toLowerCase()
        const bgnB = b.substr(0, input.length).toLowerCase()

        if (bgnA == input.toLowerCase()) {
          if (bgnB != input.toLowerCase()) return -1
        } else if (bgnB == input.toLowerCase()) return 1
        return a.length - b.length
      })

    return retOptions
  }

  const ListboxComponent = forwardRef<HTMLUListElement, PropsWithChildren<any>>(
    function ListboxComponent(props, ref) {
      const { children, ...rest } = props

      return (
        <ul ref={ref} {...rest}>
          {children}
          {isFreeUser && (
            <Box sx={{ padding: '8px 10px', textAlign: 'center', fontSize: '20px', zIndex: 999 }}>
              Full coverage list available to PRO members --{' '}
              <a
                target='_blank'
                href='https://scheduler.zoom.us/d/apaubfwe/likefolio-pro-demo-with-founder'
                rel='noreferrer'
              >
                Book a demo
              </a>
            </Box>
          )}
        </ul>
      )
    },
  )

  return (
    <form onSubmit={internalHandleSubmit}>
      <Grid container spacing='10px' marginBottom='10px' alignItems='center'>
        <Grid item md={6} xs={12}>
          <Controller
            name='chartType'
            control={control}
            render={({ field }) => (
              <RoundSelect
                fullWidth
                placeholder='Select a chart'
                {...field}
                renderValue={(value: any) => {
                  const chartType = CHART_TYPE_VALUES[value]

                  let metric = capitalize(chartType.metric)
                  if (metric === 'Volume') metric = 'Demand'
                  if (metric === 'Visits') metric = 'Web Presence'

                  return (
                    <>
                      <Text fontWeight={700} component='span'>
                        {metric}:
                      </Text>
                      <Text component='span'>{` ${ChartTypeLabels[chartType.chartType]}`}</Text>
                    </>
                  )
                }}
              >
                <ListSubheader>
                  <Text fontWeight={700} margin='10px 0'>
                    Demand
                  </Text>
                </ListSubheader>
                <MenuItem value={1}>Bar Chart (Change) </MenuItem>
                <MenuItem value={2}>Pie Chart</MenuItem>
                <MenuItem value={3}>Line Chart</MenuItem>
                <MenuItem value={4}>Outlier Grid</MenuItem>
                <ListSubheader>
                  <Text fontWeight={700} margin='10px 0'>
                    Happiness
                  </Text>
                </ListSubheader>
                <MenuItem value={5}>Bar Chart (Change)</MenuItem>
                <MenuItem value={6}>Bar Chart (Now)</MenuItem>
                <MenuItem value={7}>Pie Chart</MenuItem>
                <MenuItem value={8}>Line Chart</MenuItem>
                <ListSubheader>
                  <Text fontWeight={700} margin='10px 0'>
                    Web Presence
                  </Text>
                </ListSubheader>
                <MenuItem value={9}>Bar Chart (Change)</MenuItem>
                <MenuItem value={10}>Pie Chart</MenuItem>
                <MenuItem value={11}>Line Chart</MenuItem>
                <MenuItem value={12}>Outlier Grid</MenuItem>
              </RoundSelect>
            )}
          />
        </Grid>
        {chartType !== 7 && (
          <Grid item md={6} xs={12}>
            <ControlledAutocomplete
              multiple
              name='companies'
              label='Companies'
              control={control}
              options={sortedCompanies}
              isLoading={companiesLoading}
              filterOptions={filterOptions}
              listboxComponent={ListboxComponent}
              getOptionLabel={(option: any) => toUpper(option.ticker) ?? ''}
              getOptionIdentifier={(option: any) => option.id}
              sx={{
                '.MuiAutocomplete-inputRoot': {
                  borderRadius: '30px',
                },
              }}
            />
          </Grid>
        )}
        {chartType === 7 && (
          <Grid item md={6} xs={12}>
            <ControlledAutocomplete
              name='company'
              label='Company'
              control={control}
              options={sortedCompanies}
              isLoading={companiesLoading}
              listboxComponent={ListboxComponent}
              getOptionLabel={(option: any) => toUpper(option.ticker) ?? ''}
              getOptionIdentifier={(option: any) => option.id}
              sx={{
                '.MuiAutocomplete-inputRoot': {
                  borderRadius: '30px',
                },
              }}
            />
          </Grid>
        )}
      </Grid>
      {isCoreUser &&
        (showAdvanced ? (
          <>
            <Grid container spacing='10px' marginBottom='10px' alignItems='center'>
              <Grid item md={2} xs={6}>
                <Controller
                  name='comparisonPeriodInDays'
                  control={control}
                  render={({ field }) => (
                    <RoundSelect {...field} fullWidth>
                      <MenuItem value='365'>YoY</MenuItem>
                      <MenuItem value='90'>QoQ</MenuItem>
                      <MenuItem value='30'>MoM</MenuItem>
                      <MenuItem value='730'>2-year Compare</MenuItem>
                      <MenuItem value='1095'>3-year Compare</MenuItem>
                    </RoundSelect>
                  )}
                />
              </Grid>

              <Grid item md={2} xs={6}>
                <Controller
                  name='movingAverageInDays'
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>Moving Average</InputLabel>
                      <RoundSelect {...field} label='Moving Average' fullWidth>
                        <MenuItem value='7'>7-day</MenuItem>
                        <MenuItem value='30'>30-day</MenuItem>
                        <MenuItem value='90'>90-day</MenuItem>
                        <MenuItem value='365'>365-day</MenuItem>
                      </RoundSelect>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item md={2} xs={12}>
                <Controller
                  name='referenceDate'
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label='As-Of Date'
                      shouldDisableDate={(day) => day > DateTime.now().startOf('day')}
                      sx={{
                        width: '100%',

                        '.MuiOutlinedInput-root': {
                          borderRadius: '30px',
                        },
                      }}
                      slotProps={{
                        actionBar: { actions: ['today'] },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing='10px' marginBottom='10px' alignItems='center'>
              <Grid item>
                <StyledLink onClick={() => setShowAdvanced(false)}>
                  <Text>Hide Advanced</Text>
                </StyledLink>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item>
            <StyledLink onClick={() => setShowAdvanced(true)}>
              <Text>Show Advanced</Text>
            </StyledLink>
          </Grid>
        ))}
      <Grid xs={12} item display='flex' justifyContent='center'>
        <RoundButton
          variant='contained'
          disabled={!canSubmit()}
          type='submit'
          size='large'
          sx={{ width: '400px' }}
        >
          Generate Chart
        </RoundButton>
      </Grid>
    </form>
  )
}

const StyledLink = styled(Box)<{ visited?: boolean }>(({ visited }) => ({
  color: '#1a0dab',
  textDecoration: 'underline',
  fontWeight: visited ? 700 : 500,
  cursor: 'pointer',
}))

export default ChartsForm
