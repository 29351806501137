import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store/store'
import { API_HOST, API_PATH } from 'store/utils'
import { setSessionId } from '../slices/stripeSlice'

const stripeApi = createApi({
  reducerPath: 'stripeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/${API_PATH}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      headers.set('Content-Type', 'application/json')

      return headers
    },
  }),
  endpoints: (builder) => ({
    getCheckoutSession: builder.query<any, string>({
      query: (id) => ({
        method: 'POST',
        url: 'user/billing/checkout-session',
        body: {
          mode: 'embedded',
          stripe_price_id: id,
          return_path: '/checkout_success',
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        dispatch(setSessionId(data.session_id))
      },
    }),
    getCheckoutSessionStatus: builder.query<any, string>({
      query: (sessionId) => ({
        method: 'GET',
        url: 'user/billing/checkout-session',
        params: {
          stripe_session_id: sessionId,
        },
      }),
    }),
    getCheckoutSessionCommit: builder.query<any, string>({
      query: (sessionId) => ({
        method: 'POST',
        url: 'user/billing/commit-checkout',
        body: {
          stripe_session_id: sessionId,
        },
      }),
    }),
  }),
})

export const {
  useGetCheckoutSessionQuery,
  useGetCheckoutSessionStatusQuery,
  useLazyGetCheckoutSessionCommitQuery,
} = stripeApi
export default stripeApi
