const defaultTeaserBody = `
<p class="ql-align-center">This section is restricted to LikeFolio Pro Members only.</p>
<p class="ql-align-center">
  <a href="https://likefolio.com/vault-pro" rel="noopener noreferrer" target="_blank"
    ><strong>CLICK HERE TO UPGRADE TO LIKEFOLIO PRO</strong></a
  >
</p>
<p class="ql-align-center">-- or --</p>
<p class="ql-align-center">
  <a
    href="https://scheduler.zoom.us/d/apaubfwe/likefolio-pro-demo-with-founder"
    rel="noopener noreferrer"
    target="_blank"
    >BOOK A QUICK DEMO WITH A LIKEFOLIO FOUNDER</a
  >
</p>
`
export default defaultTeaserBody
