import { styled } from '@mui/material'
import sanitizeHtml from 'sanitize-html'

const defaultOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'b',
    'u',
    'i',
    'em',
    'strong',
    'a',
    'img',
    'iframe',
    'ul',
    'li',
    'br',
  ],
  allowedSchemes: ['data', 'http', 'https'],
  allowedAttributes: undefined,
}

const StyledText = styled('div')({
  fontFamily: 'Modern Era Regular',
  iframe: {
    width: '100%',
    aspectRatio: '2/1',
  },
})

const sanitize = (html: string, options?: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(html, { ...defaultOptions, ...options }),
})

const SanitizeHTML = ({ html, options }: { html: string; options?: sanitizeHtml.IOptions }) => (
  <StyledText>
    <div dangerouslySetInnerHTML={sanitize(html, options)} />
  </StyledText>
)

export default SanitizeHTML
